import { Type } from "class-transformer";
import { NumberFormatter } from "../util";
import { Comparison } from "./Comparison/Comparison";
import { CompositeRatingLinkedBusinessAuto } from "./CompositeRatingLinkedBusinessAuto";
import { CompositeRatingLinkedTractor } from "./CompositeRatingLinkedTractor";
import { CompositeRatingLinkedTrailer } from "./CompositeRatingLinkedTrailer";
import type { Coverage } from "./Coverage";

export class CoverageCompositeRating {
    static createTemplateFrom(compositeRating: CoverageCompositeRating): CoverageCompositeRating {
        const rating = new CoverageCompositeRating();
        rating.sortOrder = compositeRating.sortOrder;
        rating.description = compositeRating.description;
        return rating;
    }

    static compare({
        base,
        compare,
    }: {
        base?: CoverageCompositeRating;
        compare?: CoverageCompositeRating;
    }): CoverageCompositeRating.IComparisonReturn {
        const comparison = new Comparison(CoverageCompositeRating, base, compare);
        comparison.setField("description", compare?.description || base?.description);

        if (!base) {
            return comparison.setNew({
                obj: compare,
                description: `Add ${compare.description} composite rating`,
                subComparison: comparison as Comparison<unknown>,
                label: "Composite Rating",
            });
        }

        if (!compare) {
            return comparison.setDelete({
                obj: base,
                description: `Delete ${base.description} composite rating`,
                subComparison: comparison as Comparison<unknown>,
                label: "Composite Rating",
            });
        }

        [
            {
                key: "premium",
                label: "Premium",
                transform: (v) => (v ? NumberFormatter.Currency.format(v) : "n/a"),
            },
            {
                key: "basis",
                label: "Basis",
                transform: (v) => (v ? v : "n/a"),
            },
            {
                key: "rate",
                label: "Rate",
                transform: (v) => (v ? NumberFormatter.Percent.format(v) : "n/a"),
            },
            {
                key: "frequency",
                label: "Frequency",
                transform: (v) => (v ? v : "n/a"),
            },
        ].forEach(({ key, label, transform }) => {
            if (base[key] !== compare[key]) {
                comparison.addDiff({
                    type: "change",
                    description: `Update ${label} from ${transform(base[key])} to ${transform(compare[key])}`,
                    label,
                    priority: null,
                    fieldName: key as keyof CoverageCompositeRating,
                    isArrayField: false,
                    value: {
                        from: base[key],
                        to: compare[key],
                        base,
                        compare,
                    },
                });
            }
        });

        Comparison.compareArrayFields<CoverageCompositeRating, "linkedTractors">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "linkedTractors",
            fieldKey: "tractorId",
            itemDescriptionName: "Selected Tractor",
            getItemDescription(entity) {
                return entity.toString();
            },
        });

        Comparison.compareArrayFields<CoverageCompositeRating, "linkedTrailers">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "linkedTrailers",
            fieldKey: "trailerId",
            itemDescriptionName: "Selected Trailer",
            getItemDescription(entity) {
                return entity.toString();
            },
        });

        Comparison.compareArrayFields<CoverageCompositeRating, "linkedBusinessAutos">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "linkedBusinessAutos",
            fieldKey: "businessAutoId",
            itemDescriptionName: "Selected Bus Autos",
            getItemDescription(entity) {
                return entity.toString();
            },
        });

        return comparison;
    }

    id: number;
    createdDate: Date;

    coverageId: number;
    coverageCreatedDate: Date;
    coverage?: Coverage;

    sortOrder: number;
    description: string;
    basis?: Coverage.Basis;
    frequency?: Coverage.Frequency;
    rate?: number;
    premium?: number;

    linkedEquipment: {
        tractors: { id: number; vin: string }[];
        trailers: { id: number; vin: string }[];
        businessAutos: { id: number; vin: string }[];
    };

    @Type(() => CompositeRatingLinkedTractor)
    linkedTractors: CompositeRatingLinkedTractor[];

    @Type(() => CompositeRatingLinkedTrailer)
    linkedTrailers: CompositeRatingLinkedTrailer[];

    @Type(() => CompositeRatingLinkedBusinessAuto)
    linkedBusinessAutos: CompositeRatingLinkedBusinessAuto[];

    compare(
        comparisonCoverage: CoverageCompositeRating,
        options?: { isBase?: boolean; separateEquipment?: boolean }
    ): Comparison<CoverageCompositeRating> {
        return CoverageCompositeRating.compare(
            Comparison.getBaseAndComparisonObjects(
                {
                    initiatorEntity: this,
                    comparisonEntity: comparisonCoverage,
                },
                {
                    isBase: options?.isBase,
                }
            )
        );
    }

    toString(): string {
        return this.description;
    }
}

export namespace CoverageCompositeRating {
    export interface IComparisonReturn extends Comparison<CoverageCompositeRating> {
        // description?: string;
    }
}
