import { Coverage, CoverageOption, Policy } from "@deathstar/types/northstar";
import { EquipmentDriverChangeDto } from "@deathstar/types/waypoint";
import { classNames } from "@deathstar/ui";
import { CheckIcon } from "@heroicons/react/24/outline";
import { uniq } from "lodash";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import api from "../../api/api";
import { EquipmentTypeString } from "../../api/queries/equipment";
import { useAccountId } from "../../api/useAccountId";

const COMPANY_DRIVER_COVERAGES = [CoverageOption.Id.TRUCKERS_AUTO_LIABILITY];
const OWNER_OPERATOR_COVERAGES = [
    CoverageOption.Id.TRUCKERS_AUTO_LIABILITY,
    CoverageOption.Id.OCCUPATIONAL_ACCIDENT,
    CoverageOption.Id.CONTINGENT_LIABILITY,
    CoverageOption.Id.NON_TRUCKING_LIABILITY,
];

const TRACTOR_COVERAGES = [
    CoverageOption.Id.TRUCKERS_AUTO_LIABILITY,
    CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE,
    CoverageOption.Id.MOTOR_TRUCK_CARGO,
    CoverageOption.Id.EXCESS_CARGO,
];
const TRAILER_COVERAGES = [CoverageOption.Id.TRUCKERS_AUTO_LIABILITY, CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE];
const BUSINESS_AUTO_COVERAGES = [CoverageOption.Id.BUSINESS_AUTO_LIABILITY];
const TOOLS_COVERAGES = [CoverageOption.Id.INLAND_MARINE];

export default function CoverageSelector({ dataType }: { dataType: EquipmentTypeString | "drivers" }) {
    const form = useFormContext<{ coverages: EquipmentDriverChangeDto["coverages"]; ownerOperator: boolean }>();
    const accountId = useAccountId();
    const { data } = api.policies.useFind<(Coverage & { policy: Pick<Policy, "writingCompany"> })[]>(accountId!, "active", {
        select: (policies) =>
            uniq(
                policies
                    .flatMap((policy) =>
                        policy.coverages
                            .filter((coverage) => {
                                switch (dataType) {
                                    case "tractors":
                                        return CoverageOption.getAvailableAttachments(coverage.coverageOptionId).tractors;
                                    case "trailers":
                                        return CoverageOption.getAvailableAttachments(coverage.coverageOptionId).trailers;
                                    case "autos":
                                        return CoverageOption.getAvailableAttachments(coverage.coverageOptionId).businessAutos;
                                    case "tools":
                                        return CoverageOption.getAvailableAttachments(coverage.coverageOptionId).tools;
                                    case "drivers":
                                        return CoverageOption.getAvailableAttachments(coverage.coverageOptionId).drivers;
                                    default:
                                        return false;
                                }
                            })
                            .map(
                                (coverage) =>
                                    ({ ...coverage, policy: { writingCompany: policy.writingCompany } } as Coverage & {
                                        policy: Pick<Policy, "writingCompany">;
                                    })
                            )
                    )
                    .sort()
            ),
    });

    useEffect(() => {
        if (data && !form.formState.touchedFields.coverages) {
            if (dataType === "drivers") {
                if (form.getValues("ownerOperator")) {
                    form.setValue(
                        "coverages",
                        data
                            .filter((c) => OWNER_OPERATOR_COVERAGES.includes(c.coverageOptionId))
                            .map((c) => ({ id: c.id, coverageOptionId: c.coverageOptionId })),
                        { shouldTouch: false }
                    );
                } else {
                    form.setValue(
                        "coverages",
                        data
                            .filter((c) => COMPANY_DRIVER_COVERAGES.includes(c.coverageOptionId))
                            .map((c) => ({ id: c.id, coverageOptionId: c.coverageOptionId })),
                        { shouldTouch: false }
                    );
                }
            } else if (dataType === "tractors") {
                form.setValue(
                    "coverages",
                    data
                        .filter((c) => TRACTOR_COVERAGES.includes(c.coverageOptionId))
                        .map((c) => ({ id: c.id, coverageOptionId: c.coverageOptionId })),
                    { shouldTouch: false }
                );
            } else if (dataType === "trailers") {
                form.setValue(
                    "coverages",
                    data
                        .filter((c) => TRAILER_COVERAGES.includes(c.coverageOptionId))
                        .map((c) => ({ id: c.id, coverageOptionId: c.coverageOptionId })),
                    { shouldTouch: false }
                );
            } else if (dataType === "autos") {
                form.setValue(
                    "coverages",
                    data
                        .filter((c) => BUSINESS_AUTO_COVERAGES.includes(c.coverageOptionId))
                        .map((c) => ({ id: c.id, coverageOptionId: c.coverageOptionId })),
                    { shouldTouch: false }
                );
            } else if (dataType === "tools") {
                form.setValue(
                    "coverages",
                    data
                        .filter((c) => TOOLS_COVERAGES.includes(c.coverageOptionId))
                        .map((c) => ({ id: c.id, coverageOptionId: c.coverageOptionId })),
                    { shouldTouch: false }
                );
            }
        }
    }, [data, dataType, form]);

    const coverages = form.watch("coverages");

    return (
        <div className="w-full">
            <p>
                Desired coverages <span className="text-xs text-stone-500">(click to select one or more)</span>
            </p>
            {data?.map((coverage) => (
                <button
                    type="button"
                    key={coverage.id}
                    className={classNames(
                        "grid w-full grid-cols-[1rem,1fr,max-content] items-center gap-2 rounded px-2 py-1 text-stone-500 disabled:opacity-50 enabled:hover:bg-stone-100",
                        coverages.some((c) => c.id === coverage.id) ? "font-medium text-waypoint-blue" : ""
                    )}
                    onClick={() => {
                        if (coverages.some((c) => c.id === coverage.id)) {
                            form.setValue(
                                "coverages",
                                coverages.filter((c) => c.id !== coverage.id),
                                { shouldTouch: true }
                            );
                        } else {
                            form.setValue("coverages", [...coverages, { id: coverage.id, coverageOptionId: coverage.coverageOptionId }], {
                                shouldTouch: true,
                            });
                        }
                    }}
                >
                    <div className="h-4 w-4">{coverages.some((c) => c.id === coverage.id) ? <CheckIcon className="h-4 w-4" /> : ""}</div>
                    <span className="text-left">{CoverageOption.getMetadata(coverage.coverageOptionId).name}</span>
                    <span className="text-right text-xs font-normal text-stone-500">{coverage.policy!.writingCompany!.name}</span>
                </button>
            ))}
        </div>
    );
}
