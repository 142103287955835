import { FMCSA } from "@deathstar/types";
import { NumberFormatter } from "@deathstar/types/NumberFormatter";
import { classNames } from "@deathstar/ui";
import { Typography } from "@material-ui/core";
import React from "react";
import { StopLight, StopLightOption } from "../Components/StopLight";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";

export function AccidentRate({ className }: { className?: string }): JSX.Element | null {
    const { motorCarrier } = React.useContext(Context);

    const { accidentRate, accidentRating, details } = motorCarrier;
    const formattedAccidentRate = getFormattedAccidentRate(accidentRate);

    const options: StopLightOption[] = React.useMemo(() => {
        return [
            {
                label: "Unsatisfactory",
                red: true,
                selected: accidentRating === FMCSA.AccidentRating.UNSATISFACTORY,
                tooltip: `Accident rate of ${formattedAccidentRate} is greater than the max accepted rate of 1.5`,
            },
            {
                label: "Satisfactory",
                green: true,
                selected: accidentRating === FMCSA.AccidentRating.SATISFACTORY,
                tooltip:
                    accidentRate === 1.5
                        ? "Accident rate of 1.5 is the max accepted rate"
                        : `Accident rate of ${formattedAccidentRate} is less than the max accepted rate of 1.5`,
            },
            {
                label: "None",
                gray: true,
                selected: accidentRating === FMCSA.AccidentRating.NONE,
                tooltip: !details.VehicleMilesTraveled
                    ? "Unable to calculate accident rate without Vehicle Miles Traveled"
                    : "Unable to calculate accident rate",
            },
        ] as StopLightOption[] as StopLightOption[];
    }, [accidentRate, accidentRating, details.VehicleMilesTraveled, formattedAccidentRate]);

    return (
        <div className={classNames("bg-inherit ", className)}>
            <TitleGroup title={<Typography variant="h5">Accident Rate</Typography>} />
            <div className="flex flex-col py-4">
                <StopLight options={options} />
            </div>
        </div>
    );
}

function getFormattedAccidentRate(accidentRate: number | null): number | "n/a" {
    if (!accidentRate) return "n/a";
    if (accidentRate <= 1.5 || accidentRate > 1.6) return NumberFormatter.round(accidentRate, 1);
    if (accidentRate > 1.51) return NumberFormatter.round(accidentRate, 2);
    return accidentRate;
}
