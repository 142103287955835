import { Alert, classNames, InputField, Popover, PrimaryButton, useSnackbar } from "@deathstar/ui";
import { CurrencyDollarIcon, EllipsisVerticalIcon, PlayIcon, PlusIcon, StopIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { NotFoundError, UnauthorizedError } from "../../api/util/exceptions";
import { ActionButton } from "../../components/action-button/ActionButton";
import { Dialog } from "../../components/dialog/Dialog";
import Unauthorized from "../../components/error-screens/Unauthorized";
import UncaughtException from "../../components/error-screens/UncaughtException";
import Loader from "../../components/loader/Loader";
import { queryClient } from "../../util/queryClient";
import { JotForms } from "../jot-forms/jot-form-urls";
import NewPaymentMethodForm from "./NewPaymentMethodForm";
import PayBillForm from "./PaymentDialog";

export default function BillingPage() {
    const accountId = useAccountId();
    const { data, refetch, error } = api.billing.useProfile(accountId!);

    const [payingBill, setPayingBill] = useState(false);
    const [creatingPaymentMethod, setCreatingPaymentMethod] = useState(false);
    const [payBillSuccessful, setPayBillSuccessful] = useState(false);
    const [jotFormOpen, setJotFormOpen] = useState(false);

    const { mutate: createBillingProfile, isPending } = api.billing.useCreateProfile(accountId!, {
        onSuccess: (data) => {
            queryClient.setQueryData(api.billing.queryKeys.profile(accountId!), data);
        },
    });

    const updateBillingProfile = api.billing.useUpdateProfile(accountId!, {
        onError() {
            useSnackbar.add("Request failed", { variant: "error" });
        },
    });

    if (error) {
        if (error instanceof UnauthorizedError) {
            return <Unauthorized />;
        }
        if (error instanceof NotFoundError) {
            return (
                <PrimaryButton disabled={isPending} onClick={() => createBillingProfile()}>
                    Set up billing profile
                </PrimaryButton>
            );
        }
        return <UncaughtException />;
    }
    if (!data) return <Loader />;

    return (
        <div className="">
            <PayBillForm
                open={payingBill}
                onClose={(data) => {
                    if (data) {
                        setPayBillSuccessful(true);
                    }
                    setPayingBill(false);
                }}
            />
            <div className="space-y-4">
                {data?.automaticBillingEnabled && (
                    <Alert variant="info" className="max-w-max !py-2">
                        Automatic payments are enabled. If required by your policy, your default payment method will be used for recurring
                        insurance payments.
                    </Alert>
                )}
                <div className="flex flex-wrap gap-4">
                    <ActionButton
                        onClick={() => {
                            if (data) {
                                if (
                                    !data.automaticBillingEnabled &&
                                    !data.paymentMethods.filter((pm) => pm.type === "us_bank_account").length
                                ) {
                                    useSnackbar.add("Monthly insurance premiums must be paid with a bank account", { variant: "error" });
                                    return;
                                }
                                updateBillingProfile.mutate({
                                    automaticBillingEnabled: !data.automaticBillingEnabled,
                                });
                            }
                        }}
                    >
                        {updateBillingProfile.isPending && updateBillingProfile.variables?.automaticBillingEnabled !== undefined ? (
                            <CircularProgress size="1rem" className="mr-2" />
                        ) : data?.automaticBillingEnabled ? (
                            <StopIcon className="h-4 w-4 shrink-0" />
                        ) : (
                            <PlayIcon className="h-4 w-4 shrink-0" />
                        )}
                        {data?.automaticBillingEnabled ? "Disable" : "Enable"} automatic insurance payments
                    </ActionButton>
                    <ActionButton
                        onClick={() => {
                            console.log(data?.email);
                            if (!data?.email) {
                                useSnackbar.add("Please provide a billing email before proceeding", { variant: "error" });
                                return;
                            }
                            setCreatingPaymentMethod(true);
                        }}
                    >
                        <PlusIcon className="h-4 w-4 shrink-0" />
                        Add payment method
                    </ActionButton>
                    <ActionButton
                        onClick={() => {
                            setPayingBill(true);
                        }}
                    >
                        <CurrencyDollarIcon className="h-4 w-4 shrink-0" />
                        Make a payment
                    </ActionButton>
                </div>
                {payBillSuccessful && (
                    <Alert
                        variant="success"
                        className="max-w-max"
                        action={
                            <button className="rounded-full p-1 hover:bg-stone-800/5" onClick={() => setPayBillSuccessful(false)}>
                                <XMarkIcon className="h-4 w-4" />
                            </button>
                        }
                    >
                        Payment successful!
                    </Alert>
                )}
                <InputField
                    label="Billing email"
                    defaultValue={data.email}
                    className="max-w-sm"
                    onBlur={async (e) => {
                        try {
                            const email = e.target.value || "";
                            if (email === (data.email || "")) {
                                return;
                            }
                            if (!email) {
                                useSnackbar.add("Email cannot be empty", { variant: "error" });
                                e.target.value = data.email || "";
                                return;
                            }
                            await api.billing.updateProfile(accountId!, { email });
                            useSnackbar.add("Billing email updated", { variant: "success" });
                            refetch();
                        } catch (err) {
                            console.error(err);
                            e.target.value = data.email || "";
                            useSnackbar.add("Failed to update billing email", { variant: "error" });
                        }
                    }}
                />
                <hr />
                <p className="text-lg font-medium">Payment methods</p>
                <div className="flex w-full flex-col items-stretch gap-4 md:w-max md:flex-row">
                    {data.paymentMethods.map((pm) => (
                        <div
                            key={pm.id}
                            className={classNames(
                                "space-y-4 rounded border-2 bg-stone-50/50 p-4 shadow",
                                pm.isDefault ? "border-waypoint-blue" : "border-transparent"
                            )}
                        >
                            <div className="flex justify-between gap-6">
                                <div className="flex items-baseline gap-1">
                                    <p className="text-sm text-stone-500 small-caps">{pm.type.replaceAll("_", " ")}</p>
                                    {pm.isDefault && <span className="text-xs text-stone-500">(default)</span>}
                                </div>
                                <Popover className="">
                                    <Popover.Content className="w-max !p-0 !py-2">
                                        <Popover.Item
                                            className="!px-4 !py-2"
                                            disabled={pm.isDefault || updateBillingProfile.isPending}
                                            onClick={() => {
                                                updateBillingProfile.mutate({ defaultPaymentMethod: pm.id });
                                            }}
                                        >
                                            Set as default
                                        </Popover.Item>
                                        <Popover.Item
                                            className="!px-4 !py-2"
                                            onClick={async () => {
                                                await api.billing.removePaymentMethod(accountId!, pm.id);
                                                refetch();
                                            }}
                                        >
                                            Delete
                                        </Popover.Item>
                                    </Popover.Content>
                                    <Popover.Button className="rounded-full p-1 text-stone-600 hover:bg-stone-100">
                                        <EllipsisVerticalIcon className="h-5 w-5" />
                                    </Popover.Button>
                                </Popover>
                            </div>
                            <p className="font-mono">{pm.value.padStart(16, "*")}</p>
                        </div>
                    ))}
                    {!data.paymentMethods.length && <p className="ml-2 text-stone-500">No payment methods found</p>}
                </div>
            </div>

            <Dialog
                open={jotFormOpen}
                onClose={() => setJotFormOpen(false)}
                className="relative h-[80vh] w-screen overflow-visible md:w-[70vw]"
            >
                <iframe
                    src={JotForms.ACHAuthForAgencyBillClients.getUrl({
                        companyName: "",
                        policyNumber: "",
                        userEmail: data?.email || "",
                        userName: "",
                    })}
                    title="Secure ACH Authorization Form"
                    className="h-full w-full rounded"
                />
                <button
                    onClick={() => {
                        setJotFormOpen(false);
                    }}
                    title="Close"
                    className="absolute -right-4 -top-4 rounded-full border bg-white p-2 text-red-700"
                >
                    <XMarkIcon className="h-6 w-6" />
                </button>
            </Dialog>

            <Dialog
                open={creatingPaymentMethod}
                onClose={() => {
                    setCreatingPaymentMethod(false);
                }}
                className="overflow-y-auto p-4 pb-0"
            >
                <NewPaymentMethodForm
                    onSubmit={(setupIntent) => {
                        setCreatingPaymentMethod(false);
                        if (setupIntent) {
                            refetch();
                        }
                    }}
                />
            </Dialog>
        </div>
    );
}
