import { MotorCarrier } from "@deathstar/motor-carrier";
import { classNames, DataTable } from "@deathstar/ui";
import { Table as ReactTable } from "@tanstack/react-table";
import { animated, useSpring } from "react-spring";
import { PrintableTable } from "../Components/PrintableTable";
import { PrintSectionName } from "../Components/PrintSectionName";
import { Section } from "../Components/Section";
import { TitleGroup } from "../Components/TitleGroup";
import { Map as ViolationMap, type MapController } from "../Map/Map";
import Columns from "./Columns";
import { Filters } from "./Filters";
import { hideableColumns } from "./hideableColumns";

const AnimatedDataTable = animated(DataTable<MotorCarrier.IViolation>);

function Table(options: {
    data: MotorCarrier.IViolation[];
    table: ReactTable<MotorCarrier.IViolation>;
    resetTableFilter: () => void;
}): JSX.Element {
    return (
        <>
            <WebTable {...options} className="print:hidden" />
            <PrintTable {...options} className="hidden print:block" />
        </>
    );
}

function WebTable({
    data,
    table,
    resetTableFilter,
    className,
}: {
    data: MotorCarrier.IViolation[];
    table: ReactTable<MotorCarrier.IViolation>;
    resetTableFilter: () => void;
    className?: string;
}): JSX.Element {
    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    return (
        <div className={classNames("flex flex-col gap-2 overflow-auto p-1", className)}>
            <AnimatedDataTable
                style={spring}
                table={table}
                hideableColumns={hideableColumns}
                onClearFilters={resetTableFilter}
                filters={<Filters table={table} />}
                onRowClick={(viol) => {
                    table.resetColumnFilters();
                    table.resetPagination();
                    table.setColumnFilters((columnFilters) => {
                        if (columnFilters.map((f) => f.id).includes(Columns.addDate.id as string)) {
                            columnFilters = columnFilters.map((f) => {
                                if (f.id === Columns.addDate.id) {
                                    return {
                                        id: Columns.addDate.id as string,
                                        value: viol.date.format("mm/dd/YYYY"),
                                    };
                                }
                                if (f.id === Columns.state.id) {
                                    return {
                                        id: Columns.state.id as string,
                                        value: viol.inspection.get("ReportState"),
                                    };
                                }
                                if (f.id === Columns.unitVin.id) {
                                    return {
                                        id: Columns.unitVin.id as string,
                                        value: viol.unit?.vin,
                                    };
                                }
                                if (f.id === Columns.violationCode.id) {
                                    return {
                                        id: Columns.violationCode.id as string,
                                        value: viol.get("ViolationCode"),
                                    };
                                }
                                if (f.id === Columns.latitude.id) {
                                    return {
                                        id: Columns.latitude.id as string,
                                        value: viol.inspection.get("CountyLatitude"),
                                    };
                                }
                                return f;
                            });
                        } else {
                            columnFilters.push(
                                {
                                    id: Columns.addDate.id as string,
                                    value: viol.date.format("mm/dd/YYYY"),
                                },
                                {
                                    id: Columns.state.id as string,
                                    value: viol.inspection.get("ReportState"),
                                },
                                {
                                    id: Columns.unitVin.id as string,
                                    value: viol.unit?.vin,
                                },
                                {
                                    id: Columns.violationCode.id as string,
                                    value: viol.get("ViolationCode"),
                                },
                                {
                                    id: Columns.latitude.id as string,
                                    value: viol.inspection.get("CountyLatitude"),
                                }
                            );
                        }
                        return columnFilters;
                    });
                }}
            />
        </div>
    );
}

function PrintTable({ data, className }: { data: MotorCarrier.IViolation[]; className?: string }): JSX.Element {
    return (
        <div className={className}>
            <PrintableTable
                columnHeaders={["_oos", "Date", "State", "VIN", "BASIC Category", "Group", "Total Weight"]}
                data={data.map((viol) => ({
                    key: viol.id,
                    _oos: viol.isOutOfService ? (
                        <div className="flex justify-center rounded-xl bg-danger px-1 text-white">
                            <span>OOS</span>
                        </div>
                    ) : (
                        ""
                    ),
                    Date: viol.date.format("mm/dd/YY"),
                    State: viol.inspection.get("ReportState"),
                    // VIN: !viol.unit?.vin ? "" : `...${viol.unit?.vin?.slice(-4)}`,
                    VIN: viol.unit?.vin?.slice(-4) || "",
                    "BASIC Category": viol.basic,
                    Group: viol.get("GroupDescription"),
                    "Total Weight": viol.getTotalWeight(),
                }))}
            />
        </div>
    );
}

function Map({ mapController, table }: { mapController: MapController; table: ReactTable<MotorCarrier.IViolation> }): JSX.Element {
    const tableState = table.getState();

    return (
        <ViolationMap
            className="print:max-h-[450px]"
            mapController={mapController}
            selectedLatitude={parseFloat(
                tableState.globalFilter || (tableState.columnFilters.find((f) => f.id === Columns.latitude.id)?.value as string)
            )}
            options={{
                getStateColor: (_state) => "#0c324d",
                getStateLabel: (state) => state.abbr,
            }}
        />
    );
}

export function Violations({
    mapController,
    table,
    data,
    resetTableFilter,
}: {
    mapController: MapController;
    table: ReactTable<MotorCarrier.IViolation>;
    data: MotorCarrier.IViolation[];
    resetTableFilter: () => void;
}): JSX.Element {
    return (
        <div className="relative">
            <PrintSectionName name="Violations" />

            <Section data-page-break="after">
                <TitleGroup title="Violations" description="All violations within the report range." />
                <Map mapController={mapController} table={table} />
                <Table data={data} table={table} resetTableFilter={resetTableFilter} />
            </Section>
        </div>
    );
}
