import { Expose } from "class-transformer";
import { IsArray, IsBoolean, IsInt, IsOptional, IsString, Length, Max, Min, ValidateBy } from "class-validator";

export type EquipmentRow<T> = Omit<T, "value" | "spare" | "acv" | "ownerOperator" | "comprehensive" | "collision">;

export class EquipmentDriverChangeDto {
    @Expose()
    requestDate: string;

    @Expose()
    requestComment: string;

    @Expose()
    @ValidateBy({
        name: "isArrayOfIDs",
        validator: {
            validate: (value: object[]) =>
                value.every(
                    (x) => "id" in x && typeof x["id"] === "number" && "coverageOptionId" in x && typeof x["coverageOptionId"] === "number"
                ),
            defaultMessage: () => "Each item must have an id and coverageOptionId",
        },
    })
    coverages: { id: number; coverageOptionId: number }[];
}

export class EquipmentValueChangeDto {
    @Expose()
    requestDate: string;

    @Expose()
    @IsInt()
    @Min(0)
    @Max(99_999_999)
    value: number;
}

export class BusinessAutoDto extends EquipmentDriverChangeDto {
    @Expose()
    @IsOptional()
    @IsString()
    unitNumber: string;

    @Expose()
    @IsInt()
    @Max(new Date().getFullYear() + 5)
    @Min(1900)
    year: number;

    @Expose()
    @IsString()
    make: string;

    @Expose()
    @IsOptional()
    @IsInt()
    typeId: number;

    @Expose()
    @IsString()
    @Length(17, 17)
    vin: string;

    @Expose()
    @IsOptional()
    @IsInt()
    @Min(0)
    value: number;

    @Expose()
    @IsInt()
    propertyId: number;
}

export class TrailerDto extends BusinessAutoDto {
    @Expose()
    @IsBoolean()
    spare: boolean;

    @Expose()
    @IsBoolean()
    ownerOperator: boolean;

    @Expose()
    @IsOptional()
    @IsArray()
    @ValidateBy({
        name: "isArrayOfIDs",
        validator: { validate: (value: object[]) => value.every((x) => "id" in x), defaultMessage: () => "Each item must have an id" },
    })
    drivers: { id: number }[];
}

export class TractorDto extends TrailerDto {
    @Expose()
    @IsBoolean()
    wpUserConfirmationIsNotPpvVanPickup: boolean;
}

export class ToolDto extends EquipmentDriverChangeDto {
    @Expose()
    @IsOptional()
    @IsInt()
    @Max(new Date().getFullYear() + 5)
    @Min(1900)
    year: number;

    @Expose()
    @IsString()
    make: string;

    @Expose()
    @IsString()
    model: string;

    @Expose()
    @IsString()
    serialNumber: string;

    @Expose()
    @IsOptional()
    @IsInt()
    @Min(0)
    value: number;

    @Expose()
    @IsOptional()
    @IsString()
    description: string;

    @Expose()
    @IsInt()
    propertyId: number;
}
