import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { useEffect, useRef } from "react";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import environment from "../../environment";

export function SetSentryUser() {
    const { user } = useAuth0();
    const hasSetUser = useRef(false);
    useEffect(() => {
        if (user && !hasSetUser.current) {
            hasSetUser.current = true;
            Sentry.setUser({
                id: user.sub,
                email: user.email,
            });
        }
    }, [user]);
    return null;
}

/**
 * Initializes telematics and sets the user info. This just needs to be placed within the Auth0 provider, ideally as high up as possible.
 */
export function SetTelematicsUser() {
    const { user } = useAuth0();
    const hasSetUser = useRef(false);
    useEffect(() => {
        if (user && !hasSetUser.current) {
            if (environment.telematics.scriptUrl) {
                const script = document.createElement("script");
                // @ts-expect-error clear out window.trace if it was set by a script loaded in the index.html
                window.trace = undefined;
                script.onload = () => {
                    if (environment.telematics.websiteId && window.trace) {
                        window.trace.websiteId = environment.telematics.websiteId;
                        window.trace.identify(user.sub!, {
                            user_id: user.sub,
                            user_email: user.email,
                            user_name: user.name,
                        });
                    }
                };
                script.src = environment.telematics.scriptUrl;
                document.body.appendChild(script);
            } else if (window.trace && environment.telematics.websiteId) {
                if (!user.email?.endsWith("@navigatortruckinsurance.com")) {
                    window.trace.websiteId = environment.telematics.websiteId;
                    window.trace.identify(user.sub!, {
                        user_id: user.sub,
                        user_email: user.email,
                        user_name: user.name,
                    });
                }
            }
            hasSetUser.current = true;
        }
    }, [user]);
    return null;
}

/**
 * Updates the telematics visit, sending some useful data regarding the user and account.
 */
export function UpdateTelematicsVisit() {
    const { user } = useAuth0();
    const accountId = useAccountId();
    const hasRun = useRef(false);
    useEffect(() => {
        if (user && !hasRun.current && window.trace && accountId) {
            hasRun.current = true;
            api.telematics
                .get(accountId)
                .then((data) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    window.trace.updateVisit(user.sub!, data as Record<string, any>);
                })
                .catch(console.warn);
        }
    }, [accountId, user]);
    return null;
}
