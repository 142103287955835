import { BusinessAutoDto, ToolDto, TractorDto, TrailerDto } from "@deathstar/types/waypoint";
import { Checkbox, classNames, InputField, PrimaryButton, useSnackbar } from "@deathstar/ui";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import { capitalize, orderBy } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import api from "../../api/api";
import { EquipmentTypeString } from "../../api/queries/equipment";
import { useAccountId } from "../../api/useAccountId";
import { Dialog, DialogProps } from "../../components/dialog/Dialog";
import AddressSelector from "./AddressSelector";
import CoverageSelector from "./CoverageSelector";

export function NewUnitDialog({
    open,
    onClose,
    unitType,
    dialogProps,
    defaultValues,
}: {
    open: boolean;
    onClose(): void;
    unitType: EquipmentTypeString;
    dialogProps?: Partial<Omit<DialogProps, "open" | "onClose" | "children">>;
    defaultValues?: Partial<TractorDto | TrailerDto | BusinessAutoDto | ToolDto>;
}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            {...dialogProps}
            className={classNames("w-screen overflow-y-auto p-4 pb-0 md:max-w-md", dialogProps?.className)}
        >
            <NewUnitForm onClose={onClose} unitType={unitType} defaultValues={defaultValues} />
        </Dialog>
    );
}

function NewUnitForm({
    onClose,
    unitType,
    defaultValues,
}: {
    onClose(): void;
    unitType: EquipmentTypeString;
    defaultValues?: Partial<TractorDto | TrailerDto | BusinessAutoDto | ToolDto>;
}) {
    const accountId = useAccountId();
    const { mutate, isPending } = api.equipment.useCreate(unitType, accountId!, {
        onSuccess: () => {
            onClose();
        },
        onError: (error) => {
            if (error.status === 403) {
                useSnackbar.add("You do not have permission to manage equipment.", { variant: "error" });
            } else {
                useSnackbar.add("Could not add unit. Please try again later.", { variant: "error" });
            }
        },
    });
    const form = useForm<TractorDto | TrailerDto | BusinessAutoDto | ToolDto>({
        defaultValues: {
            coverages: [],
            ...defaultValues,
        },
    });
    const { data: types } = api.equipment.useFindTypes(accountId!, { select: (types) => orderBy(types, "name") });

    const filteredTypes = useMemo(
        () =>
            orderBy(
                (types || []).filter(
                    (type) =>
                        (unitType === "tractors" && type.tractor && ["CT", "DT", "HT", "S", "TT", "OTR"].includes(type.code)) ||
                        (unitType === "trailers" &&
                            type.trailer &&
                            [
                                "CST",
                                "CRT",
                                "DST",
                                "FST",
                                "GRT",
                                "HST",
                                "GNT",
                                "LBT",
                                "LST",
                                "LOG",
                                "LGT",
                                "BST",
                                "MST",
                                "SNO",
                                "PST",
                                "RST",
                                "ROF",
                                "TSD",
                                "SST",
                                "VST",
                                "WFT",
                            ].includes(type.code)) ||
                        (unitType === "autos" && type.auto && type.code !== "ICL")
                ),
                "name"
            ),
        [types, unitType]
    );
    const fields = useMemo(() => {
        switch (unitType) {
            case "tractors":
            case "trailers":
                return ["unitNumber", "year", "make", "typeId", "vin", "value", "spare", "ownerOperator"];
            case "autos":
                return ["unitNumber", "year", "make", "typeId", "vin", "value"];
            case "tools":
                return ["description", "year", "make", "model", "serialNumber", "value"];
            default:
                return [];
        }
    }, [unitType]);

    return (
        <form
            onSubmit={form.handleSubmit((data) => {
                if (!isPending) {
                    mutate(data);
                }
            })}
            className="w-full space-y-4 text-sm"
        >
            {fields.includes("unitNumber") && (
                <InputField classes={{ inputContainer: "py-2" }} label="Unit #" {...form.register("unitNumber")} />
            )}
            {fields.includes("year") && (
                <InputField
                    classes={{ inputContainer: "py-2" }}
                    required={unitType !== "tools"}
                    label="Year"
                    {...form.register("year", { setValueAs: (value) => (value ? parseInt(value) : null) })}
                    type="number"
                    min={1900}
                    max={new Date().getFullYear() + 5}
                />
            )}
            {fields.includes("make") && (
                <>
                    <InputField
                        classes={{ inputContainer: "py-2" }}
                        required={unitType !== "tools"}
                        label="Make"
                        {...form.register("make")}
                        list="make-options"
                    />
                    <datalist id="make-options">
                        <option value="CAT"></option>
                        <option value="Caterpillar"></option>
                        <option value="Chevrolet"></option>
                        <option value="Dodge"></option>
                        <option value="Freightliner"></option>
                        <option value="Fontaine"></option>
                        <option value="Ford"></option>
                        <option value="GMC"></option>
                        <option value="Great Dane"></option>
                        <option value="Hyundai"></option>
                        <option value="International"></option>
                        <option value="Kenworth"></option>
                        <option value="Mercedes"></option>
                        <option value="Navistar"></option>
                        <option value="Nissan"></option>
                        <option value="Ottawa"></option>
                        <option value="Pacific"></option>
                        <option value="Peterbilt"></option>
                        <option value="Prostar"></option>
                        <option value="Sterling"></option>
                        <option value="Stoughton"></option>
                        <option value="Trailking"></option>
                        <option value="Vanguard"></option>
                        <option value="Volvo"></option>
                        <option value="Wabash"></option>
                        <option value="WesternStar"></option>
                    </datalist>
                </>
            )}
            {fields.includes("model") && <InputField classes={{ inputContainer: "py-2" }} label="Model" {...form.register("model")} />}
            {fields.includes("typeId") && (
                <div>
                    <label htmlFor="typeId">Type</label>
                    <select
                        id="typeId"
                        {...form.register("typeId", { setValueAs: (value) => (value ? parseInt(value) : null) })}
                        className="form-select w-full rounded-lg border-stone-300 text-sm"
                    >
                        <option value="">Select a type</option>
                        {filteredTypes.map((type) => (
                            <option key={type.id} value={type.id}>
                                {capitalize(type.name)} - ({type.code.toUpperCase()})
                            </option>
                        ))}
                    </select>
                </div>
            )}
            {fields.includes("vin") && (
                <InputField
                    classes={{ inputContainer: "py-2" }}
                    required
                    label="VIN"
                    {...form.register("vin")}
                    minLength={17}
                    maxLength={17}
                />
            )}
            {fields.includes("serialNumber") && (
                <InputField classes={{ inputContainer: "py-2" }} label="Serial #" {...form.register("serialNumber")} />
            )}
            {fields.includes("value") && (
                <InputField
                    classes={{ inputContainer: "py-2" }}
                    label="Value"
                    {...form.register("value", { setValueAs: (value) => (value ? parseInt(value) : null) })}
                    type="number"
                    min={0}
                    startAdornment="$"
                />
            )}
            {fields.includes("description") && (
                <InputField classes={{ inputContainer: "py-2" }} required label="Description" {...form.register("description")} />
            )}
            {fields.includes("ownerOperator") && (
                <Checkbox
                    classes={{ input: "w-4 h-4", root: "text-sm" }}
                    label="Driven by an owner operator"
                    {...form.register("ownerOperator")}
                />
            )}
            {fields.includes("spare") && (
                <Checkbox classes={{ input: "w-4 h-4", root: "text-sm" }} label="Spare unit" {...form.register("spare")} />
            )}

            <Controller
                rules={{ required: "A terminal is required" }}
                name="propertyId"
                control={form.control}
                render={({ field }) => <AddressSelector value={field.value} onChange={field.onChange} />}
            />

            <FormProvider {...form}>
                <CoverageSelector dataType={unitType} />
            </FormProvider>

            {unitType === "tractors" && (
                <Checkbox
                    label="I confirm this is not a private passenger, van, or pickup vehicle"
                    {...form.register("wpUserConfirmationIsNotPpvVanPickup", { required: true })}
                />
            )}

            <InputField
                type="date"
                label="Add to coverage on"
                {...form.register("requestDate", {
                    required: true,
                    validate: (value) => {
                        const m = moment(value, "YYYY-MM-DD", true);
                        if (!m.isValid()) {
                            return "Invalid date";
                        }
                        if (m.isAfter(moment(), "day")) {
                            return "Date cannot be in the future";
                        }

                        if (moment().date() >= 15 && m.isBefore(moment(), "month")) {
                            return "Date is too far in the past";
                        }

                        if (moment().date() < 15 && m.isBefore(moment().subtract(1, "month"), "month")) {
                            return "Date is too far in the past";
                        }

                        return true;
                    },
                })}
            />

            <p className="text-red-600">
                {Object.values(form.formState.errors)
                    .map((error) => error.message)
                    .join("; ")}
            </p>

            <div className="flex justify-end pb-4">
                <PrimaryButton
                    type="submit"
                    disabled={isPending || (unitType === "tractors" && !form.watch("wpUserConfirmationIsNotPpvVanPickup"))}
                    className="w-32"
                >
                    {isPending ? (
                        <>
                            <span>Loading</span>
                            <CircularProgress size="1rem" classes={{ circle: "text-white" }} />
                        </>
                    ) : (
                        <>
                            <span>Submit</span>
                            <ArrowRightCircleIcon className="h-4 w-4" />
                        </>
                    )}
                </PrimaryButton>
            </div>
        </form>
    );
}
