import { GlobalCertificateHolder } from "@deathstar/types/northstar";
import { ActionButton, InputField, PrimaryButton } from "@deathstar/ui";
import { CheckIcon, ChevronLeftIcon, ChevronRightIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CircularProgress, Dialog } from "@material-ui/core";
import { keepPreviousData } from "@tanstack/react-query";
import { isEmail, isPhoneNumber, isPostalCode } from "class-validator";
import { debounce } from "lodash";
import { useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../api/api";
import { stringifyAddress } from "../certificates/certificatesUtil";

export function EmployerLinker({
    open,
    currentValue,
    onChange,
    onClose,
    currentEmployers,
}: {
    open: boolean;
    currentValue: number | null;
    onChange(
        value: number | Pick<GlobalCertificateHolder, "name" | "street" | "street2" | "city" | "state" | "zip" | "email" | "fax"> | null
    ): unknown;
    onClose(): void;

    currentEmployers: GlobalCertificateHolder[];
}) {
    const inputRef = useRef<HTMLInputElement>(null);
    const close = () => {
        if (inputRef.current) {
            inputRef.current.value = "";
        }
        form.reset({ name: "", street: "", street2: "", city: "", state: "", zip: "", email: "", fax: "" });
        setCreating(false);
        _setSearch("");
        onClose();
    };
    const [search, _setSearch] = useState("");
    const { data, isLoading } = api.certificates.useSearchGlobalHolders(
        { keyword: search, onlyEmployers: true },
        { placeholderData: keepPreviousData, enabled: search.length > 2 }
    );
    const form = useForm<Pick<GlobalCertificateHolder, "name" | "street" | "street2" | "city" | "state" | "zip" | "email" | "fax">>({
        defaultValues: {
            name: "",
            street: "",
            street2: "",
            city: "",
            state: "",
            zip: "",
        },
    });
    const [creating, setCreating] = useState(false);

    const setSearch = useMemo(
        () =>
            debounce((s) => {
                _setSearch(s);
            }, 300),
        []
    );

    return (
        <Dialog open={open} onClose={close} classes={{ paper: "p-8 w-screen max-w-lg" }}>
            {creating ? (
                <form
                    className="mt-2 grid grid-cols-6 gap-4"
                    onSubmit={form.handleSubmit((data) => {
                        onChange(data);
                        close();
                    })}
                >
                    <div className="col-span-6">
                        <ActionButton
                            onClick={() => {
                                form.reset({ name: "", street: "", street2: "", city: "", state: "", zip: "", email: "", fax: "" });
                                setCreating(false);
                            }}
                        >
                            <ChevronLeftIcon className="h-4 w-4" />
                            Back
                        </ActionButton>
                    </div>
                    <InputField
                        classes={{ root: "col-span-6" }}
                        label="Holder name"
                        placeholder="Reliable Drivers Inc."
                        {...form.register("name", { required: true })}
                        autoFocus
                        required
                        error={!!form.formState.errors?.name}
                    />
                    <InputField
                        classes={{ root: "col-span-full" }}
                        label="Street"
                        placeholder="100 N. Capitol Ave."
                        {...form.register("street", { required: true })}
                        required
                        error={!!form.formState.errors?.street}
                    />
                    <InputField
                        classes={{ root: "col-span-full" }}
                        label="Street 2"
                        placeholder="Suite 200"
                        {...form.register("street2")}
                        error={!!form.formState.errors?.street2}
                    />
                    <InputField
                        classes={{ root: "col-span-3" }}
                        label="City"
                        placeholder="Lansing"
                        {...form.register("city", { required: true })}
                        required
                        error={!!form.formState.errors?.city}
                    />
                    <InputField
                        classes={{ root: "col-span-3" }}
                        label="State/Province"
                        placeholder="MI"
                        {...form.register("state", {
                            required: true,
                            maxLength: 2,
                        })}
                        required
                        error={!!form.formState.errors?.state}
                    />
                    <InputField
                        classes={{ root: "col-span-3" }}
                        label="Postal code"
                        placeholder="48933"
                        {...form.register("zip", { required: true, validate: (v) => isPostalCode(v, "any") || "Invalid postal code" })}
                        required
                        error={!!form.formState.errors?.zip}
                    />

                    <InputField
                        label="Email"
                        classes={{ root: "col-span-full" }}
                        {...form.register("email", { validate: (v) => !v || isEmail(v) || "Invalid email" })}
                        placeholder="jsmith@example.com"
                        required
                        error={!!form.formState.errors?.email}
                    />

                    <InputField
                        label="Fax"
                        classes={{ root: "col-span-full" }}
                        {...form.register("fax", { validate: (v) => !v || isPhoneNumber(v, "US") || "Invalid fax number" })}
                        placeholder="16165551234"
                        error={!!form.formState.errors?.fax}
                    />
                    <div className="col-span-full flex justify-center">
                        <PrimaryButton type="submit">Create employer</PrimaryButton>
                    </div>
                </form>
            ) : (
                <>
                    {currentEmployers.length ? (
                        <div className="mb-12">
                            <p className="text-sm text-stone-500">Active employers</p>
                            <ul>
                                {currentEmployers.map((employer) => (
                                    <li key={employer.id}>
                                        <button
                                            className="flex w-full items-center gap-2 overflow-hidden rounded px-2 py-1 text-left hover:bg-blue-50"
                                            onClick={() => {
                                                if (currentValue === employer.id) {
                                                    onChange(null);
                                                } else {
                                                    onChange(employer.id);
                                                }
                                            }}
                                        >
                                            <div className="h-5 w-5">
                                                {employer.id === currentValue ? (
                                                    <CheckIcon className="h-5 w-5 text-navigator-primary" />
                                                ) : null}
                                            </div>
                                            <p className="hidden grow space-x-2 overflow-hidden text-ellipsis whitespace-nowrap md:block">
                                                <span className="font-medium text-navigator-primary-dark">{employer.name}</span>
                                                <span className="text-sm text-stone-600">{stringifyAddress(employer)}</span>
                                            </p>
                                            <div className="flex flex-col md:hidden">
                                                <span className="font-medium text-navigator-primary-dark">{employer.name}</span>
                                                <span className="text-sm text-stone-600">{stringifyAddress(employer)}</span>
                                            </div>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : null}
                    <InputField
                        startAdornment={<MagnifyingGlassIcon className="h-4 w-4 text-stone-400" />}
                        onChange={(e) => setSearch(e.target.value)}
                        classes={{ inputContainer: "py-1", root: "mb-4 sticky top-2", input: "placeholder:!text-stone-400" }}
                        placeholder="Search all employers..."
                        ref={inputRef}
                    />
                    <ul>
                        {search.length > 2 && (
                            <>
                                <li>
                                    <p className="text-sm text-stone-500">
                                        {data?.data.length || 0} search result{data?.data.length !== 1 ? "s" : ""}
                                    </p>
                                </li>
                                {data?.data.map((globalHolder) => (
                                    <li key={globalHolder.id}>
                                        <button
                                            className="flex w-full items-center gap-2 overflow-hidden rounded p-2 text-left hover:bg-blue-50"
                                            onClick={() => {
                                                if (globalHolder.id === currentValue) {
                                                    onChange(null);
                                                } else {
                                                    onChange(globalHolder.id);
                                                }
                                            }}
                                        >
                                            <p className="hidden grow space-x-2 overflow-hidden text-ellipsis whitespace-nowrap md:block">
                                                <span className="font-medium text-navigator-primary-dark">{globalHolder.name}</span>
                                                <span className="text-sm text-stone-600">{stringifyAddress(globalHolder)}</span>
                                            </p>
                                            <div className="flex flex-col md:hidden">
                                                <span className="font-medium text-navigator-primary-dark">{globalHolder.name}</span>
                                                <span className="text-sm text-stone-600">{stringifyAddress(globalHolder)}</span>
                                            </div>
                                            <ChevronRightIcon className="h-4 w-4 shrink-0 text-stone-400" />
                                        </button>
                                    </li>
                                ))}
                                <li className="mt-2 border-t py-2">
                                    <p className="text-center text-xs text-stone-500">Don't see what you need?</p>
                                    <button
                                        onClick={() => {
                                            form.setValue("name", search);
                                            setCreating(true);
                                        }}
                                        className="w-full py-2 text-navigator-primary-light hover:text-navigator-primary-dark"
                                    >
                                        Create a new employer
                                    </button>
                                </li>
                            </>
                        )}
                        {isLoading && (
                            <li>
                                <div className="flex w-full items-center justify-center">
                                    <CircularProgress className="h-4 w-4" />
                                </div>
                            </li>
                        )}
                    </ul>
                </>
            )}
        </Dialog>
    );
}
