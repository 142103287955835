import { Type } from "class-transformer";
import { Comparison } from "./Comparison/Comparison";
import { CoverageCompositeRating } from "./CoverageCompositeRating";
import { CoverageOption } from "./CoverageOption";
import { EquipmentComparison } from "./PolicyLayer";
import { Trailer } from "./Trailer";

export class CompositeRatingLinkedTrailer implements EquipmentComparison.IIsComparable {
    static compare({
        base,
        compare,
        comparisonLayer,
        baseLayer,
    }: Comparison.IOptions<CompositeRatingLinkedTrailer>): Map<CoverageOption.Id, CompositeRatingLinkedTrailer.IComparisonReturn> {
        const map = new Map<CoverageOption.Id, CompositeRatingLinkedTrailer.IComparisonReturn>();

        if (compare) {
            for (const [cvgOptId, linkedEntity] of Object.entries(compare) as unknown as [
                CoverageOption.Id,
                CompositeRatingLinkedTrailer
            ][]) {
                const from = base?.[cvgOptId];
                const to = linkedEntity;
                map.set(cvgOptId, CompositeRatingLinkedTrailer.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        if (base) {
            for (const [cvgOptId, linkedEntity] of Object.entries(base) as unknown as [CoverageOption.Id, CompositeRatingLinkedTrailer][]) {
                if (map.has(cvgOptId)) continue;
                const from = linkedEntity;
                const to = compare?.[cvgOptId];
                map.set(cvgOptId, CompositeRatingLinkedTrailer.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        return map;
    }

    static #compare({
        base,
        compare,
    }: Partial<Comparison.IOptions.Entity<CompositeRatingLinkedTrailer>>): CompositeRatingLinkedTrailer.IComparisonReturn {
        const comparison = new Comparison(CompositeRatingLinkedTrailer, base, compare) as CompositeRatingLinkedTrailer.IComparisonReturn;
        comparison.setField("trailerId", compare?.trailerId || base?.trailerId);
        comparison.setField("trailer", compare?.trailer || base?.trailer);

        if (!base) {
            return comparison.setNew({
                obj: compare,
                description: `Add ${compare?.trailer?.vin}`,
                subComparison: comparison as Comparison<unknown>,
                label: "Selected Trailers",
            });
        }

        if (!compare) {
            return comparison.setDelete({
                obj: base,
                description: `Delete ${base?.trailer?.vin}`,
                subComparison: comparison as Comparison<unknown>,
                label: "Selected Trailers",
            });
        }

        return comparison;
    }

    id: number;
    createdDate: Date;

    trailerId: number;
    @Type(() => Trailer)
    trailer?: Trailer;

    compositeRatingId: number;
    compositeRatingCreatedDate: Date;
    compositeRating?: CoverageCompositeRating;

    comparison?: {
        isNewToPolicy?: boolean;
        isDeletedFromPolicy?: boolean;
    };

    compare(
        comparisonLinkedTrailer: CompositeRatingLinkedTrailer,
        options?: Comparison.getBaseAndComparisonObjects.IOptions
    ): Comparison<CompositeRatingLinkedTrailer> {
        return CompositeRatingLinkedTrailer.#compare(
            Comparison.getBaseAndComparisonObjects(
                {
                    initiatorEntity: this,
                    comparisonEntity: comparisonLinkedTrailer,
                },
                options
            )
        );
    }

    toString(): string {
        if (!this.trailer) {
            return `CompositeRatingLinkedTrailer: ${this.trailerId}`;
        }
        const baseStringParts = [this.trailer.toString()];
        return baseStringParts.join(" ");
    }
}

export namespace CompositeRatingLinkedTrailer {
    export interface IComparisonReturn extends Comparison<CompositeRatingLinkedTrailer> {
        trailerId: number;
        tractor?: Trailer;
    }
}
