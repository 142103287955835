import { MotorCarrier } from "@deathstar/motor-carrier";
import { PrinterIcon } from "@heroicons/react/24/outline";
import { IconButton, Popper, Typography } from "@material-ui/core";
import React from "react";
import { DotAnalysisLogo } from "../assets/icons/svg/DotAnalysisLogo";
import WaypointWordmarkIconSrc from "../assets/icons/wp_wordmark.png";
import { Navigation } from "./Navigation";

interface IOptions {
    motorCarrier: MotorCarrier;
    onPrint: () => void;
}

export function Header({ motorCarrier, onPrint }: IOptions): JSX.Element {
    return (
        <div className="relative flex justify-between overflow-hidden bg-inherit px-4 pb-10 pt-4">
            <div className="relative z-10">
                <div className="mr-8 hidden text-pretty print:block print:text-white">
                    <TitleBlock motorCarrier={motorCarrier} />
                </div>
                <Navigation />

                <IconButton className="print:hidden" onClick={onPrint}>
                    <PrinterIcon className="h-8 w-8" />
                </IconButton>
            </div>

            <div className="z-1 relative z-10 flex flex-col">
                <div className="print:hidden">
                    <TitleBlock motorCarrier={motorCarrier} />
                </div>

                <div className="hidden items-center gap-2 text-white print:flex">
                    <div className="flex flex-col items-end justify-end">
                        <img src={WaypointWordmarkIconSrc} alt="Waypoint" className="h-[80px]" />
                        <DotAnalysisLogo white className="relative top-[-10px] h-[20px] w-min" />
                    </div>
                </div>

                <div className="relative top-9 flex justify-end gap-4  print:top-2">
                    <Typography className="rounded-full bg-white px-4 py-1">{motorCarrier.dot}</Typography>
                    <DatePicker motorCarrier={motorCarrier} />
                </div>
            </div>

            <div className="curve-style absolute top-0 z-0 h-[150px] bg-gray-100 print:bg-[#214182]" />
        </div>
    );
}

function TitleBlock({ motorCarrier }: Pick<IOptions, "motorCarrier">): JSX.Element {
    return (
        <>
            <Typography className="text-4xl print:text-3xl">{motorCarrier.name}</Typography>
            <Typography variant="subtitle1" className="text-right print:hidden">
                DOT Analysis
            </Typography>
        </>
    );
}

function DatePicker({ motorCarrier }: Pick<IOptions, "motorCarrier">): JSX.Element {
    const [isMenuOpen, setMenuOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClose = React.useCallback(() => {
        setMenuOpen(false);
        setAnchorEl(null);
    }, []);

    // const toggleOpen = React.useCallback(
    //     (e: React.MouseEvent<HTMLButtonElement>) => {
    //         if (isMenuOpen) {
    //             return handleClose();
    //         }
    //         setMenuOpen(true);
    //         setAnchorEl(e.currentTarget);
    //     },
    //     [handleClose, isMenuOpen]
    // );

    const OPTIONS = [motorCarrier.dateRange.to.format("Mmmm YYYY")];

    return (
        <div>
            <div // todo: change back to button element when date picker is implemented
                // type="button"
                className="flex items-center gap-2 rounded-full bg-navigator-primary bg-primary px-4 py-1 text-white print:bg-white print:text-inherit"
                // onClick={toggleOpen}
            >
                <Typography>{motorCarrier.dateRange.to.format("Mmmm YYYY")}</Typography>
                {/* <div className="print:hidden">
                    {isMenuOpen ? <ChevronDownIcon className="h-4 w-4 rotate-180 transform" /> : <ChevronDownIcon className="h-4 w-4" />}
                </div> */}
            </div>
            <Popper open={isMenuOpen} anchorEl={anchorEl}>
                <ul className="mt-1 rounded bg-navigator-primary bg-primary p-4 text-white">
                    {OPTIONS.map((option) => (
                        <li key={option}>
                            <button
                                type="button"
                                className="w-full rounded px-2 py-1 text-left hover:bg-white hover:text-navigator-primary hover:text-primary"
                                onClick={handleClose}
                            >
                                {option}
                            </button>
                        </li>
                    ))}
                </ul>
            </Popper>
        </div>
    );
}
