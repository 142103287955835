import { Type } from "class-transformer";
import { BusinessAuto } from "./BusinessAuto";
import { Comparison } from "./Comparison/Comparison";
import { CoverageCompositeRating } from "./CoverageCompositeRating";
import { CoverageOption } from "./CoverageOption";
import { EquipmentComparison } from "./PolicyLayer";

export class CompositeRatingLinkedBusinessAuto implements EquipmentComparison.IIsComparable {
    static compare({
        base,
        compare,
        comparisonLayer,
        baseLayer,
    }: Comparison.IOptions<CompositeRatingLinkedBusinessAuto>): Map<
        CoverageOption.Id,
        CompositeRatingLinkedBusinessAuto.IComparisonReturn
    > {
        const map = new Map<CoverageOption.Id, CompositeRatingLinkedBusinessAuto.IComparisonReturn>();

        if (compare) {
            for (const [cvgOptId, linkedEntity] of Object.entries(compare) as unknown as [
                CoverageOption.Id,
                CompositeRatingLinkedBusinessAuto
            ][]) {
                const from = base?.[cvgOptId];
                const to = linkedEntity;
                map.set(cvgOptId, CompositeRatingLinkedBusinessAuto.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        if (base) {
            for (const [cvgOptId, linkedEntity] of Object.entries(base) as unknown as [
                CoverageOption.Id,
                CompositeRatingLinkedBusinessAuto
            ][]) {
                if (map.has(cvgOptId)) continue;
                const from = linkedEntity;
                const to = compare?.[cvgOptId];
                map.set(cvgOptId, CompositeRatingLinkedBusinessAuto.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        return map;
    }

    static #compare({
        base,
        compare,
    }: Partial<Comparison.IOptions.Entity<CompositeRatingLinkedBusinessAuto>>): CompositeRatingLinkedBusinessAuto.IComparisonReturn {
        const comparison = new Comparison(
            CompositeRatingLinkedBusinessAuto,
            base,
            compare
        ) as CompositeRatingLinkedBusinessAuto.IComparisonReturn;
        comparison.setField("businessAutoId", compare?.businessAutoId || base?.businessAutoId);
        comparison.setField("businessAuto", compare?.businessAuto || base?.businessAuto);

        if (!base) {
            return comparison.setNew({
                obj: compare,
                description: `Add ${compare?.businessAuto?.vin}`,
                subComparison: comparison as Comparison<unknown>,
                label: "Selected Bus Auto",
            });
        }

        if (!compare) {
            return comparison.setDelete({
                obj: base,
                description: `Delete ${base?.businessAuto?.vin}`,
                subComparison: comparison as Comparison<unknown>,
                label: "Selected Bus Auto",
            });
        }

        return comparison;
    }

    id: number;
    createdDate: Date;

    businessAutoId: number;
    @Type(() => BusinessAuto)
    businessAuto?: BusinessAuto;

    compositeRatingId: number;
    compositeRatingCreatedDate: Date;
    compositeRating?: CoverageCompositeRating;

    comparison?: {
        isNewToPolicy?: boolean;
        isDeletedFromPolicy?: boolean;
    };

    compare(
        comparisonLinkedTrailer: CompositeRatingLinkedBusinessAuto,
        options?: Comparison.getBaseAndComparisonObjects.IOptions
    ): Comparison<CompositeRatingLinkedBusinessAuto> {
        return CompositeRatingLinkedBusinessAuto.#compare(
            Comparison.getBaseAndComparisonObjects(
                {
                    initiatorEntity: this,
                    comparisonEntity: comparisonLinkedTrailer,
                },
                options
            )
        );
    }

    toString(): string {
        if (!this.businessAuto) {
            return `CompositeRatingLinkedBusinessAuto: ${this.businessAutoId}`;
        }
        const baseStringParts = [this.businessAuto.toString()];
        return baseStringParts.join(" ");
    }
}

export namespace CompositeRatingLinkedBusinessAuto {
    export interface IComparisonReturn extends Comparison<CompositeRatingLinkedBusinessAuto> {
        businessAutoId: number;
        businessAuto?: BusinessAuto;
    }
}
