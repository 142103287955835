/* eslint-disable @typescript-eslint/no-explicit-any */
import { Transform, Type } from "class-transformer";
import moment from "moment";
import { NumberFormatter } from "../util";
import { CoverageOption } from "./CoverageOption";
import { GeneralLedgerTransaction } from "./GeneralLedgerTransaction";
import { InvoiceClientPremium } from "./InvoiceClientPremium";
import { InvoiceCommission } from "./InvoiceCommission";
import { InvoiceCommissionBreakout } from "./InvoiceCommissionBreakout";
import { InvoiceCustomerPremium } from "./InvoiceCustomerPremium";
import type { InvoiceTransaction } from "./InvoiceTransaction";
import { Policy } from "./Policy";
import { User } from "./User";

enum InvoiceStatus {
    Paid = "Paid",
    Overdue = "Overdue",
    Due = "Due",
    Pending = "Pending",
}

export class Invoice {
    static includesEscrowDeposit({ invoice }: { invoice: Invoice }): boolean {
        return !!invoice.transactions?.find((glt) => glt.description?.toLowerCase().includes("escrow deposit"));
    }

    static calculateAccountingTotals({ invoice, billMethod }: { invoice: Invoice | InvoiceDto; billMethod: Policy.BillMethod }): {
        paid: number;
        charged: number;
    } {
        if (!billMethod) {
            return {
                charged: 0,
                paid: 0,
            };
        }

        const charged = NumberFormatter.round(Invoice.#calculateTotalCharged({ invoice }));
        const paid = NumberFormatter.round(Invoice.#calculateTotalPaid({ invoice, isDirectBill: billMethod === Policy.BillMethod.DIRECT }));

        if (charged < 0 && paid > 0 && Math.abs(charged) === paid) {
            return {
                charged,
                paid: charged,
            };
        }

        return {
            charged,
            paid,
        };
    }

    /**
     * "Reversal" Example: Zipp Trucking LLC, CT4338734366, 4/17/24
     */
    static #calculateTotalCharged({ invoice }: { invoice: Invoice | InvoiceDto }): number {
        const totalCharged = invoice.transactions?.reduce((tot, tran) => {
            (tran as any).ignored = true;
            if (!["1", "2"].includes(tran.chargeCategory)) return tot;
            // const invalidDescriptionSnippets = ["memo/spt", "financed", "reversal"]; // "fee", "escrow deposit"
            const invalidDescriptionSnippets = ["memo/spt", "financed"]; // "reversal of", "fee", "escrow deposit"
            const lcDesc = tran.description?.toLowerCase();
            for (const snip of invalidDescriptionSnippets) {
                if (lcDesc.includes(snip)) return tot;
            }
            (tran as any).ignored = false;
            (tran as any).totalBefore = tot;
            (tran as any).action = `${tot} + ${tran.premium}`;
            const newTotal = tot + tran.premium;
            (tran as any).totalAfter = newTotal;
            return newTotal;
        }, 0);

        return NumberFormatter.round(totalCharged) || 0;
    }

    static #calculateTotalPaid({ invoice, isDirectBill }: { invoice: Invoice | InvoiceDto; isDirectBill: boolean }): number {
        const totalPaid = isDirectBill
            ? invoice.transactions?.reduce((acc2, t) => {
                  return acc2 + t.premium;
              }, 0)
            : Invoice.#calculateTotalPaidOnAgencyBill({ invoice });

        const smallBalanceWaivedAmount = invoice.generalLedgerTransactions?.reduce((tot, tran) => {
            if (tran.description.toLowerCase() !== "small balance waived") return tot;
            return tot + tran.creditAmount;
        }, 0);

        return NumberFormatter.round(totalPaid + smallBalanceWaivedAmount) || 0;
    }

    static #calculateTotalPaidOnAgencyBill({ invoice }: { invoice: Invoice | InvoiceDto }): number {
        const escrowDepositTrans = invoice.generalLedgerTransactions.find(
            (glt) => glt.description?.toLowerCase().includes("escrow deposit") && glt.debitAmount > 0
        );
        const escrowPaymentTranFromInvoice =
            escrowDepositTrans &&
            invoice.generalLedgerTransactions.filter(
                (glt) => glt.description?.toLowerCase().startsWith("from invoice") && glt.creditAmount > 0
            );
        const totalEscrowPaidFromOtherInvoices =
            escrowDepositTrans && escrowPaymentTranFromInvoice.reduce((tot, glt) => tot + glt.creditAmount, 0);

        const total = invoice.generalLedgerTransactions.reduce((tot, glt) => {
            const lcDesc = glt.description.toLowerCase();
            (glt as any).ignored = true;
            if (glt.sourceId <= 0) return tot;
            if (glt.sourceId === 1 && (glt.sourceTypeId !== 1 || !lcDesc.includes("financed") || !lcDesc.includes("reversal"))) return tot;
            if (glt.sourceId === 2 && glt.sourceTypeId !== 5) return tot;
            if (glt.sourceId > 4) return tot;
            if (lcDesc === "company statement") return tot;
            if (lcDesc === "commission statement") return tot;

            (glt as any).ignored = false;
            (glt as any).totalBefore = tot;

            if (glt.sourceId === 1 && lcDesc.includes("reversal")) {
                (glt as any).action = `${tot} - ${glt.creditAmount}`;
                const newTotal = tot - glt.creditAmount;
                (glt as any).totalAfter = newTotal;
                return newTotal;
            }

            if (glt.sourceId === 1) {
                (glt as any).action = `${tot} + ${glt.creditAmount}`;
                const newTotal = tot + glt.creditAmount;
                (glt as any).totalAfter = newTotal;
                return newTotal;
            }

            // refund check
            if (glt.sourceId === 2 && glt.sourceTypeId === 5) {
                (glt as any).action = `${tot} - ${glt.debitAmount}`;
                const newTotal = tot - glt.debitAmount;
                (glt as any).totalAfter = newTotal;
                return newTotal;
            }

            // refund EFT
            if (glt.sourceId === 3 && glt.sourceTypeId === 24) {
                if (lcDesc.includes("not a check")) {
                    (glt as any).action = `${tot} - ${glt.creditAmount}`;
                    const newTotal = tot - glt.creditAmount;
                    (glt as any).totalAfter = newTotal;
                    return newTotal;
                }

                (glt as any).action = `${tot} - ${glt.creditAmount}`;
                const newTotal = tot - glt.creditAmount;
                (glt as any).totalAfter = newTotal;
                return newTotal;
            }

            (glt as any).action = glt.sourceId === 4 ? `${tot} + ${glt.creditAmount} - ${glt.debitAmount}` : `${tot} + ${glt.creditAmount}`;
            const newTotal = glt.sourceId === 4 ? tot + glt.creditAmount - glt.debitAmount : tot + glt.creditAmount;
            (glt as any).totalAfter = newTotal;
            return newTotal;

            // (glt as any).action = `${tot} + ${glt.creditAmount} - ${glt.debitAmount}`;
            // const newTotal = tot + glt.creditAmount - glt.debitAmount;
            // (glt as any).totalAfter = newTotal;
            // return newTotal;
        }, 0);

        if (totalEscrowPaidFromOtherInvoices && totalEscrowPaidFromOtherInvoices === escrowDepositTrans?.debitAmount) {
            return total - totalEscrowPaidFromOtherInvoices;
        }
        return total;
    }

    id: string;
    number: number;
    transactions?: InvoiceTransaction[];
    @Type(() => GeneralLedgerTransaction)
    @Transform(({ value }) => (!value ? [] : value))
    generalLedgerTransactions?: GeneralLedgerTransaction[];
    clientPremiums?: InvoiceClientPremium[];
    commissions?: InvoiceCommission[];
    commissionBreakouts?: InvoiceCommissionBreakout[];
    customerPremium?: InvoiceCustomerPremium[];
    debitAmount?: number;
    creditAmount?: number;
    isPaid?: boolean;
    policyNumber: string;
    policyId: string;
    agentCode?: string;
    agent?: User;
    accountManagerCode?: string;
    accountManager?: User;
    isInstallment?: boolean;
    isCancelled?: boolean;
    isPosted?: boolean;
    billMethod: Policy.BillMethod;
    @Transform(({ value }) => {
        if (!value) return null;
        const mmt = moment(value);
        return mmt.toDate();
    })
    effectiveDate: Date;
    @Transform(({ value }) => {
        if (!value) return null;
        const mmt = moment(value);
        return mmt.toDate();
    })
    invoiceDate: Date;
    @Transform(({ value }) => {
        if (!value) return null;
        const mmt = moment(value);
        return mmt.toDate();
    })
    dueDate: Date;
    changedDate: Date;
    enteredDate: Date;
}

export class InvoiceWithStatementDate extends Invoice {
    public static Status = InvoiceStatus;
    statementDate: string | null;
    status: InvoiceStatus;
    coverageOptions: CoverageOption["id"][];
}

export interface InvoiceDto extends Omit<Invoice, "effectiveDate" | "dueDate" | "invoiceDate"> {
    effectiveDate: string;
    invoiceDate: string;
    dueDate: string;
}
