import { CrudValidationGroups } from "@nestjsx/crud/lib/enums/crud-validation-groups.enum";
import { Transform, Type } from "class-transformer";
import { IsBoolean, IsEmpty, IsInt, IsISO8601, IsOptional, IsString, Matches, ValidateBy, ValidateNested } from "class-validator";
import moment from "moment";
import { Deal } from "../salesforce";
import type { Account } from "./Account";
import { Company } from "./Company";
import type { Proposal } from "./Proposal";

enum Status {
    Pending,
    NotSubmitted,
    Submitted,
    Declined,
    Quoted,
}

enum EmailType {
    Appeal = "appeal",
    Binder = "binder",
    Decline = "decline",
    FinanceAgreement = "finance agreement",
    PriceIndication = "price indication",
    Quote = "quote",
    RevisedQuote = "revised quote",
    Submission = "submission",
}

export class SubmissionReportQuery {
    @IsOptional()
    @IsString()
    accountName?: string;

    @IsOptional()
    @IsString()
    customerName?: string;

    @IsOptional()
    @IsString()
    agentSalesforceUid?: string;

    @IsOptional()
    @IsString({ each: true })
    policyType?: string[];

    @IsOptional()
    @IsString()
    efrom?: string;

    @IsOptional()
    @IsString()
    eto?: string;

    @IsOptional()
    @IsString()
    sfrom?: string;

    @IsOptional()
    @IsString()
    sto?: string;

    @IsOptional()
    @IsString()
    qfrom?: string;

    @IsOptional()
    @IsString()
    qto?: string;

    @IsOptional()
    @IsString()
    dfrom?: string;

    @IsOptional()
    @IsString()
    dto?: string;

    @IsOptional()
    @IsString()
    type?: "New" | "Renewal";

    @IsOptional()
    @ValidateBy({
        name: "isArrayOfArrayOfInts",
        validator: {
            validate: (value: unknown) => {
                console.log(value);
                return (
                    Array.isArray(value) &&
                    value.every((x) => Array.isArray(x) && x.length >= 1 && x.length <= 2 && x.every((y) => typeof y === "number"))
                );
            },
        },
    })
    @Transform(({ value }) => {
        if (Array.isArray(value)) {
            return value.map(([a, b]) => (b ? [parseInt(a), parseInt(b)] : [parseInt(a)]));
        }
        return [];
    })
    truckCountSegments?: [number, number | undefined][];

    @IsInt()
    @Transform(({ value }) => (value ? parseInt(value) : undefined))
    take?: number;

    @IsInt()
    @Transform(({ value }) => (value ? parseInt(value) : 0))
    page?: number;

    @IsOptional()
    @IsString()
    sort?: string;

    @IsOptional()
    @IsString()
    @Matches(/ASC|DESC/)
    order?: "ASC" | "DESC";
}

export type SubmissionReportRow = Pick<
    Submission,
    | "id"
    | "dealId"
    | "accountId"
    | "company"
    | "policy"
    | "dateSubmitted"
    | "declineDate"
    | "quoteDate"
    | "premium"
    | "pending"
    | "revenue"
    | "declinations"
    | "effectiveDate"
    | "uwName"
> & {
    tractorCount: number;
    accountName: string;
    proposals: Pick<Proposal, "status" | "id">[];
    account: Pick<Account, "id"> & { company: Pick<Company, "name" | "accountId"> };
    deal?: Pick<Deal, "Id" | "Type" | "OwnerId" | "Lost_Lesson_Learned__c">;
};

export const UnitCountSegments = [[1, 5], [6, 9], [10, 25], [26, 50], [51, 75], [76, 100], [101]] as [number, number | undefined][];

export class SubmissionAttachmentEmail {
    @IsInt()
    time: number;

    @IsString()
    type: EmailType;

    @IsString()
    emailID: string;
}

export class Submission {
    static EmailTypes = EmailType;
    static Status = Status;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    accountId: string;

    @IsEmpty({ always: true })
    id: number;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    dealId: string;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    company: string | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    policy: string | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    comments: string | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsISO8601()
    dateSubmitted: Date | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsISO8601()
    declineDate: Date | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsISO8601()
    quoteDate: Date | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsInt()
    premium: number | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    email1: string | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    email2: string | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    uwName: string | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsBoolean()
    pending: boolean;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @ValidateNested({ each: true })
    @Type(() => SubmissionAttachmentEmail)
    emails: SubmissionAttachmentEmail[] | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsInt()
    revenue: number | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    @IsISO8601({ strict: true })
    // This is really just to help prevent users from typing a date like "01/01/20" and not realizing it's actually "01/01/0020"
    @ValidateBy({
        name: "isNotInFarPast",
        validator: (value: string) => {
            if (typeof value === "string") {
                const m = moment(value, moment.ISO_8601);
                return m.isSameOrAfter(moment().subtract(30, "years"));
            }
            return false;
        },
    })
    effectiveDate: string | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    documents: { type: string; fileID: string }[] | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    declinations: string[];

    // submissionDeclinations: SubmissionDeclination[]; // TODO: Add this back when we actually use the relation

    @IsEmpty({ always: true })
    proposals: Proposal[];

    @IsEmpty({ always: true })
    account: Account;

    @IsEmpty({ always: true })
    status: Status;
}
