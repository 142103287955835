import { WebIA } from "@deathstar/inspection-analysis";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { useMotorCarrierFetcher } from "./useMotorCarrierFetcher";

export function InspectionAnalysis() {
    const accountId = useAccountId();
    const { data: organization } = api.organization.useById(accountId!);
    const fetcher = useMotorCarrierFetcher();
    const DOT = typeof organization?.company?.dot === "string" ? parseInt(organization?.company?.dot) : null;

    if (!DOT) return null;

    return (
        <div className="p-4">
            <WebIA
                fetcher={fetcher}
                dot={DOT}
                loadingMessage="Loading DOT Analysis..."
                config={{
                    insuranceHistory: { hide: true },
                }}
            />
        </div>
    );
}
