import { autoUpdate, flip, offset, Placement, shift, size, useFloating } from "@floating-ui/react-dom";
import {
    Popover as HuiPopover,
    PopoverBackdrop as HuiPopoverBackdrop,
    PopoverButton as HuiPopoverButton,
    PopoverButtonProps as HuiPopoverButtonProps,
    PopoverPanel as HuiPopoverPanel,
    Transition,
} from "@headlessui/react";
import React, { createContext, Fragment, useContext } from "react";
import { classNames } from "../classNames/classNames";

type PanelChildren = Parameters<typeof HuiPopoverPanel>[0]["children"];

const transitions = {
    enter: "transition ease-out duration-200",
    enterFrom: "opacity-0 -translate-y-6",
    enterTo: "opacity-100 translate-y-0",
    leave: "transition ease-in duration-150",
    leaveFrom: "opacity-100 translate-y-0",
    leaveTo: "opacity-0 -translate-y-6",
};

const PopoverContext = createContext<ReturnType<typeof useFloating<HTMLButtonElement>> | null>(null);

function Popover({ children, className, placement }: { children: React.ReactNode; className?: string; placement?: Placement }) {
    const floating = useFloating<HTMLButtonElement>({
        strategy: "absolute",
        placement: placement || "bottom-end",
        middleware: [
            shift({ padding: 16 }),
            flip(),
            size({
                apply({ availableWidth, availableHeight, elements }) {
                    Object.assign(elements.floating.style, {
                        maxWidth: `${availableWidth - 16}px`,
                        maxHeight: `${availableHeight - 16}px`,
                    });
                },
            }),
            offset(8),
        ],
        transform: false,
        whileElementsMounted: autoUpdate,
    });

    floating?.update();

    return (
        <HuiPopover className={className}>
            <PopoverContext.Provider value={floating}>
                <>
                    <HuiPopoverBackdrop className="fixed inset-0 z-30 bg-black/10" />
                    {children}
                </>
            </PopoverContext.Provider>
        </HuiPopover>
    );
}

function PopoverContent({
    className,
    style,
    transitionProps,
    ...props
}: Omit<React.ComponentProps<"div">, "children"> & {
    children: PanelChildren;
    transitionProps?: Partial<Parameters<typeof Transition>[0]>;
}) {
    const floating = useContext(PopoverContext);

    return (
        <Transition as={Fragment} {...transitions} {...transitionProps}>
            <HuiPopoverPanel
                // portal
                {...props}
                style={{
                    ...floating?.floatingStyles,
                    ...style,
                }}
                ref={floating?.refs.setFloating}
                className={classNames("z-40 overflow-auto rounded bg-white p-4 shadow-lg", className)}
            />
        </Transition>
    );
}

function PopoverButton(props: Parameters<typeof HuiPopoverButton>[0]) {
    const floating = useContext(PopoverContext);
    return <HuiPopoverButton {...props} ref={floating?.refs.setReference} />;
}

function PopoverItem(props: Omit<HuiPopoverButtonProps, "className"> & { className?: string }) {
    return (
        <HuiPopoverButton
            {...props}
            className={classNames(
                "flex w-full items-center gap-2 rounded px-2 py-1 text-stone-700 disabled:opacity-50 enabled:hover:bg-stone-100",
                props?.className
            )}
        />
    );
}

Popover.Content = PopoverContent;
Popover.Item = PopoverItem;
Popover.Button = PopoverButton;

export { Popover, PopoverContent, PopoverButton, PopoverItem };
