import { MotorCarrier } from "@deathstar/motor-carrier";
import { classNames, DataTable } from "@deathstar/ui";
import { Table as ReactTable } from "@tanstack/react-table";
import { animated, useSpring } from "react-spring";
import OrangeCastIconSrc from "../../assets/icons/orange-cast.png";
import RedSkullAndCrossbonesIconSrc from "../../assets/icons/red-skull-and-crossbones.png";
import TowTruckIconSrc from "../../assets/icons/tow-truck.png";
import { PrintableTable } from "../Components/PrintableTable";
import { PrintSectionName } from "../Components/PrintSectionName";
import { Section } from "../Components/Section";
import { TitleGroup } from "../Components/TitleGroup";
import { Map as CrashMap, type MapController } from "../Map/Map";
import Columns from "./Columns";
import { hideableColumns } from "./hideableColumns";

const AnimatedDataTable = animated(DataTable<MotorCarrier.ICrash>);

function Table(options: {
    data: MotorCarrier.ICrash[];
    table: ReactTable<MotorCarrier.ICrash>;
    resetTableFilter: () => void;
}): JSX.Element {
    return (
        <>
            <WebTable {...options} className="print:hidden" />
            <PrintTable {...options} className="hidden print:block" />
        </>
    );
}

function WebTable({
    data,
    table,
    resetTableFilter,
    className,
}: {
    data: MotorCarrier.ICrash[];
    table: ReactTable<MotorCarrier.ICrash>;
    resetTableFilter: () => void;
    className?: string;
}): JSX.Element {
    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    return (
        <div className={classNames("flex flex-col gap-2 overflow-auto p-1 print:max-w-[800px]", className)}>
            <AnimatedDataTable
                style={spring}
                table={table}
                hideableColumns={hideableColumns}
                onClearFilters={resetTableFilter}
                onRowClick={(crash) => {
                    table.resetColumnFilters();
                    table.resetPagination();
                    table.setColumnFilters((columnFilters) => {
                        if (columnFilters.map((f) => f.id).includes(Columns.reportNumber.id as string)) {
                            columnFilters = columnFilters.map((f) => {
                                if (f.id === Columns.reportNumber.id) {
                                    return {
                                        id: Columns.reportNumber.id as string,
                                        value: crash.get("ReportNumber"),
                                    };
                                }
                                if (f.id === Columns.latitude.id) {
                                    return {
                                        id: Columns.latitude.id as string,
                                        value: crash.get("CountyLatitude"),
                                    };
                                }
                                return f;
                            });
                        } else {
                            columnFilters.push(
                                {
                                    id: Columns.reportNumber.id as string,
                                    value: crash.get("ReportNumber"),
                                },
                                {
                                    id: Columns.latitude.id as string,
                                    value: crash.get("CountyLatitude"),
                                }
                            );
                        }
                        return columnFilters;
                    });
                }}
            />
        </div>
    );
}

function PrintTable({ data, className }: { data: MotorCarrier.ICrash[]; className?: string }): JSX.Element {
    return (
        <div className={className}>
            <PrintableTable
                columnHeaders={["_icons", "date", "st", "vin", "vehicles", "condition", "total weight"]}
                data={data.map((crash) => {
                    const icons: { src: string; key: string }[] = [];

                    if (crash.get("TowAway")) {
                        icons.push({ src: TowTruckIconSrc, key: "tow" });
                    }
                    if (crash.get("TotalInjuries")) {
                        icons.push({ src: OrangeCastIconSrc, key: "injury" });
                    }
                    if (crash.get("TotalFatalities")) {
                        icons.push({ src: RedSkullAndCrossbonesIconSrc, key: "fatalities" });
                    }

                    return {
                        key: crash.id,
                        _icons: !icons.length ? (
                            ""
                        ) : (
                            <div className="flex items-center">
                                {icons.map((ic) => (
                                    <img className="h-4 w-4" src={ic.src} key={ic.key} alt="" />
                                ))}
                            </div>
                        ),
                        date: crash.date.format("mm/dd/YY"),
                        st: crash.get("ReportState"),
                        vin: crash.get("UnitVin").slice(-4),
                        vehicles: crash.get("TotalVehiclesInAccident") || "",
                        condition: `${crash.get("LightCondition")} - ${crash.get("WeatherCondition")}`,
                        "total weight": crash.getTotalWeight(),
                    };
                })}
            />
        </div>
    );
}

function Map({ mapController, table }: { mapController: MapController; table: ReactTable<MotorCarrier.ICrash> }): JSX.Element {
    const tableState = table.getState();
    return (
        <CrashMap
            className="print:max-h-[450px]"
            mapController={mapController}
            options={{
                getStateColor: (_state) => "#0c324d",
                getStateLabel: (state) => state.abbr,
            }}
            selectedLatitude={parseFloat(
                tableState.globalFilter || (tableState.columnFilters.find((f) => f.id === Columns.latitude.id)?.value as string)
            )}
        />
    );
}

export function Crashes({
    mapController,
    table,
    data,
    resetTableFilter,
}: {
    mapController: MapController;
    table: ReactTable<MotorCarrier.ICrash>;
    data: MotorCarrier.ICrash[];
    resetTableFilter: () => void;
}): JSX.Element {
    return (
        <div className="page relative">
            <PrintSectionName name="Crashes" />

            <Section data-page-break="after">
                <TitleGroup title="Crashes" description="All crashes within the report range." />
                <Map mapController={mapController} table={table} />
                <Table data={data} table={table} resetTableFilter={resetTableFilter} />
            </Section>
        </div>
    );
}
