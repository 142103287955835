import { CoverageOption, Trailer } from "@deathstar/types/northstar";
import { DriverRow, EquipmentRow } from "@deathstar/types/waypoint";
import { ActionButton, DataTable, fuzzyFilter, Tabs, useSnackbar } from "@deathstar/ui";
import { PlusIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import { useMutation } from "@tanstack/react-query";
import {
    ColumnDef,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { animated, useSpring } from "react-spring";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import Loader from "../../components/loader/Loader";
import { DriverLinker } from "../drivers/DriverLinker";
import EquipmentColumns, { EquipmentTableMeta } from "./EquipmentColumns";
import { NewUnitDialog } from "./NewUnitDialog";

const AnimatedDataTable = animated(DataTable<EquipmentRow<Trailer>>);

export default function TrailersTable({ data }: { data: EquipmentRow<Trailer>[] | undefined }) {
    const accountId = useAccountId();
    const [filter, setFilter] = useState<"all" | "company" | "owner-operator">("all");
    const [adding, setAdding] = useState(false);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [linkingTrailerToDriverId, setLinkingTrailerToDriverId] = useState<number | null>(null);
    const updateUnitDrivers = api.equipment.useUpdateUnitDrivers("trailers", accountId!);

    const memoizedData = useMemo(() => data || [], [data]);

    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    const sortingState = useMemo(() => {
        return [{ id: "pending", desc: true }, ...sorting];
    }, [sorting]);

    const columnFilters = useMemo(() => {
        if (filter === "all") {
            return [];
        }
        return [{ id: "owner-operator", value: filter === "owner-operator" }];
    }, [filter]);

    const meta: EquipmentTableMeta = useMemo(
        () => ({
            openDriverLinker(driverId: number) {
                setLinkingTrailerToDriverId(driverId);
            },
        }),
        []
    );

    const columns = useMemo(() => {
        return (
            [
                EquipmentColumns.mobileTrailerCell,
                EquipmentColumns.pending,
                EquipmentColumns.upcomingDate,
                EquipmentColumns.unitNumber,
                EquipmentColumns.year,
                EquipmentColumns.make,
                EquipmentColumns.type,
                EquipmentColumns.vin,
                EquipmentColumns.value,
                EquipmentColumns.ownerOperator,
                EquipmentColumns.coverages,
                EquipmentColumns.additionalInterests,
                EquipmentColumns.linkedDriversColumn,
                memoizedData.some((t) => t.ownerOperatorAPDFirstNamedInsureds?.length)
                    ? EquipmentColumns.ownerOperatorAPDFirstNamedInsureds
                    : null,
                EquipmentColumns.trailerActions,
            ] as ColumnDef<EquipmentRow<Trailer>>[]
        ).filter(Boolean);
    }, [memoizedData]);

    const table = useReactTable({
        data: memoizedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        globalFilterFn: fuzzyFilter,
        getColumnCanGlobalFilter: () => true,
        state: {
            sorting: sortingState,
            columnFilters,
        },
        initialState: {
            pagination: {
                pageSize: 30,
            },
        },
        onSortingChange: setSorting,
        meta,
    });

    const downloadCSV = useMutation({
        mutationFn: async () => {
            const tableData = memoizedData.filter((r) => {
                switch (filter) {
                    case "company":
                        return !r.policyOwnerOperator;
                    case "owner-operator":
                        return r.policyOwnerOperator;
                    default:
                        return true;
                }
            });

            if (tableData.length === 0) {
                useSnackbar.add("No data to export", { variant: "info" });
                return;
            }

            const drivers = await api.drivers.find(tableData[0].accountId);

            const rows: string[] = [];
            tableData.forEach((row) => {
                rows.push(
                    [
                        row.unitNumber,
                        row.year,
                        row.make,
                        row.type?.name,
                        row.vin,
                        row.policyAcv ? "ACV" : row.policyValue,
                        row.policyOwnerOperator ? "Owner Operator" : "Company",
                        row.coverageOptions?.map((x) => CoverageOption.getMetadata(x.id).defaultAbbreviation).join(",") || "",
                        row.drivers
                            ?.map((d1) => drivers.find((d2) => d1.id === d2.id) as DriverRow)
                            .filter(Boolean)
                            .map((d) => d.name)
                            .join(",") || "",
                    ]
                        .map((x) => `"${x ?? ""}"`)
                        .join(",")
                );
            });

            const csv = [
                ["Unit Number", "Year", "Make", "Type", "VIN", "Value", "Owner Type", "Coverage", "Drivers"].join(","),
                ...rows,
            ].join("\n");

            const blob = new Blob([csv], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.download = "trailers.csv";
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
    });

    return (
        <div>
            {data ? (
                <>
                    <AnimatedDataTable
                        style={spring}
                        table={table}
                        headerRow={
                            <div className="flex items-end gap-4">
                                <Tabs
                                    tabs={[
                                        {
                                            label: () => <p className="w-32 px-2 py-1">All trailers</p>,
                                            value: "all",
                                            buttonProps: { className: "z-[1]" },
                                        },
                                        {
                                            label: () => <p className="w-32 px-2 py-1">Company</p>,
                                            value: "company",
                                            buttonProps: { className: "z-[1]" },
                                        },
                                        {
                                            label: () => <p className="w-32 px-2 py-1">Owner Operators</p>,
                                            value: "owner-operator",
                                            buttonProps: { className: "z-[1]" },
                                        },
                                    ]}
                                    value={filter}
                                    onChange={setFilter}
                                    className="rounded-md bg-stone-100 py-1 shadow-inner"
                                    borderClasses="bg-white border border-waypoint-blue z-[0] shadow rounded-md"
                                />
                                <div className="grow"></div>
                                <ActionButton disabled={downloadCSV.isPending} onClick={() => downloadCSV.mutate()}>
                                    {downloadCSV.isPending ? <CircularProgress size="1rem" /> : <TableCellsIcon className="h-5 w-5" />}
                                    Export
                                </ActionButton>
                                <ActionButton onClick={() => setAdding(true)}>
                                    <PlusIcon className="h-5 w-5" />
                                    Add trailer
                                </ActionButton>
                                <DataTable.Search table={table} />
                            </div>
                        }
                    />
                    <NewUnitDialog open={adding} onClose={() => setAdding(false)} unitType="trailers" />
                    <DriverLinker
                        open={!!linkingTrailerToDriverId}
                        currentValue={memoizedData.find((r) => r.id === linkingTrailerToDriverId)?.drivers || []}
                        onClose={() => setLinkingTrailerToDriverId(null)}
                        onChange={async (drivers) => {
                            if (linkingTrailerToDriverId) {
                                updateUnitDrivers.mutate({ id: linkingTrailerToDriverId, drivers });
                                setLinkingTrailerToDriverId(null);
                            }
                        }}
                    />
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
}
