namespace PaymentPlanEnum {
    export enum Id {
        COMPOSITE = "D27E40DE-FBA3-4EAA-8456-BA5AE2D637CF",
        FULL = "1E6D2375-0A94-4332-A47E-2576915D6C6E",
        DP_PLUS_ONE = "B6555D0A-FD3B-4DB9-987F-B7ADDB7DAC20",
        DP_PLUS_TWO = "2C365AEA-4421-4D92-B3F7-03317573D1CA",
        DP_PLUS_THREE = "3C9F8468-9562-4EDC-B7D2-77AB712624E5",
        DP_PLUS_FOUR = "F6FB41FD-B95C-4407-9F3A-E1C8471A91D3",
        DP_PLUS_FIVE = "7EAEF48D-D8E6-4A89-B7AE-88C6233C4C66",
        DP_PLUS_SIX = "64108F78-3DE2-4B16-8AC1-16106A7C1CF7",
        DP_PLUS_SEVEN = "43EA7E0C-6042-4322-8F69-E7EC2ECDD46C",
        DP_PLUS_EIGHT = "AF07102B-D2EB-4D5B-BABB-6238D1DD6B42",
        DP_PLUS_NINE = "3A937A1C-7511-46BC-8C6F-DAC266F06E4C",
        DP_PLUS_TEN = "72C08CEC-862A-42C1-A199-0C369FF711D5",
        DP_PLUS_ELEVEN = "95D0E9D0-CD29-4D07-BF7F-A4CE8F036AB8",
        TWO_SEMI_ANNUAL = "0AA13810-37F8-4A9B-8F10-73845C78C704",
        FOUR_QUARTERLY = "20D16D86-2FE1-47C6-99D1-2863D7348707",
        FIVE = "A7743175-574B-40A9-908C-7DD716C35156",
        TEN = "1EE968DB-B34E-4675-A94C-DF38AC342BCF",
        ELEVEN = "399140BA-28F7-47B1-9025-DD1F2BCA3C1E",
        TWELVE_MONTHLY = "391E6D5C-8F90-4332-A9A3-6A8719E68F9F",
        PREMIUM_FINANCE = "33086019-8EE2-400A-B5E0-E87FA4679444",
        OTHER = "89463594-13DD-4B96-989A-B6E4FFBF9702",
    }
    export enum Type {
        COMPOSITE = "Composite",
        FULL = "Full",
        DP_PLUS_ONE = "DP + 1 Installment",
        DP_PLUS_TWO = "DP + 2 Installments",
        DP_PLUS_THREE = "DP + 3 Installments",
        DP_PLUS_FOUR = "DP + 4 Installments",
        DP_PLUS_FIVE = "DP + 5 Installments",
        DP_PLUS_SIX = "DP + 6 Installments",
        DP_PLUS_SEVEN = "DP + 7 Installments",
        DP_PLUS_EIGHT = "DP + 8 Installments",
        DP_PLUS_NINE = "DP + 9 Installments",
        DP_PLUS_TEN = "DP + 10 Installments",
        DP_PLUS_ELEVEN = "DP + 11 Installments",
        TWO_SEMI_ANNUAL = "2 Payments (Semi-Annual)",
        FOUR_QUARTERLY = "4 Payments (Quarterly)",
        TEN = "Ten Payments",
        FIVE = "5 Payments",
        ELEVEN = "11 Payments",
        TWELVE_MONTHLY = "12 Payments (Monthly)",
        PREMIUM_FINANCE = "Premium Finance",
        OTHER = "Other",
    }
}

export class PaymentPlan {
    static Id = PaymentPlanEnum.Id;
    static Type = PaymentPlanEnum.Type;
    static FULL = {
        id: PaymentPlanEnum.Id.FULL,
        type: PaymentPlanEnum.Type.FULL,
        acordCode: "FL",
        description: "Full Pay",
    } as const;

    static includesDownPayment(payPlanId: string): boolean {
        switch (payPlanId) {
            case PaymentPlan.Id.DP_PLUS_ONE:
            case PaymentPlan.Id.DP_PLUS_TWO:
            case PaymentPlan.Id.DP_PLUS_THREE:
            case PaymentPlan.Id.DP_PLUS_FOUR:
            case PaymentPlan.Id.DP_PLUS_FIVE:
            case PaymentPlan.Id.DP_PLUS_SIX:
            case PaymentPlan.Id.DP_PLUS_SEVEN:
            case PaymentPlan.Id.DP_PLUS_EIGHT:
            case PaymentPlan.Id.DP_PLUS_NINE:
            case PaymentPlan.Id.DP_PLUS_TEN:
            case PaymentPlan.Id.DP_PLUS_ELEVEN:
                return true;
            default:
                return false;
        }
    }

    static getTotalPayments(payPlanId: string): number {
        switch (payPlanId) {
            case PaymentPlan.Id.COMPOSITE:
            case PaymentPlan.Id.TWELVE_MONTHLY:
            case PaymentPlan.Id.DP_PLUS_ELEVEN:
                return 12;
            case PaymentPlan.Id.FULL:
            case PaymentPlan.Id.PREMIUM_FINANCE:
            case PaymentPlan.Id.OTHER:
                return 1;
            case PaymentPlan.Id.DP_PLUS_ONE:
            case PaymentPlan.Id.TWO_SEMI_ANNUAL:
                return 2;
            case PaymentPlan.Id.DP_PLUS_TWO:
                return 3;
            case PaymentPlan.Id.DP_PLUS_THREE:
            case PaymentPlan.Id.FOUR_QUARTERLY:
                return 4;
            case PaymentPlan.Id.DP_PLUS_FOUR:
            case PaymentPlan.Id.FIVE:
                return 5;
            case PaymentPlan.Id.DP_PLUS_FIVE:
                return 6;
            case PaymentPlan.Id.DP_PLUS_SIX:
                return 7;
            case PaymentPlan.Id.DP_PLUS_SEVEN:
                return 8;
            case PaymentPlan.Id.DP_PLUS_EIGHT:
                return 9;
            case PaymentPlan.Id.DP_PLUS_NINE:
            case PaymentPlan.Id.TEN:
                return 10;
            case PaymentPlan.Id.DP_PLUS_TEN:
            case PaymentPlan.Id.ELEVEN:
                return 11;
            default:
                return 1;
        }
    }

    static getTypeById(payPlanId: string): PaymentPlan.Type {
        switch (payPlanId) {
            case PaymentPlan.Id.COMPOSITE:
                return PaymentPlan.Type.COMPOSITE;
            case PaymentPlan.Id.FULL:
                return PaymentPlan.Type.FULL;
            case PaymentPlan.Id.DP_PLUS_ONE:
                return PaymentPlan.Type.DP_PLUS_ONE;
            case PaymentPlan.Id.DP_PLUS_TWO:
                return PaymentPlan.Type.DP_PLUS_TWO;
            case PaymentPlan.Id.DP_PLUS_THREE:
                return PaymentPlan.Type.DP_PLUS_THREE;
            case PaymentPlan.Id.DP_PLUS_FOUR:
                return PaymentPlan.Type.DP_PLUS_FOUR;
            case PaymentPlan.Id.DP_PLUS_FIVE:
                return PaymentPlan.Type.DP_PLUS_FIVE;
            case PaymentPlan.Id.DP_PLUS_SIX:
                return PaymentPlan.Type.DP_PLUS_SIX;
            case PaymentPlan.Id.DP_PLUS_SEVEN:
                return PaymentPlan.Type.DP_PLUS_SEVEN;
            case PaymentPlan.Id.DP_PLUS_EIGHT:
                return PaymentPlan.Type.DP_PLUS_EIGHT;
            case PaymentPlan.Id.DP_PLUS_NINE:
                return PaymentPlan.Type.DP_PLUS_NINE;
            case PaymentPlan.Id.DP_PLUS_TEN:
                return PaymentPlan.Type.DP_PLUS_TEN;
            case PaymentPlan.Id.DP_PLUS_ELEVEN:
                return PaymentPlan.Type.DP_PLUS_ELEVEN;
            case PaymentPlan.Id.TWO_SEMI_ANNUAL:
                return PaymentPlan.Type.TWO_SEMI_ANNUAL;
            case PaymentPlan.Id.FOUR_QUARTERLY:
                return PaymentPlan.Type.FOUR_QUARTERLY;
            case PaymentPlan.Id.FIVE:
                return PaymentPlan.Type.FIVE;
            case PaymentPlan.Id.TEN:
                return PaymentPlan.Type.TEN;
            case PaymentPlan.Id.ELEVEN:
                return PaymentPlan.Type.ELEVEN;
            case PaymentPlan.Id.TWELVE_MONTHLY:
                return PaymentPlan.Type.TWELVE_MONTHLY;
            case PaymentPlan.Id.PREMIUM_FINANCE:
                return PaymentPlan.Type.PREMIUM_FINANCE;
            case PaymentPlan.Id.OTHER:
                return PaymentPlan.Type.OTHER;
            default:
                return PaymentPlan.Type.OTHER;
        }
    }

    id: string;
    acordCode: string;
    description: string;
    type: PaymentPlan.Type;
}

export namespace PaymentPlan {
    export type Id = PaymentPlanEnum.Id;
    export type Type = PaymentPlanEnum.Type;
}
