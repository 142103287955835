import { MonthlyReportHelper } from "@deathstar/reuse";
import {
    CoverageOption,
    Driver,
    GlobalCertificateHolder,
    MonthlyReport,
    MonthlyReportData,
    MonthlyReportDataUnit,
    PolicyAdditionalInterest,
    Tractor,
    Trailer,
} from "@deathstar/types/northstar";
import { DriverRow } from "@deathstar/types/waypoint";
import { ActionButton, classNames, DataTable, Popover, Tabs, TabsProps, useSnackbar } from "@deathstar/ui";
import {
    BuildingLibraryIcon,
    CalendarIcon,
    CurrencyDollarIcon,
    EllipsisVerticalIcon,
    HashtagIcon,
    InformationCircleIcon,
    MinusCircleIcon,
    PencilSquareIcon,
    PlusCircleIcon,
    PlusIcon,
} from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "@material-ui/core";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { uniqBy } from "lodash";
import moment from "moment";
import { useMemo, useState } from "react";
import api from "../../../api/api";
import { stringifyAddress } from "../../certificates/certificatesUtil";
import {
    ChangeDialog as DriverChangeDialog,
    DriverTableMeta,
    employerColumn,
    RemovalDialog as DriverRemovalDialog,
} from "../../drivers/DriverColumns";
import { EmployerLinker } from "../../drivers/EmployerLinker";
import { NewDriverDialog } from "../../drivers/NewDriverDialog";
import EquipmentColumns from "../EquipmentColumns";
import { NewUnitDialog } from "../NewUnitDialog";

type TableMeta = {
    policyId: string;
    reportYear: number;
    reportMonth: number;
    isAdvancedReporter: boolean;
    isFinalized: boolean;
} & Pick<DriverTableMeta, "openEmployerLinker" | "companyName">;

export function MonthlyReportUnitsTable({ data, report }: { data: MonthlyReportData; report: MonthlyReport }) {
    const [typeSelected, setTypeSelected] = useState<"tractors" | "trailers" | "drivers">(() =>
        data.equipment.tractors.length > 0
            ? "tractors"
            : data.equipment.trailers.length > 0
            ? "trailers"
            : data.isDriverReporter
            ? "drivers"
            : "tractors"
    );
    const units = useMemo(() => MonthlyReportHelper.getUnitsForCoverage(data, typeSelected, undefined), [data, typeSelected]);
    const [adding, setAdding] = useState(false);
    const [linkingEmployerToDriverId, setLinkingEmployerToDriverId] = useState<number | null>(null);
    const updateDriver = api.drivers.useUpdate(data.endLayer.policy.accountId);

    const columns = useMemo(() => (typeSelected === "drivers" ? driverColumns : tractorTrailerColumns), [typeSelected]);
    const meta = useMemo<TableMeta>(
        () => ({
            isFinalized: report.isFinalized,
            policyId: report.policyId,
            reportYear: report.year,
            reportMonth: report.month,
            isAdvancedReporter: data.isAdvancedReporter,
            openEmployerLinker(driverId: number) {
                const found = units.find((row) => row.id === driverId);
                if (found && found.isOwnerOperator) {
                    setLinkingEmployerToDriverId(driverId);
                }
            },
            companyName: data.endLayer.policy.firstNamedInsured,
        }),
        [
            data.endLayer.policy.firstNamedInsured,
            data.isAdvancedReporter,
            report.isFinalized,
            report.month,
            report.policyId,
            report.year,
            units,
        ]
    );
    const table = useReactTable({
        data: units as MonthlyReportDataUnit<Tractor | Trailer | Driver>[],
        columns: columns as ColumnDef<MonthlyReportDataUnit<Tractor | Trailer | Driver>>[],
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        meta,
    });

    return (
        <div className="w-full grow overflow-auto">
            <DataTable
                table={table}
                className="!row-spacing-1"
                getRowClassName={(unit) => {
                    const isAdded = unit.isNewToPolicy;
                    const isRemoved = unit.isRemovedFromPolicy;
                    const isAddedAndRemoved = isAdded && isRemoved;
                    const changes = unit.changes;

                    return classNames(
                        isAddedAndRemoved
                            ? "bg-yellow-400/10"
                            : isAdded
                            ? "bg-emerald-400/10"
                            : isRemoved
                            ? "bg-red-400/10"
                            : changes.length > 0
                            ? "bg-yellow-400/10"
                            : "",
                        "last:border-b-0"
                    );
                }}
                headerRow={
                    <div className="flex flex-wrap items-center gap-4">
                        <Tabs<"tractors" | "trailers" | "drivers">
                            borderClasses="border-waypoint-blue"
                            tabs={
                                [
                                    data.equipment.tractors.length > 0 && {
                                        label: () => (
                                            <div
                                                className={classNames(
                                                    "w-max rounded px-2 py-1 text-stone-600 transition-colors hover:bg-black/5 sm:w-32",
                                                    typeSelected === "tractors" && "!text-waypoint-blue"
                                                )}
                                            >
                                                Tractors ({data.equipment.activeTractors.length})
                                            </div>
                                        ),
                                        value: "tractors",
                                    },
                                    data.equipment.trailers.length > 0 && {
                                        label: () => (
                                            <div
                                                className={classNames(
                                                    "w-max rounded px-2 py-1 text-stone-600 transition-colors hover:bg-black/5 sm:w-32",
                                                    typeSelected === "trailers" && "!text-waypoint-blue"
                                                )}
                                            >
                                                Trailers ({data.equipment.activeTrailers.length})
                                            </div>
                                        ),
                                        value: "trailers",
                                    },
                                    data.isDriverReporter && {
                                        label: () => (
                                            <div
                                                className={classNames(
                                                    "w-max rounded px-2 py-1 text-stone-600 transition-colors hover:bg-black/5 sm:w-32",
                                                    typeSelected === "drivers" && "!text-waypoint-blue"
                                                )}
                                            >
                                                Drivers ({data.equipment.activeDrivers.length})
                                            </div>
                                        ),
                                        value: "drivers",
                                    },
                                ].filter(Boolean) as TabsProps<"tractors" | "trailers" | "drivers">["tabs"]
                            }
                            value={typeSelected}
                            onChange={(value) => {
                                setTypeSelected(value);
                            }}
                            className="hidden rounded-lg bg-stone-100/60 px-2 py-1 font-light sm:block"
                        />
                        <select
                            className="sm:hidden"
                            value={typeSelected}
                            onChange={(e) => setTypeSelected(e.target.value as typeof typeSelected)}
                        >
                            {data.equipment.tractors.length > 0 && (
                                <option value="tractors">Tractors ({data.equipment.activeTractors.length})</option>
                            )}
                            {data.equipment.trailers.length > 0 && (
                                <option value="trailers">Trailers ({data.equipment.activeTrailers.length})</option>
                            )}
                            {data.isDriverReporter && <option value="drivers">Drivers ({data.equipment.activeDrivers.length})</option>}
                        </select>
                        <div className="flex grow items-center justify-end gap-2">
                            <Tooltip
                                title={
                                    report.isSubmitted || report.isFinalized
                                        ? "This report has already been submitted and cannot be changed. Ask your account manager or agent to allow for resubmission, if desired."
                                        : ""
                                }
                            >
                                <div className="">
                                    <ActionButton
                                        onClick={() => setAdding(true)}
                                        className="hidden sm:flex"
                                        disabled={report.isSubmitted || report.isFinalized}
                                    >
                                        <PlusCircleIcon className="h-4 w-4" />
                                        Add {typeSelected === "tractors" ? "tractor" : typeSelected === "trailers" ? "trailer" : "driver"}
                                    </ActionButton>
                                    <button
                                        onClick={() => setAdding(true)}
                                        className="rounded border border-blue-600 p-1 text-blue-600 disabled:opacity-50 sm:hidden"
                                        disabled={report.isSubmitted || report.isFinalized}
                                    >
                                        <PlusIcon className="h-4 w-4" />
                                    </button>
                                </div>
                            </Tooltip>

                            <DataTable.Search
                                table={table}
                                classes={{
                                    inputContainer: classNames(
                                        "transition-all py-1 sm:py-2 px-1 sm:px-2",
                                        table.getState().globalFilter ? "w-32" : "w-8 !px-2"
                                    ),
                                    inputContainerFocused: "!w-32",
                                }}
                            />
                        </div>
                    </div>
                }
            />
            {(typeSelected === "tractors" || typeSelected === "trailers") && (
                <NewUnitDialog
                    open={adding}
                    onClose={() => setAdding(false)}
                    unitType={typeSelected}
                    dialogProps={{
                        baseZIndex: 100,
                    }}
                    defaultValues={{
                        requestDate: moment
                            .utc()
                            .set({ year: report.year, month: report.month - 1, date: 1 })
                            .format("YYYY-MM-DD"),
                    }}
                />
            )}

            {typeSelected === "drivers" && (
                <>
                    <NewDriverDialog
                        open={adding}
                        onClose={() => setAdding(false)}
                        dialogProps={{
                            baseZIndex: 100,
                        }}
                        defaultValues={{
                            requestDate: moment
                                .utc()
                                .set({ year: report.year, month: report.month - 1, date: 1 })
                                .format("YYYY-MM-DD"),
                        }}
                    />
                    <EmployerLinker
                        open={!!linkingEmployerToDriverId}
                        currentValue={
                            (units as MonthlyReportDataUnit<Driver>[]).find((r) => r.id === linkingEmployerToDriverId)?.employerId || null
                        }
                        onClose={() => setLinkingEmployerToDriverId(null)}
                        onChange={async (employer) => {
                            if (linkingEmployerToDriverId) {
                                updateDriver.mutate({ id: linkingEmployerToDriverId, employer: employer || null });
                                setLinkingEmployerToDriverId(null);
                            }
                        }}
                        currentEmployers={uniqBy(
                            (units as MonthlyReportDataUnit<Driver>[]).map((r) => r.employer).filter(Boolean) as GlobalCertificateHolder[],
                            "id"
                        )}
                    />
                </>
            )}
        </div>
    );
}

function getDefaultRemovalDate(data: { reportYear: number; reportMonth: number; isAdvancedReporter: boolean }): moment.Moment {
    if (data.isAdvancedReporter) {
        // start of the month
        return moment
            .utc()
            .set({ year: data.reportYear, month: data.reportMonth - 1, date: 1, hour: 12, minute: 0, second: 0, millisecond: 0 });
    } else {
        // end of the month
        return moment
            .utc()
            .set({ year: data.reportYear, month: data.reportMonth - 1, date: 1, hour: 12, minute: 0, second: 0, millisecond: 0 })
            .endOf("month");
    }
}

const tractorTrailerColumns: ColumnDef<MonthlyReportDataUnit<Tractor | Trailer>>[] = [
    {
        id: "mobile",
        cell: function MobileTractorTrailerCell(info) {
            const { reportYear, reportMonth, policyId } = info.table.options.meta as TableMeta;
            const defaultRemovalDate = getDefaultRemovalDate(info.table.options.meta as TableMeta);
            const defaultUpdateDate = moment
                .utc()
                .set({ year: reportYear, month: reportMonth - 1, date: 1, hour: 12, minute: 0, second: 0, millisecond: 0 });
            const unit = info.row.original;
            const type = unit.isTractor ? "tractors" : "trailers";
            const [changeDialogOpen, setChangeDialogOpen] = useState(false);
            const [removalDialogOpen, setRemovalDialogOpen] = useState(false);
            const [updateValueDialogOpen, setUpdateValueDialogOpen] = useState(false);
            return (
                <>
                    <Popover>
                        <Popover.Button className="w-full text-left">
                            <div className="flex items-center justify-between">
                                <p className="font-medium">{unit.unitNumber}</p>
                                <p className="tabular-nums">
                                    {unit.isACV
                                        ? "ACV"
                                        : unit.statedValue?.toLocaleString("en-us", {
                                              style: "currency",
                                              currency: "USD",
                                              maximumFractionDigits: 0,
                                          })}
                                </p>
                            </div>
                            <p className="font-mono uppercase text-stone-600">
                                <HashtagIcon className="-mt-1 mr-1 inline-block h-3 w-3" />
                                {unit.vin}
                            </p>
                            {unit.coverages.length > 0 && (
                                <p className="text-stone-600">
                                    <ShieldCheckIcon className="-mt-1 mr-1 inline-block h-3 w-3 text-black/30" />
                                    {unit.coverages.map((c) => CoverageOption.getMetadata(c).defaultAbbreviation).join(", ")}
                                </p>
                            )}
                        </Popover.Button>
                        <Popover.Content className="w-3/4">
                            <div>
                                <div>
                                    <p className="text-stone-600">
                                        <span>
                                            {unit.year} {unit.make}
                                        </span>{" "}
                                        - <span className="capitalize">{unit.type?.name}</span>
                                    </p>
                                </div>
                                {unit.additionalInterests.length > 0 && (
                                    <div>
                                        {unit.additionalInterests.map((a) => (
                                            <div key={a.id}>
                                                <p>{a.additionalInterest?.name}</p>
                                                <p>
                                                    {[
                                                        a.isAdditionalInsured && "Additional Insured",
                                                        a.isLossPayee && "Loss Payee",
                                                        a.isMortgagee && "Mortgagee",
                                                        a.isPrimaryAndNonContributory && "Primary and Non-Contributory",
                                                        a.isWaiverOfSubrogation && "Waiver of Subrogation",
                                                    ]
                                                        .filter(Boolean)
                                                        .join(", ")}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <hr className="my-2" />
                            <Popover.Item
                                onClick={() => {
                                    setChangeDialogOpen(true);
                                }}
                                disabled={unit.requiresApproval}
                            >
                                <PencilSquareIcon className="h-5 w-5" />
                                <span>Edit{unit.requiresApproval ? " (pending)" : ""}</span>
                            </Popover.Item>
                            <Popover.Item
                                disabled={unit.requiresApproval}
                                onClick={() => {
                                    setRemovalDialogOpen(true);
                                }}
                            >
                                <MinusCircleIcon className="h-5 w-5" />
                                <span>Remove</span>
                            </Popover.Item>
                        </Popover.Content>
                    </Popover>
                    <EquipmentColumns.ChangeDialog
                        open={changeDialogOpen}
                        onClose={() => setChangeDialogOpen(false)}
                        row={unit}
                        type={type}
                        dialogProps={{ baseZIndex: 100 }}
                    />
                    <EquipmentColumns.RemovalDialog
                        open={removalDialogOpen}
                        onClose={() => setRemovalDialogOpen(false)}
                        row={unit}
                        type={type}
                        dialogProps={{ baseZIndex: 100 }}
                        policyId={policyId}
                        defaultRemovalDate={defaultRemovalDate.format("YYYY-MM-DD")}
                    />
                    <EquipmentColumns.UpdateValueDialog
                        open={updateValueDialogOpen}
                        onClose={() => setUpdateValueDialogOpen(false)}
                        row={unit}
                        type={type}
                        dialogProps={{ baseZIndex: 100 }}
                        defaultUpdateDate={defaultUpdateDate.format("YYYY-MM-DD")}
                    />
                </>
            );
        },
        header: () => null,
        meta: { responsive: true, classes: { header: "hidden" } },
    },
    {
        id: "changes",
        accessorKey: "changes",
        enableGlobalFilter: false,
        header: "",
        enableSorting: false,
        cell: (info) => {
            if (info.row.original.changes.length > 0) {
                return <ChangeListTooltip unit={info.row.original} />;
            }
            return null;
        },
    },
    {
        id: "pendingChange",
        enableGlobalFilter: false,
        enableSorting: false,
        header: "",
        cell: (info) => {
            if (!(info.table.options.meta as TableMeta).isFinalized && info.row.original.requestComment) {
                return (
                    <Tooltip
                        title={
                            <div className="font-normal">
                                <span className="mr-2 whitespace-nowrap rounded-full bg-yellow-50 px-2 text-xs text-yellow-700 small-caps">
                                    Pending change:
                                </span>
                                <span className="text-sm italic">{info.row.original.requestComment}</span>
                            </div>
                        }
                    >
                        <InformationCircleIcon className="h-4 w-4 text-stone-500" />
                    </Tooltip>
                );
            }
            return null;
        },
    },
    {
        id: "unitNumber",
        accessorKey: "unitNumber",
        header: "Unit",
        meta: {
            classes: {
                cell: "tabular-nums uppercase",
            },
        },
    },
    {
        id: "year",
        accessorKey: "year",
        enableGlobalFilter: false,
        meta: {
            classes: {
                cell: "tabular-nums",
            },
        },
    },
    {
        id: "make",
        accessorKey: "make",
    },
    {
        id: "type",
        accessorFn: (row) => row.type?.name || "",
    },
    {
        id: "vin",
        accessorKey: "vin",
        meta: {
            classes: {
                cell: "tabular-nums uppercase",
            },
        },
    },
    {
        id: "value",
        accessorFn: (row) =>
            row.isACV
                ? "ACV"
                : row.statedValue?.toLocaleString("en-us", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                  }),
        enableGlobalFilter: false,
    },
    {
        id: "coverages",
        accessorFn: (row) => row.coverages.map((c) => CoverageOption.getMetadata(c).defaultAbbreviation).join(", "),
        enableGlobalFilter: false,
    },
    {
        id: "additionalInterests",
        accessorKey: "additionalInterests",
        header: "ai",
        cell: (info) => {
            const value = info.getValue() as PolicyAdditionalInterest[];
            if (value.length) {
                return (
                    <Tooltip
                        title={
                            <ul>
                                {value.map((ai) => (
                                    <li key={ai.id}>
                                        {ai.additionalInterest?.name || "[Unknown]"} -{" "}
                                        {[
                                            ai.isAdditionalInsured && "Additional Insured",
                                            ai.isLossPayee && "Loss Payee",
                                            ai.isMortgagee && "Mortgagee",
                                            ai.isPrimaryAndNonContributory && "Primary and Non-Contributory",
                                            ai.isWaiverOfSubrogation && "Waiver of Subrogation",
                                        ]
                                            .filter(Boolean)
                                            .join(", ")}
                                    </li>
                                ))}
                            </ul>
                        }
                    >
                        <BuildingLibraryIcon className="h-4 w-4" />
                    </Tooltip>
                );
            }
            return null;
        },
        enableGlobalFilter: false,
    },
    {
        id: "actions",
        cell: (info) => {
            const { reportYear, reportMonth } = info.table.options.meta as TableMeta;
            const defaultRemovalDate = getDefaultRemovalDate(info.table.options.meta as TableMeta);
            const defaultUpdateDate = moment
                .utc()
                .set({ year: reportYear, month: reportMonth - 1, date: 1, hour: 12, minute: 0, second: 0, millisecond: 0 });
            return (
                <TractorTrailerActions
                    unit={info.row.original}
                    type={info.row.original.isTractor ? "tractors" : "trailers"}
                    policyId={(info.table.options.meta as TableMeta).policyId}
                    defaultRemovalDate={defaultRemovalDate.format("YYYY-MM-DD")}
                    defaultUpdateDate={defaultUpdateDate.format("YYYY-MM-DD")}
                />
            );
        },
        enableGlobalFilter: false,
    },
];

const driverColumns: ColumnDef<MonthlyReportDataUnit<Driver>>[] = [
    {
        id: "mobile",
        cell: function MobileDriverCell(info) {
            const meta = info.table.options.meta as TableMeta;
            const defaultRemovalDate = getDefaultRemovalDate(meta);
            const unit = info.row.original;
            const [changeDialogOpen, setChangeDialogOpen] = useState(false);
            const [removalDialogOpen, setRemovalDialogOpen] = useState(false);
            return (
                <>
                    <Popover>
                        <Popover.Button className="w-full text-left">
                            <div className="flex flex-wrap items-center justify-between gap-2">
                                <p className="font-medium">{unit.name}</p>
                                <p className="text-stone-600">{unit.dob ? moment(unit.dob).format("MM/DD/YYYY") : null}</p>
                            </div>
                            <p className="text-stone-600">
                                <CalendarIcon className="-mt-1 mr-1 inline-block h-3 w-3" />
                                Hired on {unit.doh ? moment(unit.doh).format("MM/DD/YYYY") : "Unknown"}
                            </p>
                            <p className="font-mono uppercase text-stone-600">
                                <HashtagIcon className="-mt-1 mr-1 inline-block h-3 w-3" />
                                {unit.license}
                            </p>
                            {unit.coverages.length > 0 && (
                                <p className="text-stone-600">
                                    <ShieldCheckIcon className="-mt-1 mr-1 inline-block h-3 w-3 text-black/30" />
                                    {unit.coverages.map((c) => CoverageOption.getMetadata(c).defaultAbbreviation).join(", ")}
                                </p>
                            )}
                            {unit.policyOwnerOperator ? (
                                <p className="overflow-hidden text-ellipsis whitespace-nowrap text-stone-500">
                                    Employed by {meta.companyName}
                                </p>
                            ) : unit.employer ? (
                                <Tooltip
                                    classes={{ tooltip: "bg-white shadow-lg rounded p-2 text-stone-800 font-normal border" }}
                                    title={
                                        <div>
                                            <p>{unit.employer.name}</p>
                                            <p>{stringifyAddress(unit.employer)}</p>
                                            <p>{unit.employer.email}</p>
                                            <p>{unit.employer.fax}</p>
                                        </div>
                                    }
                                >
                                    <p className="overflow-hidden text-ellipsis whitespace-nowrap text-stone-500">
                                        Employed by {unit.employer.name}
                                    </p>
                                </Tooltip>
                            ) : (
                                <p className="overflow-hidden text-ellipsis whitespace-nowrap text-yellow-600">Employer not set</p>
                            )}
                        </Popover.Button>
                        <Popover.Content className="w-3/4">
                            <Popover.Item
                                onClick={() => {
                                    setChangeDialogOpen(true);
                                }}
                                disabled={unit.requiresApproval}
                            >
                                <PencilSquareIcon className="h-5 w-5" />
                                <span>Edit{unit.requiresApproval ? " (pending)" : ""}</span>
                            </Popover.Item>
                            <Popover.Item
                                disabled={unit.requiresApproval}
                                onClick={() => {
                                    setRemovalDialogOpen(true);
                                }}
                            >
                                <MinusCircleIcon className="h-5 w-5" />
                                <span>Remove</span>
                            </Popover.Item>
                        </Popover.Content>
                    </Popover>
                    <DriverChangeDialog open={changeDialogOpen} onClose={() => setChangeDialogOpen(false)} row={unit} />
                    <DriverRemovalDialog
                        open={removalDialogOpen}
                        onClose={() => setRemovalDialogOpen(false)}
                        row={unit}
                        policyId={meta.policyId}
                        defaultRemovalDate={defaultRemovalDate.format("YYYY-MM-DD")}
                    />
                </>
            );
        },
        header: () => null,
        meta: { responsive: true, classes: { header: "hidden" } },
    },
    {
        id: "changes",
        accessorKey: "changes",
        enableGlobalFilter: false,
        header: "",
        enableSorting: false,
        cell: (info) => {
            if (info.row.original.changes.length > 0) {
                return <ChangeListTooltip unit={info.row.original} />;
            }
            return null;
        },
    },
    {
        id: "pendingChange",
        enableGlobalFilter: false,
        enableSorting: false,
        header: "",
        cell: (info) => {
            if ((info.table.options.meta as TableMeta).isFinalized && info.row.original.requestComment) {
                return (
                    <Tooltip title={info.row.original.requestComment}>
                        <InformationCircleIcon className="h-4 w-4 text-stone-500" />
                    </Tooltip>
                );
            }
            return null;
        },
    },
    {
        id: "name",
        accessorKey: "name",
    },
    {
        id: "license",
        accessorKey: "license",
        meta: {
            classes: {
                cell: "tabular-nums uppercase",
            },
        },
    },
    {
        id: "dob",
        accessorKey: "dob",
        meta: {
            classes: {
                cell: "tabular-nums",
            },
        },
        cell: (info) => (info.getValue() ? moment(info.getValue() as string).format("MM/DD/YYYY") : null),
    },
    {
        id: "doh",
        header: "Hired",
        accessorKey: "doh",
        meta: {
            classes: {
                cell: "tabular-nums",
            },
        },
        cell: (info) => (info.getValue() ? moment(info.getValue() as string).format("MM/DD/YYYY") : null),
    },
    {
        id: "coverages",
        accessorFn: (row) => row.coverages.map((c) => CoverageOption.getMetadata(c).defaultAbbreviation).join(", "),
        enableGlobalFilter: false,
    },
    employerColumn as ColumnDef<MonthlyReportDataUnit<Driver>>,
    {
        id: "actions",
        cell: (info) => {
            const meta = info.table.options.meta as TableMeta;
            const defaultRemovalDate = getDefaultRemovalDate(meta);
            return (
                <DriverActions
                    unit={info.row.original}
                    policyId={meta.policyId}
                    defaultRemovalDate={defaultRemovalDate.format("YYYY-MM-DD")}
                />
            );
        },
        enableGlobalFilter: false,
    },
];

function ChangeListTooltip({ unit }: { unit: MonthlyReportDataUnit<Tractor | Trailer | Driver> }) {
    const isAdded = unit.isNewToPolicy;
    const isRemoved = unit.isRemovedFromPolicy;
    const isAddedAndRemoved = isAdded && isRemoved;
    return (
        <Tooltip
            placement="right-start"
            classes={{
                tooltip: "bg-white rounded text-black border border-stone-100 p-3 shadow-lg !max-w-none font-normal",
            }}
            title={
                <table className="col-spacing-2">
                    <thead>
                        <tr>
                            <th className="text-left font-normal text-stone-500">Date</th>
                            <th className="text-left font-normal text-stone-500">Coverage</th>
                            <th className="text-left font-normal text-stone-500">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {unit.changes
                            .flatMap((change) =>
                                Array.from(change.changesByCoverage.entries()).flatMap(([coverageOptionId, c]) =>
                                    c.changes.map((ch) => [change.date, coverageOptionId, ch] as const)
                                )
                            )
                            .sort((a, b) => b[0].getTime() - a[0].getTime())
                            .map(([date, coverageOptionId, change], i) => (
                                <tr key={i}>
                                    <td className="text-left">{moment.utc(date, "YYYY-MM-DD").format("MM/DD/YY")}</td>
                                    <td>{CoverageOption.getMetadata(coverageOptionId).defaultAbbreviation}</td>
                                    <td className="text-left">{change.description}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            }
        >
            {isAddedAndRemoved ? (
                <span className="cursor-default text-yellow-600">△</span>
            ) : isAdded ? (
                <span className="cursor-default text-emerald-600">+</span>
            ) : isRemoved ? (
                <span className="cursor-default text-red-600">-</span>
            ) : unit.changes.length > 0 ? (
                <span className="cursor-default text-yellow-600">△</span>
            ) : (
                <span></span>
            )}
        </Tooltip>
    );
}

function TractorTrailerActions({
    unit,
    type,
    policyId,
    defaultRemovalDate,
    defaultUpdateDate,
}: {
    unit: MonthlyReportDataUnit<Tractor | Trailer>;
    type: "tractors" | "trailers";
    policyId: string;
    defaultRemovalDate: string;
    defaultUpdateDate: string;
}) {
    const [changeDialogOpen, setChangeDialogOpen] = useState(false);
    const [removalDialogOpen, setRemovalDialogOpen] = useState(false);
    const [updateValueDialogOpen, setUpdateValueDialogOpen] = useState(false);

    return (
        <>
            <Popover>
                <Popover.Content>
                    <Popover.Item
                        onClick={() => {
                            setChangeDialogOpen(true);
                        }}
                        disabled={unit.requiresApproval}
                    >
                        <PencilSquareIcon className="h-5 w-5" />
                        <span>Edit{unit.requiresApproval ? " (pending)" : ""}</span>
                    </Popover.Item>
                    <Popover.Item
                        onClick={() => {
                            setUpdateValueDialogOpen(true);
                        }}
                    >
                        <CurrencyDollarIcon className="h-5 w-5" />
                        <span>Update value</span>
                    </Popover.Item>
                    <Popover.Item
                        disabled={unit.requiresApproval}
                        onClick={() => {
                            if (unit.isNewToPolicy) {
                                useSnackbar.add(
                                    `This unit was added on ${moment(unit.dateAddedToPolicy).format(
                                        "MM/DD/YY"
                                    )}. For reporting accuracy it may only be removed on your next month's report.`,
                                    { variant: "warning", autoHideDuration: 5_000 }
                                );
                            } else {
                                setRemovalDialogOpen(true);
                            }
                        }}
                    >
                        <MinusCircleIcon className="h-5 w-5" />
                        <span>Remove</span>
                    </Popover.Item>
                </Popover.Content>
                <Popover.Button className="rounded p-1 hover:bg-black/5">
                    <EllipsisVerticalIcon className="h-5 w-5" />
                </Popover.Button>
            </Popover>
            <EquipmentColumns.ChangeDialog
                open={changeDialogOpen}
                onClose={() => setChangeDialogOpen(false)}
                row={unit}
                type={type}
                dialogProps={{ baseZIndex: 100 }}
            />
            <EquipmentColumns.RemovalDialog
                open={removalDialogOpen}
                onClose={() => setRemovalDialogOpen(false)}
                row={unit}
                type={type}
                dialogProps={{ baseZIndex: 100 }}
                policyId={policyId}
                defaultRemovalDate={defaultRemovalDate}
            />
            <EquipmentColumns.UpdateValueDialog
                open={updateValueDialogOpen}
                onClose={() => setUpdateValueDialogOpen(false)}
                row={unit}
                type={type}
                dialogProps={{ baseZIndex: 100 }}
                defaultUpdateDate={defaultUpdateDate}
            />
        </>
    );
}

function DriverActions({ unit, policyId, defaultRemovalDate }: { unit: DriverRow; policyId: string; defaultRemovalDate: string }) {
    const [changeDialogOpen, setChangeDialogOpen] = useState(false);
    const [removalDialogOpen, setRemovalDialogOpen] = useState(false);

    return (
        <>
            <Popover>
                <Popover.Content>
                    <Popover.Item
                        onClick={() => {
                            setChangeDialogOpen(true);
                        }}
                        disabled={unit.requiresApproval}
                    >
                        <PencilSquareIcon className="h-5 w-5" />
                        <span>Edit{unit.requiresApproval ? " (pending)" : ""}</span>
                    </Popover.Item>
                    <Popover.Item
                        disabled={unit.requiresApproval}
                        onClick={() => {
                            setRemovalDialogOpen(true);
                        }}
                    >
                        <MinusCircleIcon className="h-5 w-5" />
                        <span>Remove</span>
                    </Popover.Item>
                </Popover.Content>
                <Popover.Button className="rounded p-1 hover:bg-black/5">
                    <EllipsisVerticalIcon className="h-5 w-5" />
                </Popover.Button>
            </Popover>
            <DriverChangeDialog
                open={changeDialogOpen}
                onClose={() => setChangeDialogOpen(false)}
                row={unit}
                dialogProps={{ baseZIndex: 100 }}
            />
            <DriverRemovalDialog
                open={removalDialogOpen}
                onClose={() => setRemovalDialogOpen(false)}
                row={unit}
                dialogProps={{ baseZIndex: 100 }}
                policyId={policyId}
                defaultRemovalDate={defaultRemovalDate}
            />
        </>
    );
}
