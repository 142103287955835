import { MonthlyReportHelper } from "@deathstar/reuse";
import {
    AttributeOption,
    Coverage,
    CoverageOption,
    MonthlyReport,
    MonthlyReportData,
    MonthlyReportDataUnit,
    PolicyLayer,
    Tractor,
    Trailer,
} from "@deathstar/types/northstar";
import { CurrencyDollarIcon, MapIcon } from "@heroicons/react/24/outline";
import { orderBy } from "lodash";
import moment from "moment";
import { forwardRef, Fragment } from "react";

export const MonthlyReportStatic = forwardRef<HTMLDivElement, { report: MonthlyReport; data: MonthlyReportData }>(
    ({ report, data }, ref) => {
        const addedEquipment = orderBy(
            [
                ...data.equipment.tractors.filter((unit) => unit.isNewToPolicy).map((x) => ({ ...x, unitType: "tractor" as const })),
                ...data.equipment.trailers.filter((unit) => unit.isNewToPolicy).map((x) => ({ ...x, unitType: "trailer" as const })),
            ],
            "dateAddedToPolicy",
            "desc"
        );

        const removedEquipment = orderBy(
            [
                ...data.equipment.tractors
                    .filter(
                        (unit) => unit.isRemovedFromPolicy && (!unit.isNewToPolicy || unit.dateAddedToPolicy! < unit.dateRemovedFromPolicy!)
                    )
                    .map((x) => ({ ...x, unitType: "tractor" as const })),
                ...data.equipment.trailers
                    .filter(
                        (unit) => unit.isRemovedFromPolicy && (!unit.isNewToPolicy || unit.dateAddedToPolicy! < unit.dateRemovedFromPolicy!)
                    )
                    .map((x) => ({ ...x, unitType: "trailer" as const })),
            ],
            "dateRemovedFromPolicy",
            "desc"
        );

        const addedDrivers = orderBy(
            data.equipment.drivers.filter((unit) => unit.isNewToPolicy),
            "dateAddedToPolicy",
            "desc"
        );

        const removedDrivers = orderBy(
            data.equipment.drivers.filter(
                (unit) => unit.isRemovedFromPolicy && (!unit.isNewToPolicy || unit.dateAddedToPolicy! < unit.dateRemovedFromPolicy!)
            ),
            "dateRemovedFromPolicy",
            "desc"
        );

        const is15DayRuleApplied = data.endLayer.policy.attributes.some(
            (att) => att.attributeOptionId === AttributeOption.Id.FIFTEEN_DAY_RULE_APPLIES
        );

        const m = moment
            .utc()
            .year(report.year)
            .month(report.month - 1);
        const effectiveDate = moment.utc(data.endLayer.policy.effectiveDate);
        const expirationDate = moment.utc(data.endLayer.policy.expirationDate);

        return (
            <div className="space-y-12 overflow-x-hidden" ref={ref}>
                <div>
                    <div className="flex items-start justify-between">
                        <p className="text-3xl text-navigator-blue">{data.endLayer.policy.firstNamedInsured}</p>
                        <div className="text-gray-700">
                            <p className="text-right">Report created on {moment().format("MM/DD/YY hh:mmZ")}</p>
                            <p className="text-right text-lg">
                                <span>#{data.endLayer.policy.number}</span> / <span>{data.endLayer.policy.writingCompany?.name}</span>
                            </p>
                            <p className="text-right">
                                {effectiveDate.format("MM/DD/YY")} - {expirationDate.format("MM/DD/YY")}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-end gap-4">
                        <p className="text-xl font-medium">{m.format("MMMM YYYY")} Report</p>
                        <p className="text-base text-gray-600">
                            (
                            {effectiveDate.isAfter(m.startOf("month"), "day")
                                ? effectiveDate.format("MM/DD/YY")
                                : m.startOf("month").format("MM/DD/YY")}{" "}
                            -{" "}
                            {expirationDate.isBefore(m.endOf("month"), "day")
                                ? expirationDate.format("MM/DD/YY")
                                : m.endOf("month").format("MM/DD/YY")}
                            )
                        </p>
                    </div>
                </div>
                <div>
                    <div className="flex gap-6">
                        {report.mileage ? (
                            <p>
                                <MapIcon className="-mt-1 mr-2 inline-block h-3 w-3 text-gray-600" />
                                <span className="text-gray-600">Mileage: </span>
                                <span className="tabular-nums">{report.mileage?.toLocaleString("en-us") || 0}</span>
                            </p>
                        ) : null}
                        {report.revenue ? (
                            <p>
                                <CurrencyDollarIcon className="-mt-1 mr-2 inline-block h-3 w-3 text-gray-600" />
                                <span className="text-gray-600">Revenue: </span>
                                <span className="tabular-nums">
                                    {report.revenue?.toLocaleString("en-us", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                    }) || 0}
                                </span>
                            </p>
                        ) : null}
                    </div>
                </div>
                <div className="space-y-8 rounded-lg border">
                    <div className="p-2">
                        <History data={data} />
                    </div>
                    <div className="rounded-b-lg bg-gray-50 p-2">
                        <PremiumTable data={data} />
                        {is15DayRuleApplied && (
                            <p className="text-sm italic text-gray-500">The 15-day-rule has been applied to these calculations.</p>
                        )}
                    </div>
                </div>
                {data.isUnitReporter && (data.equipment.activeTractors.length > 0 || data.equipment.activeTrailers.length > 0) && (
                    <div className="space-y-8">
                        <div className="break-inside-avoid border-l-2 border-emerald-600 pl-2">
                            <p className="mb-2 font-medium text-gray-700">Equipment added this month</p>
                            <table className="w-full text-sm col-spacing-2 row-p-2 row-spacing-2">
                                <thead>
                                    <tr className="text-left text-base text-gray-600 small-caps [&>th]:font-normal">
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>Year</th>
                                        <th>Make</th>
                                        <th>VIN</th>
                                        <th className="text-right">Value</th>
                                        <th>Coverages</th>
                                        <th>Addl. interests</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addedEquipment.length > 0 ? (
                                        addedEquipment.map((unit) => (
                                            <tr key={unit.id}>
                                                <td className="whitespace-normal tabular-nums">
                                                    {unit.dateAddedToPolicy ? moment.utc(unit.dateAddedToPolicy).format("MM/DD/YY") : "-"}
                                                </td>
                                                <td className="whitespace-normal">
                                                    {unit.unitType === "tractor" ? (
                                                        <span className="rounded-full border-blue-100 bg-blue-50 px-2 text-blue-600 small-caps">
                                                            {unit.unitType}
                                                        </span>
                                                    ) : (
                                                        <span className="rounded-full border-pink-100 bg-pink-50 px-2 text-pink-600 small-caps">
                                                            {unit.unitType}
                                                        </span>
                                                    )}
                                                </td>
                                                <td className="whitespace-normal">{unit.year}</td>
                                                <td className="whitespace-normal capitalize">{unit.make}</td>
                                                <td className="whitespace-nowrap uppercase tabular-nums">{unit.vin}</td>
                                                <td className="whitespace-nowrap text-right tabular-nums">
                                                    {unit.isACV
                                                        ? "ACV"
                                                        : unit.statedValue?.toLocaleString("en-us", {
                                                              style: "currency",
                                                              currency: "USD",
                                                              maximumFractionDigits: 0,
                                                          }) || "-"}
                                                </td>
                                                <td>
                                                    {data.endLayer.policy.coverages
                                                        .filter(
                                                            (c) =>
                                                                (unit.unitType === "tractor" &&
                                                                    c.linkedTractors?.some((t) => t.tractorId === unit.id)) ||
                                                                (unit.unitType === "trailer" &&
                                                                    c.linkedTrailers?.some((t) => t.trailerId === unit.id))
                                                        )
                                                        .map((c) => CoverageOption.getMetadata(c.coverageOptionId).defaultAbbreviation)
                                                        .join(", ")}
                                                </td>
                                                <td>
                                                    <AdditionalInterestsString unit={unit} type={unit.unitType} layer={data.endLayer} />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td className="text-gray-500">No equipment added</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="break-inside-avoid border-l-2 border-red-600 pl-2">
                            <p className="mb-2 font-medium text-gray-700">Equipment removed this month</p>
                            <table className="w-full text-sm col-spacing-2 row-p-2 row-spacing-2">
                                <thead>
                                    <tr className="text-left text-base text-gray-600 small-caps [&>th]:font-normal">
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>Year</th>
                                        <th>Make</th>
                                        <th>VIN</th>
                                        <th className="text-right">Value</th>
                                        <th>Addl. interests</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {removedEquipment.length > 0 ? (
                                        removedEquipment.map((unit) => (
                                            <tr key={unit.id}>
                                                <td className="whitespace-normal tabular-nums">
                                                    {unit.dateRemovedFromPolicy
                                                        ? moment.utc(unit.dateRemovedFromPolicy).format("MM/DD/YY")
                                                        : "-"}
                                                </td>
                                                <td className="whitespace-normal">
                                                    {unit.unitType === "tractor" ? (
                                                        <span className="rounded-full border-blue-100 bg-blue-50 px-2 text-blue-600 small-caps">
                                                            {unit.unitType}
                                                        </span>
                                                    ) : (
                                                        <span className="rounded-full border-pink-100 bg-pink-50 px-2 text-pink-600 small-caps">
                                                            {unit.unitType}
                                                        </span>
                                                    )}
                                                </td>
                                                <td className="whitespace-normal">{unit.year}</td>
                                                <td className="whitespace-normal">{unit.make}</td>
                                                <td className="whitespace-nowrap uppercase tabular-nums">{unit.vin}</td>
                                                <td className="whitespace-nowrap text-right tabular-nums">
                                                    {unit.isACV
                                                        ? "ACV"
                                                        : unit.statedValue?.toLocaleString("en-us", {
                                                              style: "currency",
                                                              currency: "USD",
                                                              maximumFractionDigits: 0,
                                                          }) || "-"}
                                                </td>
                                                <td>
                                                    <AdditionalInterestsString unit={unit} type={unit.unitType} layer={data.startLayer} />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td className="text-gray-500">No equipment removed</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {data.isDriverReporter && (
                    <div className="space-y-8">
                        <div className="break-inside-avoid border-l-2 border-emerald-600 pl-2">
                            <p className="mb-2 font-medium text-gray-700">Drivers added this month</p>
                            <table className="w-full text-sm col-spacing-2 row-p-2 row-spacing-2">
                                <thead>
                                    <tr className="text-left text-base text-gray-600 small-caps [&>th]:font-normal">
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>State</th>
                                        <th>License</th>
                                        <th className="text-right">DOB</th>
                                        <th className="text-right">Hired</th>
                                        <th className="text-right">Experience</th>
                                        <th>Notes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addedDrivers.length > 0 ? (
                                        addedDrivers.map((unit) => (
                                            <tr key={unit.id}>
                                                <td className="whitespace-normal tabular-nums">
                                                    {unit.dateAddedToPolicy ? moment.utc(unit.dateAddedToPolicy).format("MM/DD/YY") : "-"}
                                                </td>
                                                <td>{unit.name}</td>
                                                <td>{unit.state}</td>
                                                <td>{unit.license}</td>
                                                <td className="text-right tabular-nums">
                                                    {unit.dob ? moment(unit.dob).format("MM/DD/YYYY") : ""}
                                                </td>
                                                <td className="text-right tabular-nums">
                                                    {unit.doh ? moment(unit.doh).format("MM/DD/YYYY") : ""}
                                                </td>
                                                <td className="text-right tabular-nums">{unit.experience}</td>
                                                <td>{unit.employer?.name}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td className="text-gray-500">No drivers added</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="break-inside-avoid border-l-2 border-red-600 pl-2">
                            <p className="mb-2 font-medium text-gray-700">Drivers removed this month</p>
                            <table className="w-full text-sm col-spacing-2 row-p-2 row-spacing-2">
                                <thead>
                                    <tr className="text-left text-base text-gray-600 small-caps [&>th]:font-normal">
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>State</th>
                                        <th>License</th>
                                        <th className="text-right">DOB</th>
                                        <th className="text-right">Hired</th>
                                        <th className="text-right">Experience</th>
                                        <th>Employer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {removedDrivers.length > 0 ? (
                                        removedDrivers.map((unit) => (
                                            <tr key={unit.id}>
                                                <td className="whitespace-normal tabular-nums">
                                                    {unit.dateRemovedFromPolicy
                                                        ? moment.utc(unit.dateRemovedFromPolicy).format("MM/DD/YY")
                                                        : "-"}
                                                </td>
                                                <td>{unit.name}</td>
                                                <td>{unit.state}</td>
                                                <td>{unit.license}</td>
                                                <td className="text-right tabular-nums">
                                                    {unit.dob ? moment(unit.dob).format("MM/DD/YYYY") : ""}
                                                </td>
                                                <td className="text-right tabular-nums">
                                                    {unit.doh ? moment(unit.doh).format("MM/DD/YYYY") : ""}
                                                </td>
                                                <td className="text-right tabular-nums">{unit.experience}</td>
                                                <td>{unit.employer?.name}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td className="text-gray-500">No drivers removed</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {data.isUnitReporter && data.equipment.activeTractors.length > 0 && (
                    <div className="break-inside-avoid">
                        <p className="mb-2 font-medium text-gray-700">Current tractors</p>
                        <ActiveTractorTrailerTable data={data} type="tractor" />
                    </div>
                )}
                {data.isUnitReporter && data.equipment.activeTrailers.length > 0 && (
                    <div className="break-inside-avoid">
                        <p className="mb-2 font-medium text-gray-700">Current trailers</p>
                        <ActiveTractorTrailerTable data={data} type="trailer" />
                    </div>
                )}
                {data.isDriverReporter && (
                    <div className="break-inside-avoid">
                        <p className="mb-2 font-medium text-gray-700">Current drivers</p>
                        <table className="w-full text-sm col-spacing-2 row-p-2 row-spacing-2">
                            <thead>
                                <tr className="text-left text-base text-gray-600 small-caps [&>th]:font-normal">
                                    <th>Name</th>
                                    <th>State</th>
                                    <th>License</th>
                                    <th className="text-right">DOB</th>
                                    <th className="text-right">Hired</th>
                                    <th className="text-right">Experience</th>
                                    <th>Employer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.equipment.activeDrivers.map((unit) => (
                                    <tr key={unit.id}>
                                        <td>{unit.name}</td>
                                        <td>{unit.state}</td>
                                        <td>{unit.license}</td>
                                        <td className="text-right tabular-nums">{unit.dob ? moment(unit.dob).format("MM/DD/YYYY") : ""}</td>
                                        <td className="text-right tabular-nums">{unit.doh ? moment(unit.doh).format("MM/DD/YYYY") : ""}</td>
                                        <td className="text-right tabular-nums">{unit.experience}</td>
                                        <td>{unit.employer?.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        );
    }
);

function History({ data }: { data: MonthlyReportData }) {
    const previousTractors = data.equipment.tractors.filter((t) => MonthlyReportHelper.isTractorOnPolicy(t, data.startLayer));
    const previousTrailers = data.equipment.trailers.filter((t) => MonthlyReportHelper.isTrailerOnPolicy(t, data.startLayer));
    const previousDrivers = data.equipment.drivers.filter((t) => MonthlyReportHelper.isDriverOnPolicy(t, data.startLayer));

    return (
        <div className="">
            <table className="col-spacing-12 row-p-2 row-spacing-1">
                <thead>
                    <tr className="text-gray-600 small-caps">
                        <th></th>
                        <th className="text-right font-normal">Last month</th>
                        <th className="text-right font-normal">This month</th>
                        <th className="text-right font-normal">Change</th>
                    </tr>
                </thead>
                <tbody>
                    {data.equipment.activeTractors.length > 0 && (
                        <tr>
                            <td>Tractors</td>
                            <td className="text-right tabular-nums">{previousTractors.length}</td>
                            <td className="text-right font-medium tabular-nums">{data.equipment.activeTractors.length}</td>
                            <td className="text-right tabular-nums">{data.equipment.activeTractors.length - previousTractors.length}</td>
                        </tr>
                    )}
                    {data.equipment.activeTractors.length > 0 && (
                        <tr>
                            <td>Tractor value</td>
                            <td className="text-right tabular-nums">
                                {previousTractors
                                    .reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0)
                                    .toLocaleString("en-us", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                    })}
                            </td>
                            <td className="text-right font-medium tabular-nums">
                                {data.equipment.activeTractors
                                    .reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0)
                                    .toLocaleString("en-us", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                    })}
                            </td>
                            <td className="text-right tabular-nums">
                                {(
                                    data.equipment.activeTractors.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0) -
                                    previousTractors.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0)
                                ).toLocaleString("en-us", {
                                    style: "currency",
                                    currency: "USD",
                                    maximumFractionDigits: 0,
                                })}
                            </td>
                        </tr>
                    )}
                    {data.equipment.activeTrailers.length > 0 && (
                        <tr>
                            <td>Trailers</td>
                            <td className="text-right tabular-nums">{previousTrailers.length}</td>
                            <td className="text-right font-medium tabular-nums">{data.equipment.activeTrailers.length}</td>
                            <td className="text-right tabular-nums">{data.equipment.activeTrailers.length - previousTrailers.length}</td>
                        </tr>
                    )}
                    {data.equipment.activeTrailers.length > 0 && (
                        <tr>
                            <td>Trailer value</td>
                            <td className="text-right tabular-nums">
                                {previousTrailers
                                    .reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0)
                                    .toLocaleString("en-us", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                    })}
                            </td>
                            <td className="text-right font-medium tabular-nums">
                                {data.equipment.activeTrailers
                                    .reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0)
                                    .toLocaleString("en-us", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                    })}
                            </td>
                            <td className="text-right tabular-nums">
                                {(
                                    data.equipment.activeTrailers.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0) -
                                    previousTrailers.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0)
                                ).toLocaleString("en-us", {
                                    style: "currency",
                                    currency: "USD",
                                    maximumFractionDigits: 0,
                                })}
                            </td>
                        </tr>
                    )}
                    {data.equipment.activeTractors.length > 0 && data.equipment.activeTrailers.length > 0 && (
                        <tr>
                            <td>Total insured value</td>
                            <td className="text-right tabular-nums">
                                {(
                                    previousTractors.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0) +
                                    previousTrailers.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0)
                                ).toLocaleString("en-us", { style: "currency", currency: "USD", maximumFractionDigits: 0 })}
                            </td>
                            <td className="text-right font-medium tabular-nums">
                                {(
                                    data.equipment.activeTractors.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0) +
                                    data.equipment.activeTrailers.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0)
                                ).toLocaleString("en-us", { style: "currency", currency: "USD", maximumFractionDigits: 0 })}
                            </td>
                            <td className="text-right tabular-nums">
                                {(
                                    data.equipment.activeTractors.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0) +
                                    data.equipment.activeTrailers.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0) -
                                    (previousTractors.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0) +
                                        previousTrailers.reduce((acc, t) => acc + (t.isACV ? 0 : t.statedValue || 0), 0))
                                ).toLocaleString("en-us", { style: "currency", currency: "USD", maximumFractionDigits: 0 })}
                            </td>
                        </tr>
                    )}
                    {data.isDriverReporter && (
                        <tr>
                            <td>Drivers</td>
                            <td className="text-right tabular-nums">{previousDrivers.length}</td>
                            <td className="text-right font-medium tabular-nums">{data.equipment.activeDrivers.length}</td>
                            <td className="text-right tabular-nums">{data.equipment.activeDrivers.length - previousDrivers.length}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

function AdditionalInterestsString({
    unit,
    layer,
    type,
}: {
    unit: MonthlyReportDataUnit<Tractor | Trailer>;
    type: "tractor" | "trailer";
    layer: PolicyLayer;
}) {
    return (
        <span>
            {layer.policy.additionalInterests
                .filter(
                    (ai) =>
                        (ai.additionalInterest?.name && type === "tractor" && ai.linkedTractors?.some((lt) => lt.tractorId === unit.id)) ||
                        (type === "trailer" && ai.linkedTrailers?.some((lt) => lt.trailerId === unit.id))
                )
                .map(
                    (ai) =>
                        `${ai.additionalInterest?.name} (${[
                            ai.isAdditionalInsured && "AI",
                            ai.isLossPayee && "LP",
                            ai.isMortgagee && "MG",
                            ai.isPrimaryAndNonContributory && "PNC",
                            ai.isWaiverOfSubrogation && "WOS",
                        ]
                            .filter(Boolean)
                            .join(", ")})`
                )
                .join("; ")}
        </span>
    );
}

function PremiumTable({ data }: { data: MonthlyReportData }) {
    return (
        <table className="w-full col-spacing-6 row-p-2 row-spacing-1">
            <thead>
                <tr className="text-gray-600 small-caps">
                    <th className="text-left font-normal">Coverage</th>
                    <th className="text-left font-normal">Basis</th>
                    <th className="text-right font-normal">Value</th>
                    <th className="text-right font-normal">Rate</th>
                    <th className="text-right font-normal">Total</th>
                </tr>
            </thead>
            <tbody>
                {Array.from(data.premiumByCoverage.entries()).map(([coverageOptionId, { rates }]) => (
                    <Fragment key={coverageOptionId}>
                        {rates.map((rate) => (
                            <tr key={`${coverageOptionId}-${rate.basis}-${rate.description}`}>
                                <td className="text-left">
                                    {CoverageOption.getMetadata(coverageOptionId).defaultAbbreviation}
                                    {rate.description && <span className="text-gray-500"> / {rate.description}</span>}
                                </td>
                                <td className="text-left">{rate.basis}</td>
                                <td className="text-right tabular-nums">
                                    {rate.factor.toLocaleString(
                                        "en-us",
                                        rate.basis === Coverage.Basis.REVENUE ||
                                            rate.basis === Coverage.Basis.VALUES ||
                                            rate.basis === Coverage.Basis.OTHER
                                            ? { style: "currency", currency: "USD", maximumFractionDigits: 0 }
                                            : {}
                                    )}
                                </td>
                                <td className="text-right tabular-nums">
                                    {(rate.basis === Coverage.Basis.VALUES || rate.basis === Coverage.Basis.REVENUE
                                        ? rate.rate * 100
                                        : rate.rate
                                    ).toLocaleString("en-us", {
                                        maximumFractionDigits: 12,
                                    })}{" "}
                                    <span className="text-gray-500">
                                        {rate.basis === Coverage.Basis.MILEAGE
                                            ? "per mile"
                                            : rate.basis === Coverage.Basis.REVENUE || rate.basis === Coverage.Basis.VALUES
                                            ? "per $100"
                                            : rate.basis === Coverage.Basis.UNITS
                                            ? `per ${
                                                  coverageOptionId === CoverageOption.Id.OCCUPATIONAL_ACCIDENT ||
                                                  coverageOptionId === CoverageOption.Id.CONTINGENT_LIABILITY ||
                                                  coverageOptionId === CoverageOption.Id.NON_TRUCKING_LIABILITY
                                                      ? "driver"
                                                      : "unit"
                                              }`
                                            : null}
                                    </span>
                                </td>
                                <td className="text-right tabular-nums">
                                    {rate.total.toLocaleString("en-us", { style: "currency", currency: "USD" })}
                                </td>
                            </tr>
                        ))}
                    </Fragment>
                ))}
            </tbody>
            <tfoot className="border-t">
                <tr className="text-gray-500">
                    <td colSpan={4} className="text-right">
                        Subtotal
                    </td>
                    <td className="text-right tabular-nums">
                        {data.subtotalPremium.toLocaleString("en-us", { style: "currency", currency: "USD" })}
                    </td>
                </tr>
                {data.minimumPremiumApplied > 0 && (
                    <tr className="text-gray-500">
                        <td colSpan={4} className="text-right">
                            Minimum premium applied
                        </td>
                        <td className="text-right tabular-nums">
                            {data.minimumPremiumApplied.toLocaleString("en-us", { style: "currency", currency: "USD" })}
                        </td>
                    </tr>
                )}
                <tr className="font-medium">
                    <td colSpan={4} className="text-right align-top">
                        Total premium due
                    </td>
                    <td className="text-right tabular-nums">
                        <p>{data.totalPremium.toLocaleString("en-us", { style: "currency", currency: "USD" })}</p>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
}

function ActiveTractorTrailerTable({ data, type }: { data: MonthlyReportData; type: "tractor" | "trailer" }) {
    const units = type === "tractor" ? data.equipment.activeTractors : data.equipment.activeTrailers;
    return (
        <table className="w-full text-sm col-spacing-2 row-p-2 row-spacing-2">
            <thead>
                <tr className="text-left text-base text-gray-600 small-caps [&>th]:font-normal">
                    <th>Year</th>
                    <th>Make</th>
                    <th>VIN</th>
                    <th className="text-right">Value</th>
                    <th>Coverages</th>
                    <th>Addl. interests</th>
                </tr>
            </thead>
            <tbody>
                {units.map((unit: MonthlyReportDataUnit<Tractor | Trailer>) => (
                    <tr key={unit.id}>
                        <td className="whitespace-normal">{unit.year}</td>
                        <td className="whitespace-normal">{unit.make}</td>
                        <td className="whitespace-nowrap tabular-nums">{unit.vin}</td>
                        <td className="whitespace-nowrap text-right tabular-nums">
                            {unit.isACV
                                ? "ACV"
                                : unit.statedValue?.toLocaleString("en-us", {
                                      style: "currency",
                                      currency: "USD",
                                      maximumFractionDigits: 0,
                                  }) || "-"}
                        </td>
                        <td>
                            {data.endLayer.policy.coverages
                                .filter(
                                    (c) =>
                                        (type === "tractor" && c.linkedTractors?.some((t) => t.tractorId === unit.id)) ||
                                        (type === "trailer" && c.linkedTrailers?.some((t) => t.trailerId === unit.id))
                                )
                                .map((c) => CoverageOption.getMetadata(c.coverageOptionId).defaultAbbreviation)
                                .join(", ")}
                        </td>
                        <td>
                            <AdditionalInterestsString unit={unit} type={type} layer={data.endLayer} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
