import { CoverageOption, GlobalCertificateHolder, Tractor, Trailer } from "@deathstar/types/northstar";
import { DriverRow, EquipmentRow } from "@deathstar/types/waypoint";
import { ActionButton, DataTable, fuzzyFilter, Tabs, useSnackbar } from "@deathstar/ui";
import { PlusIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import { useMutation } from "@tanstack/react-query";
import {
    ColumnDef,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import { uniqBy } from "lodash";
import { useMemo, useState } from "react";
import { animated, useSpring } from "react-spring";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import Loader from "../../components/loader/Loader";
import { DriverColumns, DriverTableMeta } from "./DriverColumns";
import { EmployerLinker } from "./EmployerLinker";
import { NewDriverDialog } from "./NewDriverDialog";
import { TractorLinker, TrailerLinker } from "./TractorLinker";

const AnimatedDataTable = animated(DataTable<DriverRow>);

export default function DriversTable({ data }: { data: DriverRow[] | undefined }) {
    const accountId = useAccountId();
    const { data: companyName } = api.organization.useById(accountId!, { select: (org) => org.company.name });
    const [filter, setFilter] = useState<"all" | "company" | "owner-operator">("all");
    const [adding, setAdding] = useState(false);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [linkingTractorToDriverId, setLinkingTractorToDriverId] = useState<number | null>(null);
    const [linkingTrailerToDriverId, setLinkingTrailerToDriverId] = useState<number | null>(null);
    const [linkingEmployerToDriverId, setLinkingEmployerToDriverId] = useState<number | null>(null);
    const updateDriver = api.drivers.useUpdate(accountId!);
    const memoizedData = useMemo(() => data || [], [data]);

    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    const sortingState = useMemo(() => {
        return [{ id: "pending", desc: true }, ...sorting];
    }, [sorting]);

    const columnFilters = useMemo(() => {
        if (filter === "all") {
            return [];
        }
        return [{ id: "owner-operator", value: filter === "owner-operator" }];
    }, [filter]);

    const columnVisibility = useMemo<VisibilityState>(() => ({ "owner-operator": filter !== "company" }), [filter]);

    const meta: DriverTableMeta = useMemo(
        () => ({
            openTractorLinker(driverId: number) {
                setLinkingTractorToDriverId(driverId);
            },
            openTrailerLinker(driverId: number) {
                setLinkingTrailerToDriverId(driverId);
            },
            openEmployerLinker(driverId: number) {
                setLinkingEmployerToDriverId(driverId);
            },
            companyName: companyName || "",
        }),
        [companyName]
    );

    const columns = useMemo(() => {
        return (
            [
                DriverColumns.mobileCell,
                DriverColumns.pending,
                DriverColumns.upcomingDate,
                DriverColumns.nameColumn,
                DriverColumns.licenseColumn,
                DriverColumns.dobColumn,
                DriverColumns.dohColumn,
                DriverColumns.expColumn,
                DriverColumns.ownerOperatorColumn,
                DriverColumns.coveragesColumn,
                DriverColumns.linkedTractorsColumn,
                DriverColumns.linkedTrailersColumn,
                memoizedData.some((r) =>
                    r.coverageOptions?.some(
                        (x) => x.id === CoverageOption.Id.OCCUPATIONAL_ACCIDENT || x.id === CoverageOption.Id.NON_TRUCKING_LIABILITY
                    )
                ) && DriverColumns.employerColumn,
                DriverColumns.actionsColumn,
            ] as ColumnDef<DriverRow>[]
        ).filter(Boolean);
    }, [memoizedData]);

    const table = useReactTable({
        data: memoizedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        globalFilterFn: fuzzyFilter,
        state: {
            sorting: sortingState,
            columnFilters,
            columnVisibility,
        },
        initialState: {
            pagination: {
                pageSize: 30,
            },
        },
        onSortingChange: setSorting,
        meta,
    });

    const downloadCSV = useMutation({
        mutationFn: async () => {
            const tableData = memoizedData.filter((r) => {
                switch (filter) {
                    case "company":
                        return !r.policyOwnerOperator;
                    case "owner-operator":
                        return r.policyOwnerOperator;
                    default:
                        return true;
                }
            });

            if (tableData.length === 0) {
                useSnackbar.add("No data to export", { variant: "info" });
                return;
            }

            const tractors = await api.equipment.findTractors(tableData[0].accountId);
            const trailers = await api.equipment.findTrailers(tableData[0].accountId);

            const rows: string[] = [];
            tableData.forEach((row) => {
                rows.push(
                    [
                        row.name,
                        row.license,
                        row.dob,
                        row.policyDoh,
                        row.policyExp,
                        row.policyOwnerOperator ? "Owner Operator" : "Company",
                        row.coverageOptions?.map((x) => CoverageOption.getMetadata(x.id).defaultAbbreviation).join(",") || "",
                        row.tractors
                            ?.map((t1) => tractors.find((t2) => t1.id === t2.id) as EquipmentRow<Tractor>)
                            .filter(Boolean)
                            .map((t) => t.unitNumber || t.vin?.slice(-4))
                            .filter(Boolean)
                            .map((x) => "#" + x)
                            .join(",") || "",
                        row.trailers
                            ?.map((t1) => trailers.find((t2) => t1.id === t2.id) as EquipmentRow<Trailer>)
                            .filter(Boolean)
                            .map((t) => t.unitNumber || t.vin?.slice(-4))
                            .filter(Boolean)
                            .map((x) => "#" + x)
                            .join(",") || "",
                        row.employer?.name,
                    ]
                        .map((x) => `"${x ?? ""}"`)
                        .join(",")
                );
            });

            const csv = [
                [
                    "Name",
                    "License",
                    "DOB",
                    "DOH",
                    "EXP",
                    "Type",
                    "Coverage",
                    "Tractors",
                    "Trailers",

                    tableData.some((r) =>
                        r.coverageOptions?.some(
                            (x) => x.id === CoverageOption.Id.OCCUPATIONAL_ACCIDENT || x.id === CoverageOption.Id.NON_TRUCKING_LIABILITY
                        )
                    ) && "Employer",
                ]
                    .filter(Boolean)
                    .join(","),
                ...rows,
            ].join("\n");

            const blob = new Blob([csv], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.download = "drivers.csv";
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
    });

    return (
        <div>
            {data ? (
                <>
                    <AnimatedDataTable
                        style={spring}
                        table={table}
                        headerRow={
                            <div className="flex items-end gap-4">
                                <Tabs
                                    tabs={[
                                        {
                                            label: () => <p className="w-32 px-2 py-1">All drivers</p>,
                                            value: "all",
                                            buttonProps: { className: "z-[1]" },
                                        },
                                        {
                                            label: () => <p className="w-32 px-2 py-1">Company</p>,
                                            value: "company",
                                            buttonProps: { className: "z-[1]" },
                                        },
                                        {
                                            label: () => <p className="w-32 px-2 py-1">Owner Operators</p>,
                                            value: "owner-operator",
                                            buttonProps: { className: "z-[1]" },
                                        },
                                    ]}
                                    value={filter}
                                    onChange={setFilter}
                                    className="rounded-md bg-stone-100 py-1 shadow-inner"
                                    borderClasses="bg-white border border-waypoint-blue z-[0] shadow rounded-md"
                                />
                                <div className="grow"></div>
                                <ActionButton disabled={downloadCSV.isPending} onClick={() => downloadCSV.mutate()}>
                                    {downloadCSV.isPending ? <CircularProgress size="1rem" /> : <TableCellsIcon className="h-5 w-5" />}
                                    Export
                                </ActionButton>
                                <ActionButton onClick={() => setAdding(true)}>
                                    <PlusIcon className="h-5 w-5" />
                                    Add driver
                                </ActionButton>
                                <DataTable.Search table={table} />
                            </div>
                        }
                    />
                    <NewDriverDialog open={adding} onClose={() => setAdding(false)} />
                    <TractorLinker
                        open={!!linkingTractorToDriverId}
                        currentValue={memoizedData.find((r) => r.id === linkingTractorToDriverId)?.tractors || []}
                        onClose={() => setLinkingTractorToDriverId(null)}
                        onChange={async (tractors) => {
                            if (linkingTractorToDriverId) {
                                updateDriver.mutate({ id: linkingTractorToDriverId, tractors });
                                setLinkingTractorToDriverId(null);
                            }
                        }}
                    />
                    <TrailerLinker
                        open={!!linkingTrailerToDriverId}
                        currentValue={memoizedData.find((r) => r.id === linkingTrailerToDriverId)?.trailers || []}
                        onClose={() => setLinkingTrailerToDriverId(null)}
                        onChange={async (trailers) => {
                            if (linkingTrailerToDriverId) {
                                updateDriver.mutate({ id: linkingTrailerToDriverId, trailers });
                                setLinkingTrailerToDriverId(null);
                            }
                        }}
                    />
                    <EmployerLinker
                        open={!!linkingEmployerToDriverId}
                        currentValue={memoizedData.find((r) => r.id === linkingEmployerToDriverId)?.employerId || null}
                        onClose={() => setLinkingEmployerToDriverId(null)}
                        onChange={async (employer) => {
                            if (linkingEmployerToDriverId) {
                                updateDriver.mutate({ id: linkingEmployerToDriverId, employer: employer || null });
                                setLinkingEmployerToDriverId(null);
                            }
                        }}
                        currentEmployers={uniqBy(memoizedData.map((r) => r.employer).filter(Boolean) as GlobalCertificateHolder[], "id")}
                    />
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
}
