import { classNames } from "@deathstar/ui";
import { SvgIcon } from "@material-ui/core";

export function DotAnalysisAppIcon(options?: DotAnalysisAppIcon.IOptions): JSX.Element {
    const _colors = {
        top: options?.top || "#da6027",
        right: options?.right || "#2b4572",
        left: options?.left || "#4f77bb",
    };

    if (options?.white) {
        _colors.top = "white";
        _colors.right = "white";
        _colors.left = "white";
    }

    if (options?.primary) {
        _colors.top = "rgb(13, 68, 123)";
        _colors.right = "rgb(13, 68, 123)";
        _colors.left = "rgb(13, 68, 123)";
    }

    return (
        <SvgIcon id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425.51 428.03" className={classNames(options?.className)}>
            <path
                fill={`var(--top, ${_colors.top || "#da6027"})`}
                d="M106.9,161.27c10.89-20.53,25.45-36.71,43.68-48.56,18.24-11.85,37.96-18.42,59.17-19.72,21.21-1.3,41.62,3.24,61.21,13.63,19.36,10.27,34.42,24.41,45.22,42.39,3.67-.49,7.42-1.09,11.06-1.54l65.4-11.46c-3.69-8.36-7.93-16.55-12.78-24.54-18.41-30.28-43.32-53.76-74.73-70.41-31.41-16.66-64.82-24.11-100.21-22.36-35.4,1.75-68.13,11.99-98.18,30.72-30.06,18.72-53.33,43.65-69.82,74.74-16.82,31.72-24.48,65.12-22.95,100.2.12,2.72.38,5.39.6,8.07,24.64-10.88,50.94-21.23,78.8-30.8,2.14-13.86,6.62-27.32,13.53-40.36Z"
            />
            <path
                fill={`var(--right, ${_colors.right || "#2b4572"})`}
                d="M329.41,243.39c-2.62,10.04-6.48,19.94-11.66,29.71-10.72,20.22-25.01,36.25-42.84,48.11-4.35,2.89-8.79,5.45-13.32,7.71,1.95,26.4,4.85,52.22,8.61,77.26,16.29-4.92,31.95-11.92,47-21.01,30.2-18.25,53.71-43.24,70.54-74.96,11.39-21.48,18.35-44.06,21.07-67.67-24.4-.48-51.93-.17-79.4.85Z"
            />
            <path
                fill={`var(--left, ${_colors.left || "#4f77bb"})`}
                d="M178.09,336.85c-7.47-2.2-14.82-5.22-22.04-9.04-19.91-10.56-35.42-25.06-46.55-43.52-2.52-4.18-4.74-8.47-6.72-12.83-26.02,5.55-51.02,11.75-74.86,18.51,4.34,10.91,9.61,21.54,15.96,31.84,18.41,29.89,43.49,53.25,75.21,70.07,24.87,13.19,50.96,20.62,78.23,22.46-5.29-17.95-10.19-36.36-14.52-55.24-1.71-7.48-3.21-14.87-4.71-22.25Z"
            />
        </SvgIcon>
    );
}

export namespace DotAnalysisAppIcon {
    export interface IOptions
        extends Partial<{ top: string; right: string; left: string; white: boolean; primary: boolean; className?: string }> {}
}
