import { Type } from "class-transformer";
import { Comparison } from "./Comparison/Comparison";
import { CoverageCompositeRating } from "./CoverageCompositeRating";
import { CoverageOption } from "./CoverageOption";
import { EquipmentComparison } from "./PolicyLayer";
import { Tractor } from "./Tractor";

export class CompositeRatingLinkedTractor implements EquipmentComparison.IIsComparable {
    static compare({
        base,
        compare,
        comparisonLayer,
        baseLayer,
    }: Comparison.IOptions<CompositeRatingLinkedTractor>): Map<CoverageOption.Id, CompositeRatingLinkedTractor.IComparisonReturn> {
        const map = new Map<CoverageOption.Id, CompositeRatingLinkedTractor.IComparisonReturn>();

        if (compare) {
            for (const [cvgOptId, linkedEntity] of Object.entries(compare) as unknown as [
                CoverageOption.Id,
                CompositeRatingLinkedTractor
            ][]) {
                const from = base?.[cvgOptId];
                const to = linkedEntity;
                map.set(cvgOptId, CompositeRatingLinkedTractor.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        if (base) {
            for (const [cvgOptId, linkedEntity] of Object.entries(base) as unknown as [CoverageOption.Id, CompositeRatingLinkedTractor][]) {
                if (map.has(cvgOptId)) continue;
                const from = linkedEntity;
                const to = compare?.[cvgOptId];
                map.set(cvgOptId, CompositeRatingLinkedTractor.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        return map;
    }

    static #compare({
        base,
        compare,
    }: Partial<Comparison.IOptions.Entity<CompositeRatingLinkedTractor>>): CompositeRatingLinkedTractor.IComparisonReturn {
        const comparison = new Comparison(CompositeRatingLinkedTractor, base, compare) as CompositeRatingLinkedTractor.IComparisonReturn;
        comparison.setField("tractorId", compare?.tractorId || base?.tractorId);
        comparison.setField("tractor", compare?.tractor || base?.tractor);

        if (!base) {
            return comparison.setNew({
                obj: compare,
                description: `Add ${compare?.tractor?.vin} selected vin TRACTOR`,
                subComparison: comparison as Comparison<unknown>,
                label: "Composite Rating ASDF",
            });
        }

        if (!compare) {
            return comparison.setDelete({
                obj: base,
                description: `Delete ${base?.tractor?.vin} base vin TRACTOR`,
                subComparison: comparison as Comparison<unknown>,
                label: "Composite Rating ASDF",
            });
        }

        return comparison;
    }

    id: number;
    createdDate: Date;

    tractorId: number;
    @Type(() => Tractor)
    tractor?: Tractor;

    compositeRatingId: number;
    compositeRatingCreatedDate: Date;
    compositeRating?: CoverageCompositeRating;

    comparison?: {
        isNewToPolicy?: boolean;
        isDeletedFromPolicy?: boolean;
    };

    compare(
        comparisonLinkedTrailer: CompositeRatingLinkedTractor,
        options?: Comparison.getBaseAndComparisonObjects.IOptions
    ): Comparison<CompositeRatingLinkedTractor> {
        return CompositeRatingLinkedTractor.#compare(
            Comparison.getBaseAndComparisonObjects(
                {
                    initiatorEntity: this,
                    comparisonEntity: comparisonLinkedTrailer,
                },
                options
            )
        );
    }

    toString(): string {
        if (!this.tractor) {
            return `CompositeRatingLinkedTractor: ${this.tractorId}`;
        }
        const baseStringParts = [this.tractor.toString()];
        return baseStringParts.join(" ");
    }
}

export namespace CompositeRatingLinkedTractor {
    export interface IComparisonReturn extends Comparison<CompositeRatingLinkedTractor> {
        tractorId: number;
        tractor?: Tractor;
    }
}
