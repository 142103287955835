import { DateTime, memoize } from "@deathstar/reuse";
import { round } from "mathjs";

import { FMCSA } from "@deathstar/types";
import { AuthorityHistory } from "./AuthorityHistory/AuthorityHistory";
import { Basic } from "./Basic/Basic";
import { ControlledSubstances as ControlledSubstancesBasic } from "./Basic/ControlledSubstances/ControlledSubstances";
import { CrashIndicator as CrashIndicatorBasic } from "./Basic/CrashIndicator/CrashIndicator";
import { DriverFitness as DriverFitnessBasic } from "./Basic/DriverFitness/DriverFitness";
import { Hazmat as HazmatBasic } from "./Basic/Hazmat/Hazmat";
import { HoursOfService as HoursOfServiceBasic } from "./Basic/HoursOfService/HoursOfService";
import { SmsResult } from "./Basic/SmsResult";
import { SmsResults } from "./Basic/SmsResults";
import { UnsafeDriving as UnsafeDrivingBasic } from "./Basic/UnsafeDriving/UnsafeDriving";
import { VehicleMaintenance as VehicleMaintenanceBasic } from "./Basic/VehicleMaintenance/VehicleMaintenance";
import { CarrierOutOfServiceRate } from "./CarrierOutOfServiceRates/CarrierOutOfServiceRate";
import { CarrierOutOfServiceRates } from "./CarrierOutOfServiceRates/CarrierOutOfServiceRates";
import { Census } from "./Census/Census";
import { CensusHistorical } from "./CensusHistorical/CensusHistorical";
import { Configuration } from "./Configuration";
import { Crash } from "./Crash/Crash";
import { Crashes } from "./Crashes/Crashes";
import { EnforcementCase } from "./EnforcementCase/EnforcementCase";
import { EnforcementCases } from "./EnforcementCases/EnforcementCases";
import { Inspection } from "./Inspection/Inspection";
import { Inspections } from "./Inspections/Inspections";
import { InsuranceHistory } from "./InsuranceHistory/InsuranceHistory";
import { InsuranceHistoryEntry } from './InsuranceHistory/InsuranceHistoryEntry';
import { IssResult } from "./IssResults/IssResult";
import { IssResults } from "./IssResults/IssResults";
import { Mcs150History } from "./Mcs150History/Mcs150Entry";
import { Mcs150HistoryEntry } from "./Mcs150History/Mcs150HistoryEntry";
import { NationalAverageOutOfServiceRate } from "./NationalAverageOutOfServiceRates/NationalAverageOutOfServiceRate";
import { NationalAverageOutOfServiceRates } from "./NationalAverageOutOfServiceRates/NationalAverageOutOfServiceRates";
import { SafetyEventGroup } from "./SafetyEventGroup/SafetyEventGroup";
import { Trends } from "./Trends/Trends";
import { Unit } from "./Unit/Unit";
import { Units } from "./Units/Units";
import { Violation } from "./Violation/Violation";
import { Breakdown } from "./Violations/Breakdown/Breakdown";
import { Violations } from "./Violations/Violations";

const mcs150UpdateMapByLastDigit = new Map<number, DateTime.MonthAbbreviations>([
    [1, "Jan"],
    [2, "Feb"],
    [3, "Mar"],
    [4, "Apr"],
    [5, "May"],
    [6, "Jun"],
    [7, "Jul"],
    [8, "Aug"],
    [9, "Sep"],
    [0, "Oct"],
]);

// ========================================================================
export class MotorCarrier implements MotorCarrier.IsHazmatCarrier {
    static IFetcher = {
        HISTORICAL_CENSUS_DATE_FORMAT: "YYYY-MM-DD",
        createCensusHistoricalMapFromDates<T>({ dateRange }: { dateRange: { from: Date; to: Date } }): Map<string, T> {
            const map = new Map<string, T>();
            const from = DateTime.fromDate(dateRange.from);

            const next: DateTime = DateTime.fromDate(dateRange.to);
            while (from.isBefore(next)) {
                map.set(next.format(MotorCarrier.IFetcher.HISTORICAL_CENSUS_DATE_FORMAT), null as T);
                next.subtractMonths(1).last();
            }
            return map;
        },
    };

    // ========================================================================
    /**
     * Round the given number to the given decimal places (defaults to 2)
     */
    static round(num: number, decimalPlaces = 2): number {
        return round(num, decimalPlaces);
        // return Math.round(num * 10 ** decimalPlaces) / 10 ** decimalPlaces;
    }

    // ========================================================================
    /**
     * Truncates the given number.
     */
    static trunc(floatingNum: number, truncAmount = 0): number {
        const [int, fract] = floatingNum.toString().split(".");
        return globalThis.Number(`${int}.${(fract ?? new Array(truncAmount).fill(0).join("")).slice(0, truncAmount)}`);
    }

    // ========================================================================
    static calculateDateRange({ snapshotDate }: { snapshotDate: Date }): MotorCarrier.IDateRange {
        const to = DateTime.fromDate(snapshotDate).freeze();
        const from = DateTime.subtractYears(to, 2).addDays(1).freeze();

        return {
            from,
            to,
        };
    }

    // ========================================================================
    static calculateDates({ snapshotDate }: { snapshotDate: Date }): MotorCarrier.IDates {
        const to = DateTime.fromDate(snapshotDate).freeze();
        const from = DateTime.subtractYears(to, 2).addDays(1).freeze();

        return {
            from,
            eighteenMonthMark: DateTime.subtractYears(to, 1.5).addDays(1).freeze(),
            twelveMonthMark: DateTime.subtractYears(to, 1).addDays(1).freeze(),
            sixMonthMark: DateTime.subtractYears(to, 0.5).addDays(1).freeze(),
            to,
        };
    }

    // ========================================================================
    static mcs150UpdateMonth(dot: number): DateTime.MonthAbbreviations {
        const lastDotDigit = parseInt(dot.toString().slice(-1));
        return mcs150UpdateMapByLastDigit.get(lastDotDigit)!;
    }

    // ========================================================================
    static getFetcher(carrier: MotorCarrier): MotorCarrier.IFetcher {
        return carrier.#fetcher;
    }

    // ========================================================================
    static getBasicThresholds(isHazmatCarrier: boolean): Basic.IInterventionThresholds {
        if (isHazmatCarrier) {
            return {
                unsafeDriving: UnsafeDrivingBasic.thresholdDefinition.hazmat,
                hos: HoursOfServiceBasic.thresholdDefinition.hazmat,
                vehicleMx: VehicleMaintenanceBasic.thresholdDefinition.hazmat,
                controlledSubstances: ControlledSubstancesBasic.thresholdDefinition.hazmat,
                hazmat: HazmatBasic.thresholdDefinition.hazmat,
                driverFitness: DriverFitnessBasic.thresholdDefinition.hazmat,
                crash: CrashIndicatorBasic.thresholdDefinition.hazmat,
            };
        }

        return {
            unsafeDriving: UnsafeDrivingBasic.thresholdDefinition.general,
            hos: HoursOfServiceBasic.thresholdDefinition.general,
            vehicleMx: VehicleMaintenanceBasic.thresholdDefinition.general,
            controlledSubstances: ControlledSubstancesBasic.thresholdDefinition.general,
            hazmat: HazmatBasic.thresholdDefinition.general,
            driverFitness: DriverFitnessBasic.thresholdDefinition.general,
            crash: CrashIndicatorBasic.thresholdDefinition.general,
        };
    }

    // ========================================================================
    static getSnapshotDateFromRecordDate(carrier: MotorCarrier, recordDate: DateTime): DateTime {
        return carrier.#snapshotDateHistorical.get(recordDate.toISOString()) || carrier.#snapshotDate;
    }

    // ========================================================================
    static async fetchSmsScore({
        carrier,
        recordDate,
        safetyEventGroupName,
        measure,
    }: {
        carrier: MotorCarrier;
        recordDate: DateTime;
        safetyEventGroupName: MotorCarrier.SafetyEventGroupNames;
        measure: number;
    }): Promise<number> {
        return carrier.#safetyEventGroupDetails.fetch(recordDate, safetyEventGroupName, measure);
    }

    // ========================================================================
    static getCachedFetcherResults(carrier: MotorCarrier): MotorCarrier.CachedFetcherResults {
        return carrier.#fetcherResults;
    }

    // ========================================================================
    static Violation = Violation;
    static Violations = Violations;
    static Inspection = Inspection;
    static Inspections = Inspections;
    static Unit = Unit;
    static Units = Units;
    static Score = SmsResult;
    static IssScore = IssResult;
    static Basic = Basic;
    static Basics = {
        Hazmat: HazmatBasic,
        DriverFitness: DriverFitnessBasic,
        VehicleMaintenance: VehicleMaintenanceBasic,
        ControlledSubstances: ControlledSubstancesBasic,
        UnsafeDriving: UnsafeDrivingBasic,
        HoursOfService: HoursOfServiceBasic,
        CrashIndicator: CrashIndicatorBasic,
    };
    static SafetyEventGroupResults = SafetyEventGroup.Results;

    // ========================================================================
    /**
     * Creates a `Map` where the key is the inspection ID and the value is an
     *  array of raw violation objects.
     * May be used by the `MotorCarrier.IFetcher` fetchViolations method.
     */
    static rawViolationsReducer(map: Map<number, Violation.Raw[]>, viol: Violation.Raw): Map<number, Violation.Raw[]> {
        if (!map.has(viol.InspectionUniqueId)) {
            map.set(viol.InspectionUniqueId, []);
        }
        const arr = map.get(viol.InspectionUniqueId);
        arr?.push(viol);
        return map;
    }

    // ========================================================================
    /**
     * Creates a `Map` where the key is the date and the value is the
     *  raw census historical object.
     * May be used by the `MotorCarrier.IFetcher` fetchCensusHistorical method.
     */
    static rawCensusHistoricalMapper(
        dateRange: MotorCarrier.IDateRange,
        entities: CensusHistorical.Raw[]
    ): Map<string, MotorCarrier.Raw.CensusHistorical> {
        const map = MotorCarrier.IFetcher.createCensusHistoricalMapFromDates<CensusHistorical.Raw>({ dateRange });
        if (!entities?.length) {
            const emptyMap = new Map<string, MotorCarrier.Raw.CensusHistorical>();
            map.forEach((entity, key) => {
                emptyMap.set(key, {
                    RecordDate: null!,
                    Mcs150Date: null!,
                    Mcs150MileageTotal: null!,
                    Mcs150MileageYear: null!,
                    TotalPowerUnits: null!,
                    TotalDrivers: null!,
                    IsHazmatCarrier: null!,
                    IsPassengerCarrier: null!,
                    IsOutOfService: null!,
                    RecentMileage: null!,
                    RecentMileageYear: null!,
                });
            });
            return emptyMap;
        }
        let oldest: CensusHistorical.Raw | null = null;
        entities.forEach((entity) => {
            map.set(DateTime.fromDate(entity.RecordDate).format(MotorCarrier.IFetcher.HISTORICAL_CENSUS_DATE_FORMAT), entity);
            if (entity) {
                oldest = entity;
            }
        });

        let last: CensusHistorical.Raw | null = null;
        Array.from(map.entries())
            .reverse()
            .forEach(([dateStr, entity]) => {
                if (!entity) {
                    map.set(dateStr, (last || oldest)!);
                } else if (entity) {
                    last = entity;
                }
            });

        const parsedMap = new Map<string, MotorCarrier.Raw.CensusHistorical>();
        map.forEach((entity, key) => {
            parsedMap.set(key, {
                RecordDate: entity.RecordDate,
                Mcs150Date: entity.Mcs150Date,
                Mcs150MileageTotal: entity.Mcs150MileageTotal,
                Mcs150MileageYear: entity.Mcs150MileageYear,
                TotalPowerUnits: entity.TotalPowerUnits,
                TotalDrivers: entity.TotalDrivers,
                IsHazmatCarrier: entity.IsHazmatCarrier,
                IsPassengerCarrier: entity.IsPassengerCarrier,
                IsOutOfService: entity.IsOutOfService,
                RecentMileage: entity.RecentMileage,
                RecentMileageYear: entity.RecentMileageYear,
            });
        });

        return parsedMap;
    }

    // ========================================================================
    static getRawViolationsMap(carrier: MotorCarrier): Map<number, Violation.Raw[]> {
        return carrier.#rawViolationsMap;
    }

    // ========================================================================
    /**
     * Async constructor
     */
    private static async _new({
        dot,
        fetcher,
        config,
        snapshotDate,
        smsScoreDates,
        includeUnitDetails,
    }: MotorCarrier.Options): Promise<MotorCarrier> {
        const carrier = new MotorCarrier(dot, snapshotDate, smsScoreDates, fetcher, config);

        const [
            rawCensus,
            rawCensusHistorical,
            rawInspections,
            rawCrashes,
            rawAuthHistory,
            rawEnforcementCases,
            historicalSnapshotDates,
            nationalAverageOutOfServiceRates,
            rawInsuranceHistory,
        ] = await Promise.all([
            fetcher.fetchCensus(carrier.dot),
            fetcher.fetchCensusHistorical(carrier.dot, {
                dateRange: {
                    from: DateTime.subtractMonths(carrier.#dateRange.from, 18),
                    to: carrier.#dateRange.to,
                },
            }),
            fetcher.fetchInspections(carrier.dot, { dateRange: carrier.#dateRange }),
            fetcher.fetchCrashes(carrier.dot, { dateRange: carrier.#dateRange }),
            fetcher.fetchAuthorityHistory(carrier.dot),
            fetcher.fetchEnforcementCases(carrier.dot),
            fetcher.fetchSnapshotDateHistorical(),
            fetcher.fetchNationalAverageOutOfServiceRates(smsScoreDates),
            fetcher.fetchInsuranceHistory(carrier.dot),
        ]);
        if (!rawCensus) {
            throw new Error("Carrier not found!");
        }
        carrier.#fetcherResults.rawCensus = rawCensus;
        carrier.#fetcherResults.rawInspections = rawInspections;
        carrier.#fetcherResults.rawCrashes = rawCrashes;
        carrier.#fetcherResults.rawEnforcementCases = rawEnforcementCases;
        carrier.#authorityHistory = rawAuthHistory.map((authHist) => new AuthorityHistory(carrier, authHist));
        carrier.#insuranceHistory = new InsuranceHistory(carrier, rawInsuranceHistory);
        carrier.#snapshotDateHistorical = new Map(
            historicalSnapshotDates.map((obj) => {
                return [new Date(obj.RecordDate).toISOString(), new DateTime(obj.SnapshotDate).freeze()];
            })
        );

        carrier.#census = new Census(carrier, rawCensus);
        carrier.#censusHistorical = new Map<string, CensusHistorical>();
        Array.from(rawCensusHistorical.entries()).forEach(([date, raw]) => {
            carrier.#censusHistorical.set(date, new CensusHistorical(carrier, raw));
        });
        carrier.#mcs150History = new Mcs150History(carrier, Array.from(rawCensusHistorical.values()));

        const inspectionIds = rawInspections.map((insp) => insp.UniqueId);
        const vinNumbers = new Set<string>();
        rawInspections.forEach((inspection) => {
            vinNumbers.add(inspection.UnitVin);
            if (inspection.Unit2Vin) {
                vinNumbers.add(inspection.Unit2Vin);
            }
        });

        const [rawUnits, rawViolationsMap] = await Promise.all([
            includeUnitDetails === false ? [] : fetcher.fetchUnits(Array.from(vinNumbers)),
            fetcher
                .fetchViolations({
                    inspectionIds,
                    dotNumber: carrier.dot,
                })
                .catch((error) => {
                    throw new Error(`Error fetching violations: ${error?.message}`);
                }),
        ]);
        carrier.#crashes = Crashes.new(carrier, rawCrashes);
        carrier.#fetcherResults.rawUnits = rawUnits;
        carrier.#fetcherResults.rawViolationsMap = rawViolationsMap;
        carrier.#inspections = Inspections.new(carrier);
        carrier.#enforcementCases = EnforcementCases.new(carrier, rawEnforcementCases);
        carrier.#violations = Violations.new(carrier, carrier.#inspections);
        carrier.#units = Units.new(carrier);
        const rawSmsData = await fetcher.fetchSmsData(carrier.dot, smsScoreDates);
        carrier.#smsResults = await SmsResults.new(carrier, smsScoreDates, rawSmsData);
        carrier.#issResults = await IssResults.new(carrier, smsScoreDates);
        carrier.#carrierOutOfServiceRates = await CarrierOutOfServiceRates.new(carrier, smsScoreDates);
        carrier.#nationalAverageOutOfServiceRates = await NationalAverageOutOfServiceRates.new(carrier, nationalAverageOutOfServiceRates);

        return carrier;
    }

    // ========================================================================
    /**
     * Async constructor
     */
    static async new({
        dot,
        includeUnitDetails,
        fetcher,
        config,
        snapshotDate,
        smsScoreDates,
    }: MotorCarrier.Options): Promise<MotorCarrier> {
        const carrier = new MotorCarrier(dot, snapshotDate, smsScoreDates, fetcher, config);

        const results = await fetcher.fetchBulk({
            dot,
            snapshotDate,
            smsScoreDates,
            includeUnitDetails,
        });

        if (!results?.census) {
            throw new Error("Carrier not found!");
        }

        const {
            census: rawCensus,
            censusHistorical: rawCensusHistorical,
            inspections: rawInspections,
            crashes: rawCrashes,
            authorityHistory: rawAuthHistory,
            insuranceHistory: rawInsuranceHistory,
            units: rawUnits,
            violations: rawViolations,
            smsData: rawSmsData,
            enforcementCases: rawEnforcementCases,
            snapshotDateHistorical,
            nationalAverageOutOfServiceRates,
        } = results;
        carrier.#fetcherResults.rawCensus = rawCensus;
        carrier.#fetcherResults.rawInspections = rawInspections;
        carrier.#fetcherResults.rawCrashes = rawCrashes;
        carrier.#fetcherResults.rawEnforcementCases = rawEnforcementCases;
        carrier.#authorityHistory = rawAuthHistory.map((authHist) => new AuthorityHistory(carrier, authHist));
        carrier.#insuranceHistory = new InsuranceHistory(carrier, rawInsuranceHistory);
        carrier.#snapshotDateHistorical = new Map(
            snapshotDateHistorical.map((obj) => {
                return [new Date(obj.RecordDate).toISOString(), new DateTime(obj.SnapshotDate).freeze()];
            })
        );

        carrier.#census = new Census(carrier, rawCensus);
        carrier.#censusHistorical = new Map<string, CensusHistorical>();
        carrier.#mcs150History = new Mcs150History(carrier, Array.from(rawCensusHistorical.values()));

        const { from, to } = MotorCarrier.calculateDateRange({ snapshotDate });
        const dateRange = {
            from: DateTime.fromDate(from).last(),
            to: DateTime.fromDate(to).last(),
        };
        const censusHistoricalMap = MotorCarrier.rawCensusHistoricalMapper(dateRange, rawCensusHistorical);
        Array.from(censusHistoricalMap.entries()).forEach(([date, raw]) => {
            carrier.#censusHistorical.set(date, new CensusHistorical(carrier, raw));
        });

        carrier.#crashes = Crashes.new(carrier, rawCrashes);
        carrier.#fetcherResults.rawUnits = rawUnits;
        carrier.#fetcherResults.rawViolationsMap = rawViolations.reduce(
            MotorCarrier.rawViolationsReducer,
            new Map<number, MotorCarrier.Raw.Violation[]>()
        );
        carrier.#inspections = Inspections.new(carrier);
        carrier.#enforcementCases = EnforcementCases.new(carrier, rawEnforcementCases);
        carrier.#violations = Violations.new(carrier, carrier.#inspections);
        carrier.#units = Units.new(carrier);
        carrier.#smsResults = await SmsResults.new(carrier, smsScoreDates, rawSmsData);
        carrier.#issResults = await IssResults.new(carrier, smsScoreDates);
        carrier.#carrierOutOfServiceRates = await CarrierOutOfServiceRates.new(carrier, smsScoreDates);
        carrier.#nationalAverageOutOfServiceRates = await NationalAverageOutOfServiceRates.new(carrier, nationalAverageOutOfServiceRates);

        return carrier;
    }

    // ========================================================================
    static getRawFmcsaUnits(carrier: MotorCarrier): Map<string, Unit.Raw.FMCSA> {
        const rawFmcsaUnits = new Map<string, Unit.Raw.FMCSA>();
        if (!carrier.#fetcherResults.rawInspections) {
            throw new Error("Raw inspections not set");
        }
        carrier.#fetcherResults.rawInspections.forEach((insp) => {
            rawFmcsaUnits.set(insp.UnitVin, {
                UnitVin: insp.UnitVin,
                UnitDecalNumber: insp.UnitDecalNumber,
                UnitLicense: insp.UnitLicense,
                UnitMake: insp.UnitMake,
                UnitType: insp.UnitType,
            });

            if (insp.Unit2Vin) {
                rawFmcsaUnits.set(insp.Unit2Vin, {
                    UnitVin: insp.Unit2Vin,
                    UnitDecalNumber: insp.Unit2DecalNumber,
                    UnitLicense: insp.Unit2License!,
                    UnitMake: insp.Unit2Make!,
                    UnitType: insp.Unit2Type,
                });
            }
        });
        return rawFmcsaUnits;
    }

    // ========================================================================
    /**
     * Get the raw data representation of the MotorCarrier. Includes a 12 month history
     *  of BASIC scores.
     */
    static async raw(options: MotorCarrier.Options & { basicScoreHistoryLength?: number }): Promise<MotorCarrier.Raw> {
        const carrier = await MotorCarrier.new(options);
        return carrier.raw();
    }

    // ========================================================================
    #safetyEventGroupDetails: SafetyEventGroup.Results;
    #dot: number;
    #census!: Census;
    #authorityHistory!: AuthorityHistory[];
    #insuranceHistory!: InsuranceHistory;
    #mcs150History!: Mcs150History;
    #censusHistorical!: Map<string /** date */, CensusHistorical>;
    #snapshotDateHistorical!: Map<string, DateTime>;
    #inspections!: Inspections;
    #enforcementCases!: EnforcementCases;
    #smsResults!: SmsResults;
    #issResults!: IssResults;
    #carrierOutOfServiceRates!: CarrierOutOfServiceRates;
    #nationalAverageOutOfServiceRates!: NationalAverageOutOfServiceRates;
    #crashes!: Crashes;
    #fetcher: MotorCarrier.IFetcher;
    #units!: Units;
    #config: Configuration;
    #violations!: Violations;
    #rawViolationsMap!: Map<number, Violation.Raw[]>;
    #fetcherResults: MotorCarrier.CachedFetcherResults = {
        rawCensus: null,
        rawInspections: null,
        rawViolationsMap: null,
        rawUnits: null,
        rawCrashes: null,
        rawEnforcementCases: null,
    };
    #smsScoreDates: DateTime[];
    #snapshotDate: DateTime;
    // TODO combine these
    #dateRange: MotorCarrier.IDateRange;
    #dates: MotorCarrier.IDates;
    private constructor(
        dot: number,
        snapshotDate: DateTime,
        smsScoreDates: DateTime[],
        fetcher: MotorCarrier.IFetcher,
        configOptions?: Configuration.Options
    ) {
        this.#dot = dot;
        this.#safetyEventGroupDetails = new SafetyEventGroup.Results(fetcher);
        this.#smsScoreDates = DateTime.sort(smsScoreDates).map((date) => date.freeze());
        this.#snapshotDate = snapshotDate || this.#smsScoreDates[this.#smsScoreDates.length - 1];
        this.#snapshotDate.freeze();
        this.#dates = MotorCarrier.calculateDates({ snapshotDate: this.#snapshotDate });
        this.#dateRange = {
            from: this.#dates.from,
            to: this.#dates.to,
        };
        this.#config = new Configuration(this.#dateRange, configOptions);
        this.#fetcher = fetcher;
    }

    // ========================================================================
    #currentHistorical?: CensusHistorical;
    #getCurrentHistorical(): CensusHistorical {
        if (this.#currentHistorical) return this.#currentHistorical;
        const sorted = Array.from(this.#censusHistorical.entries()).sort(([a], [b]) => {
            return new Date(b).valueOf() - new Date(a).valueOf();
        });
        this.#currentHistorical = sorted[0][1];
        return this.#currentHistorical;
    }

    // ========================================================================
    get dateRange(): MotorCarrier.IDateRange {
        return this.#dateRange;
    }

    // ========================================================================
    get dates(): MotorCarrier.IDates {
        return this.#dates;
    }

    // ========================================================================
    /**
     * @deprecated use `dateRange.to`
     */
    get targetDate(): DateTime {
        return this.dateRange.to;
    }

    // ========================================================================
    get config(): Configuration {
        return this.#config;
    }

    // ========================================================================
    get inspections(): Inspections {
        return this.#inspections;
    }

    // ========================================================================
    #expiredInspections: Inspections | null = null;
    get expiredInspections(): Inspections {
        if (this.#expiredInspections) return this.#expiredInspections;
        const expiredInspections = this.#fetcherResults.rawInspections?.filter((i) =>
            new DateTime(i.InspectionDate).isBefore(this.#dateRange.from)
        );
        this.#expiredInspections = Inspections.of(this, expiredInspections);
        return this.#expiredInspections;
    }

    // ========================================================================
    #expiredViolations: Violations | null = null;
    get expiredViolations(): Violations {
        if (this.#expiredViolations) return this.#expiredViolations;
        const rawViolations = Array.from(this.#fetcherResults.rawViolationsMap?.values() || []).flat();
        const expiredViolations = rawViolations?.filter((v) => new DateTime(v.InspectionDate).isBefore(this.#dateRange.from));
        this.#expiredViolations = Violations.of(this, expiredViolations);
        return this.#expiredViolations;
    }

    // ========================================================================
    get enforcementCases(): EnforcementCases {
        return this.#enforcementCases;
    }

    // ========================================================================
    get violations(): Violations {
        return this.#violations;
    }

    // ========================================================================
    get crashes(): Crashes {
        return this.#crashes;
    }

    // ========================================================================
    get units(): Units {
        return this.#units;
    }

    // ========================================================================
    get smsResults(): SmsResults {
        return this.#smsResults;
    }

    // ========================================================================
    get issResults(): IssResults {
        return this.#issResults;
    }

    // ========================================================================
    get outOfServiceRates(): CarrierOutOfServiceRates {
        return this.#carrierOutOfServiceRates;
    }

    // ========================================================================
    get nationalAverageOutOfServiceRates(): NationalAverageOutOfServiceRates {
        return this.#nationalAverageOutOfServiceRates;
    }

    // ========================================================================
    get isHazmat(): boolean {
        return this.#getCurrentHistorical().get("IsHazmatCarrier");
    }

    // ========================================================================
    get dot(): number {
        return this.#dot;
    }

    // ========================================================================
    get name(): string {
        return this.#census.get("Name") || String(this.#dot);
    }

    // ========================================================================
    get accidentRate(): number | null {
        const vmt = this.#census.get("VehicleMilesTraveled");
        if (!vmt) return null;
        return (this.#crashes.total / vmt) * 1000000;
    }

    // ========================================================================
    get accidentRating(): FMCSA.AccidentRating {
        const rate = this.accidentRate;
        if (!rate) return FMCSA.AccidentRating.NONE;
        if (rate <= 1.5) return FMCSA.AccidentRating.SATISFACTORY;
        if (rate > 1.5) return FMCSA.AccidentRating.UNSATISFACTORY;
        return FMCSA.AccidentRating.NONE;
    }

    // ========================================================================
    get details(): Census.Raw {
        return this.#census.json();
    }

    // ========================================================================
    get isOutOfService(): boolean {
        return this.#census.get("IsOutOfService") ?? false;
    }

    // ========================================================================
    @memoize()
    getTrends(options: Omit<Trends.Options, "carrier">): Trends {
        return new Trends({
            ...options,
            carrier: this,
        });
    }

    // ========================================================================
    #getValidMcs150UpdateDateWithinTwoYears(renewalDate: DateTime, nextPossibleUpdateDate: DateTime): DateTime {
        if (nextPossibleUpdateDate.isLeapYear() && nextPossibleUpdateDate.getMonthAbbreviation() === "Feb") {
            nextPossibleUpdateDate.subtractDays(1);
        }

        if (nextPossibleUpdateDate.isBeforeOrEqual(renewalDate)) {
            return nextPossibleUpdateDate;
        }
        return this.#getValidMcs150UpdateDateWithinTwoYears(renewalDate, nextPossibleUpdateDate.subtractYears(1).clearUTCTime().last());
    }

    // ========================================================================
    getLatestNationalAverageOutOfServiceRate(): NationalAverageOutOfServiceRate {
        return this.#nationalAverageOutOfServiceRates.latest;
    }

    // ========================================================================
    getMcs150UpdateDueDate(): DateTime {
        const currentMcs150Date = this.details.Mcs150Date ? new Date(this.details.Mcs150Date) : null;
        if (!currentMcs150Date) return DateTime.today;
        const twoYearRenewalMark = DateTime.fromDate(currentMcs150Date).addYears(2).clearUTCTime();
        const monthAbbr = MotorCarrier.mcs150UpdateMonth(this.dot);
        const nextUpdate = DateTime.fromString(`01-${monthAbbr}-${currentMcs150Date.getFullYear() + 2}`, "DD-Mmm-YYYY")
            .last()
            .clearUTCTime();

        return this.#getValidMcs150UpdateDateWithinTwoYears(twoYearRenewalMark, nextUpdate).last();
    }

    // ========================================================================
    getMileageHistory(): [date: string, mileage: number | null][] {
        const history: [date: string, mileage: number | null][] = [];
        Array.from(this.#censusHistorical.entries()).forEach(([date, censusHistorical]) => {
            const recordDate = DateTime.fromDate(censusHistorical.get("RecordDate")).format(
                MotorCarrier.IFetcher.HISTORICAL_CENSUS_DATE_FORMAT
            );
            if (date === recordDate) {
                history.push([date, censusHistorical.mileage]);
            } else {
                history.push([date, null]);
            }
        });
        return history;
    }

    // ========================================================================
    getPowerUnitHistory(): [date: string, totalPowerUnits: number | null][] {
        const history: [date: string, totalPowerUnits: number | null][] = [];
        Array.from(this.#censusHistorical.entries()).forEach(([date, censusHistorical]) => {
            const recordDate = DateTime.fromDate(censusHistorical.get("RecordDate")).format(
                MotorCarrier.IFetcher.HISTORICAL_CENSUS_DATE_FORMAT
            );
            if (date === recordDate) {
                history.push([date, censusHistorical.totalPowerUnits]);
            } else {
                history.push([date, null]);
            }
        });
        return history;
    }

    // ========================================================================
    getHazmatHistory(): [date: string, isHazmat: boolean | null][] {
        const history: [date: string, isHazmat: boolean | null][] = [];
        Array.from(this.#censusHistorical.entries()).forEach(([date, censusHistorical]) => {
            const recordDate = DateTime.fromDate(censusHistorical.get("RecordDate")).format(
                MotorCarrier.IFetcher.HISTORICAL_CENSUS_DATE_FORMAT
            );
            if (date === recordDate) {
                history.push([date, censusHistorical.get("IsHazmatCarrier")]);
            } else {
                history.push([date, null]);
            }
        });
        return history;
    }

    // ========================================================================
    #getHistoricalPowerUnits(date: DateTime): number {
        const dateStr = date.format(MotorCarrier.IFetcher.HISTORICAL_CENSUS_DATE_FORMAT);
        const historicalEntity = this.#censusHistorical.get(dateStr);
        if (!historicalEntity) return 0;
        return historicalEntity.totalPowerUnits;
    }

    // ========================================================================
    @memoize()
    get currentPowerUnits(): number {
        return this.#getCurrentHistorical().totalPowerUnits || this.#census.totalPowerUnits;
    }

    // ========================================================================
    #averagePowerUnits?: number;
    #calculateAveragePowerUnits(): number {
        const current = this.currentPowerUnits;
        const sixMonths = this.#getHistoricalPowerUnits(DateTime.fromDate(this.#dates.sixMonthMark).last());
        const eighteenMonths = this.#getHistoricalPowerUnits(DateTime.fromDate(this.#dates.eighteenMonthMark).last());
        const average = (current + sixMonths + eighteenMonths) / 3;
        this.#averagePowerUnits = average > 0 ? MotorCarrier.round(average, 2) : 1;
        return this.#averagePowerUnits;
    }
    /**
     * Average power units as defined in SMS Methodology.
     * Calculated by averaging the current, 6 month, and 18 month power units.
     *
     * If the average is 0, then 1 is returned.
     *
     * If the history is not on file, takes the most recent power units count.
     */
    get averagePowerUnits(): number {
        return this.#averagePowerUnits ?? this.#calculateAveragePowerUnits();
    }

    // ========================================================================
    /**
     * The average miles traveled per unit as defined in SMS Methodology.
     *
     * Calculated by dividing the MCS 150 Mileage Total by the carrier's number
     * of average power units.
     */
    get averageMilesTraveledPerUnit(): number {
        const vmt = this.#census.get("VehicleMilesTraveled");
        if (!vmt) return 1;
        return MotorCarrier.trunc(vmt / this.averagePowerUnits);
    }

    // ========================================================================
    /**
     * There are two segments into which each motor carrier can be categorized:
     *
     * `COMBINATION` = Combination trucks/motorcoaches make up 70% or more of the total power units in the motor carrier’s fleet
     *
     * `STRAIGHT` = Straight trucks/other vehicles constitute more than 30% of the total power units in the motor carrier’s fleet
     */
    get segment(): FMCSA.Segment | null {
        return this.#census.get("Segment");
    }

    // ========================================================================
    get insuranceHistory(): InsuranceHistory {
        return this.#insuranceHistory;
    }

    // ========================================================================
    get mcs150History(): Mcs150History {
        return this.#mcs150History;
    }

    // ========================================================================
    /**
     * Returns historical census data for the given record date.
     *
     * @param recordDate record date of the historical census data
     */
    getHistoricalCensusData(recordDate: DateTime): CensusHistorical {
        return this.#censusHistorical.get(recordDate.toISOString()) || CensusHistorical.empty(this);
    }

    // ========================================================================
    /**
     * Creates a new instance of a `MotorCarrier` with a date range filter on the `Inspections`,
     * `Violations`, `Units`, and `Crashes`.
     *
     * @param options.dateRange the date range desired
     * @param options.keepAllSmsResults `true` if ALL `SmsResult`s should be kept in the new `MotorCarrier` instance.
     *      `false` if only a single score for the ending date range should be kept
     */
    filterByDateRange({ dateRange, keepAllSmsResults }: { dateRange: MotorCarrier.IDateRange; keepAllSmsResults: boolean }): MotorCarrier {
        const mc = new MotorCarrier(this.#dot, this.#snapshotDate, this.#smsScoreDates, this.#fetcher, this.#config.get());

        const inspections = this.inspections.filterByDateRange(dateRange);
        mc.#inspections = inspections;
        mc.#expiredInspections = this.#expiredInspections;
        mc.#violations = inspections.violations;
        mc.#units = inspections.units;
        mc.#dateRange = dateRange;
        mc.#fetcherResults = this.#fetcherResults;
        mc.#census = this.#census;
        mc.#enforcementCases = this.enforcementCases.filterByDateRange(dateRange);
        mc.#authorityHistory = this.#authorityHistory.filter((ah) => {
            const originalServedDate = ah.get("OriginalServedDate");
            if (!originalServedDate) return ah;
            const originalServedDateTime = new DateTime(originalServedDate);
            if (originalServedDateTime.isBefore(dateRange.to)) return ah;
            return null;
        });
        mc.#insuranceHistory = this.#insuranceHistory;
        mc.#mcs150History = this.#mcs150History;
        mc.#censusHistorical = this.#censusHistorical;
        mc.#crashes = this.#crashes.filterByDateRange(dateRange);
        mc.#nationalAverageOutOfServiceRates = this.#nationalAverageOutOfServiceRates;
        if (keepAllSmsResults) {
            mc.#smsResults = this.#smsResults;
            mc.#issResults = this.#issResults;
            mc.#carrierOutOfServiceRates = this.#carrierOutOfServiceRates;
        } else {
            mc.#smsResults = SmsResults.from(this.#smsResults, DateTime.last(dateRange.to));
            mc.#issResults = IssResults.from(this.#issResults, DateTime.last(dateRange.to));
            mc.#carrierOutOfServiceRates = CarrierOutOfServiceRates.from(this.#carrierOutOfServiceRates, DateTime.last(dateRange.to));
        }

        return mc;
    }

    // ========================================================================
    /**
     * Filters trailer violations by BASIC, sorts by percentage of score, and compiles an array of units.
     *
     * @param max upper limit of how many units to return
     * @returns {MotorCarrier.PrimaryScoreContributor[]} an array of units that are the top contributors to the BASIC score
     */
    getPrimaryTrailerScoreContributors(max = 5): MotorCarrier.PrimaryScoreContributor[] {
        const violationsByType = this.violations.filterByType();
        const unitsWithDriverViolation = violationsByType.trailer.units.sortByPercentOfScore().take(max);
        const primaryDriverScoreContributors = Array.from(unitsWithDriverViolation).map((unit) => {
            const { unsafeDriving, hoursOfService, controlledSubstances, driverFitness } = unit.violations.filterByBasics();

            return {
                ...unit.json(),
                basicPointTotals: {
                    unsafeDriving: unsafeDriving.getTotalWeight(),
                    hoursOfService: hoursOfService.getTotalWeight(),
                    controlledSubstances: controlledSubstances.getTotalWeight(),
                    driverFitness: driverFitness.getTotalWeight(),
                    total: unit.violations.getTotalWeight(),
                },
            };
        });
        return primaryDriverScoreContributors;
    }

    // ========================================================================
    /**
     * Filters tractor violations by BASIC, sorts by percentage of score, and compiles an array of units.
     *
     * @param max upper limit of how many units to return
     * @returns {MotorCarrier.PrimaryScoreContributor[]} an array of units that are the top contributors to the BASIC score
     */
    getPrimaryTractorScoreContributors(max = 5): MotorCarrier.PrimaryScoreContributor[] {
        const violationsByType = this.violations.filterByType();
        const unitsWithDriverViolation = violationsByType.tractor.units.sortByPercentOfScore().take(max);
        const primaryDriverScoreContributors = Array.from(unitsWithDriverViolation).map((unit) => {
            const { unsafeDriving, hoursOfService, controlledSubstances, driverFitness } = unit.violations.filterByBasics();

            return {
                ...unit.json(),
                basicPointTotals: {
                    unsafeDriving: unsafeDriving.getTotalWeight(),
                    hoursOfService: hoursOfService.getTotalWeight(),
                    controlledSubstances: controlledSubstances.getTotalWeight(),
                    driverFitness: driverFitness.getTotalWeight(),
                    total: unit.violations.getTotalWeight(),
                },
            };
        });
        return primaryDriverScoreContributors;
    }

    // ========================================================================
    /**
     * Filters driver violations by BASIC, sorts by percentage of score, and compiles an array of units.
     *
     * @param max upper limit of how many units to return
     * @returns {MotorCarrier.PrimaryScoreContributor[]} an array of units that are the top contributors to the BASIC score
     */
    getPrimaryDriverScoreContributors(max = 5): MotorCarrier.PrimaryScoreContributor[] {
        const violationsByType = this.violations.filterByType();
        const unitsWithDriverViolation = violationsByType.driver
            .filterByBasics([
                FMCSA.BasicName.UNSAFE_DRIVING,
                FMCSA.BasicName.HOS,
                FMCSA.BasicName.CONTROLLED_SUBSTANCES,
                FMCSA.BasicName.DRIVER_FITNESS,
            ])
            .aggregate.units.sortByPercentOfScore()
            .take(max);
        const primaryDriverScoreContributors = Array.from(unitsWithDriverViolation).map((unit) => {
            const { unsafeDriving, hoursOfService, controlledSubstances, driverFitness } = unit.violations.filterByBasics();

            return {
                ...unit.json(),
                basicPointTotals: {
                    unsafeDriving: unsafeDriving.getTotalWeight(),
                    hoursOfService: hoursOfService.getTotalWeight(),
                    controlledSubstances: controlledSubstances.getTotalWeight(),
                    driverFitness: driverFitness.getTotalWeight(),
                    total: unit.violations.getTotalWeight(),
                },
            };
        });
        return primaryDriverScoreContributors;
    }

    // ========================================================================
    /**
     * Sorts units by percentage of score, limit units taken (default 10), filters units with minimum score, and compiles an array of units.
     *
     * @param max upper limit of how many units to return
     * @returns {MotorCarrier.PrimaryScoreContributor[]} an array of units that are the top contributors to the BASIC score
     */
    getPrimaryScoreContributors(max = 10): MotorCarrier.PrimaryScoreContributor[] {
        return this.units
            .sortByPercentOfScore()
            .take(max)
            .array()
            .filter((unit) => {
                if (unit.percentOfTotalScore > 0) return unit;
                return null;
            })
            .map((unit) => {
                const unitViolations = unit.violations.filterByBasics();
                return {
                    ...unit.json(),
                    basicPointTotals: {
                        unsafeDriving: unitViolations.unsafeDriving.getTotalWeight(),
                        hoursOfService: unitViolations.hoursOfService.getTotalWeight(),
                        controlledSubstances: unitViolations.controlledSubstances.getTotalWeight(),
                        driverFitness: unitViolations.driverFitness.getTotalWeight(),
                        total: unit.violations.getTotalWeight(),
                    },
                };
            });
    }

    // ========================================================================
    /**
     * Get the raw data for the `MotorCarrier`.
     */
    raw(): MotorCarrier.Raw {
        return {
            crashes: this.#crashes.raw(),
            units: this.#units.raw(),
            violations: this.#violations.raw(),
            inspections: this.#inspections.raw(),
            authorityHistory: this.#authorityHistory.map((ah) => ah.raw()),
            insuranceHistory: this.#insuranceHistory.raw(),
            mcs150History: this.#mcs150History.raw(),
            census: this.#census.raw(),
            censusHistorical: Array.from(this.#censusHistorical.values()).map((ch) => ch.raw()),
            smsResults: this.#smsResults.json(),
            issResults: this.#issResults.json(),
            outOfServiceRates: this.#carrierOutOfServiceRates.json(),
            nationalAverageOutOfServiceRate: this.#nationalAverageOutOfServiceRates.json(),
            safetyEventGroupDetailResults: this.#safetyEventGroupDetails.json(),
            latestSmsResultsDate: this.#smsResults.latest.recordDate,
            snapshotDate: this.#snapshotDate,
            enforcementCases: this.#enforcementCases.raw(),
            snapshotDateHistorical: Array.from(this.#snapshotDateHistorical.entries()).map(([recordDate, snapshotDate]) => {
                return {
                    RecordDate: new Date(recordDate),
                    SnapshotDate: snapshotDate,
                };
            }),
        };
    }
}

// ========================================================================
export declare namespace MotorCarrier {
    export type SafetyEventGroupNames = SafetyEventGroup.GroupNames;
    export interface SafetyEventGroup<SubGroup> extends SafetyEventGroup.Group<SubGroup> {}
    export namespace Basics {
        export interface Hazmat extends HazmatBasic {}
        export interface DriverFitness extends DriverFitnessBasic {}
        export interface VehicleMaintenance extends VehicleMaintenanceBasic {}
        export interface ControlledSubstances extends ControlledSubstancesBasic {}
        export interface UnsafeDriving extends UnsafeDrivingBasic {}
        export interface HoursOfService extends HoursOfServiceBasic {}
    }
    export interface ICensus extends Census {}
    export interface IViolation extends Violation {}
    export interface IViolations extends Violations {}
    export namespace Violations {
        export interface IFilterByBasicReturn {
            hazmat: Violations;
            controlledSubstances: Violations;
            driverFitness: Violations;
            vehicleMaintenance: Violations;
            hoursOfService: Violations;
            unsafeDriving: Violations;
            [FMCSA.BasicName.HAZMAT]: Violations;
            [FMCSA.BasicName.CONTROLLED_SUBSTANCES]: Violations;
            [FMCSA.BasicName.DRIVER_FITNESS]: Violations;
            [FMCSA.BasicName.VEHICLE_MAINTENANCE]: Violations;
            [FMCSA.BasicName.HOS]: Violations;
            [FMCSA.BasicName.UNSAFE_DRIVING]: Violations;
            aggregate: Violations;
        }

        export interface IByType {
            driver: Violations;
            tractor: Violations;
            trailer: Violations;
            unknown: Violations;
        }

        export interface IBreakdown extends Breakdown {}
        export namespace IBreakdown {
            export interface IGroup<GroupNames extends string, SubGroupNames extends string>
                extends Breakdown.Group<GroupNames, SubGroupNames> {}
        }

        export type GroupNames = Breakdown.GroupNames;
        export type SubGroupNames = Breakdown.SubGroupNames;
    }
    export interface IInspection extends Inspection {}
    export interface IInspections extends Inspections {}
    export interface IUnit extends Unit {}
    export interface IUnits extends Units {}
    export interface ISmsResult extends SmsResult {}
    export interface IIssResult extends IssResult {}
    export interface IBasic extends Basic<unknown> {}
    export interface ISafetyEventGroup extends SafetyEventGroup.Group<unknown> {}
    export interface ICrash extends Crash {}
    export interface ICrashes extends Crashes {}
    export interface ISafetyEventGroupResults extends SafetyEventGroup.Results {}
    export interface ITrends extends Trends {}

    export interface Raw {
        smsResults: JSON.SmsResult[];
        issResults: JSON.IssResult[];
        outOfServiceRates: JSON.OutOfServiceRate[];
        nationalAverageOutOfServiceRate: NationalAverageOutOfServiceRate.JSON[];
        units: MotorCarrier.Raw.Unit[];
        violations: MotorCarrier.Raw.Violation[];
        inspections: MotorCarrier.Raw.Inspection[];
        census: MotorCarrier.Raw.Census;
        authorityHistory: AuthorityHistory.Raw[];
        insuranceHistory: InsuranceHistoryEntry.Raw[];
        mcs150History: Mcs150HistoryEntry.Raw[];
        censusHistorical: MotorCarrier.Raw.CensusHistorical[];
        crashes: MotorCarrier.Raw.Crash[];
        safetyEventGroupDetailResults: SafetyEventGroup.Results.JSON[];
        latestSmsResultsDate: DateTime;
        snapshotDate: DateTime;
        enforcementCases: MotorCarrier.Raw.EnforcementCase[];
        snapshotDateHistorical: { RecordDate: Date; SnapshotDate: Date }[];
    }
    export namespace Raw {
        export interface AuthorityHistory extends AuthorityHistory.Raw {}
        export interface InsuranceHistory extends InsuranceHistoryEntry.Raw {}
        export interface Mcs150History extends Mcs150HistoryEntry.Raw {}
        export interface Violation extends Violation.Raw {}
        export interface Inspection extends Inspection.Raw {}
        export interface Census extends Census.Raw {}
        export interface CensusHistorical extends CensusHistorical.Raw {}
        export interface Unit extends Unit.Raw {}
        export interface Crash extends Crash.Raw {}
        export interface SmsData extends SmsResults.Raw {}
        export interface EnforcementCase extends EnforcementCase.Raw {}
        export interface NationalAverageOutOfServiceRate extends NationalAverageOutOfServiceRate.Raw {}
    }

    export namespace JSON {
        export interface SmsResult extends SmsResult.JSON {}
        export interface IssResult extends IssResult.JSON {}
        export interface OutOfServiceRate extends CarrierOutOfServiceRate.JSON {}
        export interface SafetyEventGroupDetailResults extends SafetyEventGroup.Results.JSON {}
    }
    export interface IDates {
        from: DateTime;
        to: DateTime;
        eighteenMonthMark: DateTime;
        twelveMonthMark: DateTime;
        sixMonthMark: DateTime;
    }

    export interface IDateRange extends Pick<IDates, "from" | "to"> {}
    export interface IsHazmatCarrier {
        readonly isHazmat: boolean;
    }
    export interface CachedFetcherResults {
        rawCensus: Census.Raw | null;
        rawInspections: Inspection.Raw[] | null;
        rawViolationsMap: Map<number, Violation.Raw[]> | null;
        rawUnits: Unit.Raw[] | null;
        rawCrashes: Crash.Raw[] | null;
        rawEnforcementCases: EnforcementCase.Raw[] | null;
    }

    export interface Options {
        dot: number;
        fetcher: MotorCarrier.IFetcher;
        smsScoreDates: DateTime[];
        snapshotDate: DateTime;
        config?: Configuration.Options;
        includeUnitDetails?: boolean;
    }

    export interface IFetcher {
        fetchSmsData(dot: number, dates: Date[]): Promise<Raw.SmsData[]>;
        fetchSmsScore(options: IFetcher.fetchSmsScore.Options): Promise<number>;
        fetchCensus(dot: number): Promise<Census.Raw | undefined>;
        fetchCensusHistorical(
            dot: number,
            options: { dateRange: MotorCarrier.IDateRange }
        ): Promise<Map<string /* date */, CensusHistorical.Raw>>;
        fetchInspections(dot: number, dateRange: { dateRange: { from: Date; to: Date } }): Promise<Inspection.Raw[]>;
        fetchViolations(options: IFetcher.fetchViolations.Options): Promise<Map<number, Violation.Raw[]>>;
        fetchEnforcementCases(dot: number): Promise<EnforcementCase.Raw[]>;
        fetchCrashes(dot: number, dateRange: { dateRange: { from: Date; to: Date } }): Promise<Crash.Raw[]>;
        fetchUnit(vin: string): Promise<Unit.Raw | undefined>;
        fetchUnits(vinsNumbers: string[]): Promise<Unit.Raw[]>;
        fetchLatestSmsResultsDate(): Promise<Date>;
        fetchLatestSnapshotDate(): Promise<Date>;
        fetchSnapshotDateHistorical(): Promise<IFetcher.fetchSnapshotDateHistorical.Return[]>;
        fetchAuthorityHistory(dot: number): Promise<AuthorityHistory.Raw[]>;
        fetchBulk(options: IFetcher.fetchBulk.Options): Promise<IFetcher.fetchBulk.Return | null>;
        fetchNationalAverageOutOfServiceRates(dates: Date[]): Promise<FMCSA.NationalAverageOutOfServiceRate[]>;
        fetchInsuranceHistory(dot: number): Promise<InsuranceHistoryEntry.Raw[]>;
    }

    export namespace IFetcher {
        export namespace fetchSnapshotDateHistorical {
            export interface Return {
                RecordDate: Date;
                SnapshotDate: Date;
            }
        }

        export namespace fetchViolations {
            export interface Options {
                inspectionIds: number[];
                dotNumber: number;
            }
        }
        export namespace fetchSmsScore {
            export interface Options {
                recordDate: Date;
                safetyEventGroupName: SafetyEventGroup.GroupNames;
                measure: number;
            }
        }

        export namespace fetchBulk {
            export interface Options extends Omit<MotorCarrier.Options, "fetcher" | "config"> {}

            export interface Return {
                recordDate: Date;
                snapshotDate: Date;
                snapshotDateHistorical: { RecordDate: Date; SnapshotDate: Date }[];
                census: MotorCarrier.Raw.Census;
                censusHistorical: MotorCarrier.Raw.CensusHistorical[];
                authorityHistory: MotorCarrier.Raw.AuthorityHistory[];
                insuranceHistory: MotorCarrier.Raw.InsuranceHistory[];
                smsData: MotorCarrier.Raw.SmsData[];
                crashes: MotorCarrier.Raw.Crash[];
                inspections: MotorCarrier.Raw.Inspection[];
                violations: MotorCarrier.Raw.Violation[];
                units: MotorCarrier.Raw.Unit[] | null;
                enforcementCases: MotorCarrier.Raw.EnforcementCase[];
                nationalAverageOutOfServiceRates: MotorCarrier.Raw.NationalAverageOutOfServiceRate[];
            }
        }
    }

    export interface PrimaryScoreContributor extends Unit.JSON {
        basicPointTotals: {
            unsafeDriving: number;
            hoursOfService: number;
            controlledSubstances: number;
            driverFitness: number;
            total: number;
        };
    }
}
