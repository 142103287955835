import { classNames } from "@deathstar/ui";

export function DotAnalysisLogo(
    options?: Partial<{ top: string; right: string; left: string; white: boolean; primary: boolean; className?: string }>
): JSX.Element {
    const _colors = {
        top: options?.top || "#da6027",
        right: options?.right || "#2b4572",
        left: options?.left || "#4f77bb",
    };

    if (options?.white) {
        _colors.top = "white";
        _colors.right = "white";
        _colors.left = "white";
    }

    if (options?.primary) {
        _colors.top = "rgb(13, 68, 123)";
        _colors.right = "rgb(13, 68, 123)";
        _colors.left = "rgb(13, 68, 123)";
    }

    return (
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1519.43 211.41" className={classNames(options?.className)}>
            <path
                fill={`var(--left, ${_colors.left || "#2b4572"})`}
                d="M586.66,148.66h-77.94l-23.41,58.52h-12.2l74.7-184.51h1l74.7,184.51h-13.7l-23.16-58.52ZM582.18,137.21l-34.11-86.9-34.86,86.9h68.98Z"
            />
            <path
                fill={`var(--left, ${_colors.left || "#2b4572"})`}
                d="M792.83,30.38v181.03h-.5l-136.21-153.39.25,149.15h-11.95V26.65h.75l136.21,154.63-.25-150.9h11.7Z"
            />
            <path
                fill={`var(--left, ${_colors.left || "#2b4572"})`}
                d="M927.29,148.66h-77.94l-23.41,58.52h-12.2l74.7-184.51h1l74.7,184.51h-13.7l-23.16-58.52ZM922.81,137.21l-34.11-86.9-34.86,86.9h68.98Z"
            />
            <path fill={`var(--left, ${_colors.left || "#2b4572"})`} d="M997.26,30.38v165.09h96.86v11.7h-109.07V30.38h12.2Z" />
            <path
                fill={`var(--left, ${_colors.left || "#2b4572"})`}
                d="M1232.83,30.38l-65.99,113.79v63h-12.2v-63l-65.99-113.79h14.94l57.52,100.1,57.02-100.1h14.69Z"
            />
            <path
                fill={`var(--left, ${_colors.left || "#2b4572"})`}
                d="M1315.5,43.08c-7.31-1.99-14.44-2.99-21.41-2.99-11.62,0-20.92,2.86-27.89,8.59-6.97,5.73-10.46,13.07-10.46,22.04,0,6.31,1.87,11.66,5.6,16.06,3.74,4.4,8.51,8.06,14.32,10.96,5.81,2.91,13.28,6.1,22.41,9.59,9.96,3.65,18.22,7.39,24.78,11.21,6.56,3.82,12.08,9.01,16.56,15.56,4.48,6.56,6.72,14.98,6.72,25.27s-2.33,18.47-6.97,26.02c-4.65,7.56-11.17,13.41-19.55,17.56-8.38,4.15-17.97,6.23-28.76,6.23-18.76,0-37.35-6.06-55.78-18.18l5.73-9.96c6.14,4.65,13.7,8.71,22.66,12.2,8.96,3.49,17.68,5.23,26.15,5.23,7.47,0,14.57-1.45,21.29-4.36,6.72-2.9,12.2-7.3,16.43-13.2,4.23-5.89,6.35-12.99,6.35-21.29,0-7.8-1.95-14.36-5.85-19.67-3.9-5.31-8.8-9.59-14.69-12.82-5.89-3.24-13.32-6.51-22.29-9.83-9.96-3.65-18.18-7.18-24.65-10.58-6.47-3.4-11.91-7.93-16.31-13.57-4.4-5.64-6.6-12.7-6.6-21.16s2.12-15.64,6.35-22.04c4.23-6.39,10.12-11.37,17.68-14.94,7.55-3.57,15.97-5.44,25.27-5.6,7.97,0,16.22,1.08,24.78,3.24,8.55,2.16,16.14,4.9,22.78,8.22l-4.73,9.46c-5.98-2.82-12.62-5.23-19.92-7.22Z"
            />
            <path fill={`var(--left, ${_colors.left || "#2b4572"})`} d="M1383.97,30.38v176.79h-12.2V30.38h12.2Z" />
            <path
                fill={`var(--left, ${_colors.left || "#2b4572"})`}
                d="M1488.8,43.08c-7.31-1.99-14.44-2.99-21.41-2.99-11.62,0-20.92,2.86-27.89,8.59-6.97,5.73-10.46,13.07-10.46,22.04,0,6.31,1.87,11.66,5.6,16.06,3.74,4.4,8.51,8.06,14.32,10.96,5.81,2.91,13.28,6.1,22.41,9.59,9.96,3.65,18.22,7.39,24.78,11.21,6.56,3.82,12.08,9.01,16.56,15.56,4.48,6.56,6.72,14.98,6.72,25.27s-2.33,18.47-6.97,26.02c-4.65,7.56-11.17,13.41-19.55,17.56-8.38,4.15-17.97,6.23-28.76,6.23-18.76,0-37.35-6.06-55.78-18.18l5.73-9.96c6.14,4.65,13.7,8.71,22.66,12.2,8.96,3.49,17.68,5.23,26.15,5.23,7.47,0,14.57-1.45,21.29-4.36,6.72-2.9,12.2-7.3,16.43-13.2,4.23-5.89,6.35-12.99,6.35-21.29,0-7.8-1.95-14.36-5.85-19.67-3.9-5.31-8.8-9.59-14.69-12.82-5.89-3.24-13.32-6.51-22.29-9.83-9.96-3.65-18.18-7.18-24.65-10.58-6.47-3.4-11.91-7.93-16.31-13.57-4.4-5.64-6.6-12.7-6.6-21.16s2.12-15.64,6.35-22.04c4.23-6.39,10.12-11.37,17.68-14.94,7.55-3.57,15.97-5.44,25.27-5.6,7.97,0,16.22,1.08,24.78,3.24,8.55,2.16,16.14,4.9,22.78,8.22l-4.73,9.46c-5.98-2.82-12.62-5.23-19.92-7.22Z"
            />
            <path
                fill={`var(--right, ${_colors.right || "#4f77bb"})`}
                d="M470.11,22.6v34.93h-44.67v148.72h-36.18V57.53h-42.92V22.6h123.77Z"
            />
            <polygon fill={`var(--right, ${_colors.right || "#4f77bb"})`} points="280.28 0 280.28 0 280.28 0 280.28 0" />
            <path
                fill={`var(--top, ${_colors.top || "#da6027"})`}
                d="M209.62,88.09c5.14-9.7,12.03-17.35,20.64-22.95,8.62-5.6,17.94-8.71,27.96-9.32,10.02-.62,19.67,1.53,28.92,6.44,9.15,4.85,16.26,11.53,21.37,20.03,1.74-.23,3.51-.51,5.23-.73l30.91-5.42c-1.74-3.95-3.75-7.82-6.04-11.6-8.7-14.31-20.47-25.4-35.31-33.27-14.84-7.87-30.63-11.4-47.35-10.57-16.73.83-32.19,5.66-46.39,14.52-14.2,8.85-25.2,20.63-32.99,35.32-7.95,14.99-11.57,30.77-10.85,47.35.06,1.28.18,2.55.28,3.81,11.64-5.14,24.07-10.03,37.24-14.55,1.01-6.55,3.13-12.91,6.39-19.07Z"
            />
            <path
                fill={`var(--right, ${_colors.right || "#4f77bb"})`}
                d="M314.77,126.89c-1.24,4.74-3.06,9.42-5.51,14.04-5.07,9.55-11.82,17.13-20.25,22.73-2.05,1.36-4.15,2.57-6.3,3.65.92,12.47,2.29,24.67,4.07,36.51,7.7-2.32,15.1-5.63,22.21-9.93,14.27-8.63,25.38-20.43,33.33-35.42,5.38-10.15,8.67-20.82,9.96-31.98-11.53-.23-24.54-.08-37.52.4Z"
            />
            <path
                fill={`var(--left, ${_colors.left || "#2b4572"})`}
                d="M243.26,171.06c-3.53-1.04-7-2.46-10.41-4.27-9.41-4.99-16.74-11.84-22-20.56-1.19-1.98-2.24-4-3.18-6.06-12.29,2.62-24.11,5.55-35.38,8.74,2.05,5.16,4.54,10.18,7.54,15.05,8.7,14.13,20.55,25.16,35.54,33.11,11.75,6.23,24.08,9.74,36.97,10.61-2.5-8.48-4.82-17.18-6.86-26.1-.81-3.53-1.52-7.02-2.23-10.51Z"
            />
            <path
                fill={`var(--right, ${_colors.right || "#4f77bb"})`}
                d="M0,22.6h54.9c23.46,0,42.75,4.53,57.89,13.6,15.14,9.07,26.16,20.8,33.06,35.18,6.9,14.39,10.35,29.82,10.35,46.29,0,17.63-4.16,33.19-12.48,46.66-8.32,13.47-19.51,23.83-33.56,31.07-14.06,7.24-29.49,10.85-46.29,10.85H0V22.6ZM57.64,171.32c18.8,0,33.69-4.82,44.67-14.47,10.98-9.65,16.47-23.54,16.47-41.67,0-14.64-3.04-26.28-9.11-34.93-6.07-8.65-13.14-14.64-21.21-17.97-8.07-3.32-15.68-4.99-22.83-4.99h-29.44v114.03h21.46Z"
            />
        </svg>
    );
}
