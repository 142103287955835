import { CertificateBuilderConfigItem, CertificateHolder, MasterCertificate } from "@deathstar/types/northstar";
import { ActionButton, Checkbox, classNames, InputField, PrimaryButton, SecondaryButton } from "@deathstar/ui";
import { ArrowDownTrayIcon, CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { CircularProgress } from "@material-ui/core";
import { isPhoneNumber, isPostalCode } from "class-validator";
import { validate as validateEmail } from "email-validator";
import { uniq } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Controller, FieldErrors, FormProvider, useForm, useWatch } from "react-hook-form";
import api from "../../api/api";
import { Dialog } from "../../components/dialog/Dialog";
import { EquipmentSelector } from "./EquipmentSelector";
import { OwnerOperatorSelector } from "./OwnerOperatorSelector";

interface FormData {
    to: string;
    emailCC: string;
    holderName: string;
    holderStreet: string;
    holderStreet2: string;
    holderCity: string;
    holderState: string;
    holderZip: string;

    optionsObj: Record<string, unknown>;
}

export interface SendCertificateDialogProps {
    certificate: MasterCertificate;
    open: boolean;

    // we separate out these methods purposefully. See the comment in the `Transition.afterLeave` prop
    closeDialog(): void;
    afterClose(): void;

    globalHolderId?: CertificateHolder["globalHolderId"];
    defaultValues?: Partial<FormData>;

    primaryAction: "send" | "download";
}

export default function SendCertificateDialog({
    certificate,
    open,
    afterClose,
    closeDialog,
    globalHolderId,
    defaultValues,
    primaryAction = "send",
}: SendCertificateDialogProps) {
    const [choosingTractors, setChoosingTractors] = useState<boolean>(false);
    const [choosingTrailers, setChoosingTrailers] = useState<boolean>(false);
    const [choosingOwnerOperators, setChoosingOwnerOperators] = useState<boolean>(false);

    const method = useRef<"send" | "download">(primaryAction);
    const sendCertificate = api.certificates.useSend(certificate.accountId, certificate.id, {
        onMutate: () => {
            window.trace.trackEvent("certificate_sent");
        },
    });
    const downloadCertificate = api.certificates.useDownload(certificate.accountId, certificate.id);

    const form = useForm<FormData>({
        resolver: (values) => {
            const errors: FieldErrors<FormData> = {};
            if (primaryAction === "send") {
                if (method.current === "send") {
                    if (!validateEmail(values.to) && !isPhoneNumber(values.to, "US") && !isPhoneNumber(values.to)) {
                        errors["to"] = { type: "validate", message: "Must be a valid email or fax number" };
                    }
                    if (values.emailCC && !validateEmail(values.emailCC)) {
                        errors["emailCC"] = { type: "validate", message: "Must be a valid email" };
                    }
                }
                if (!values.holderName) {
                    errors["holderName"] = { type: "required", message: "Required" };
                }
                if (!values.holderStreet) {
                    errors["holderStreet"] = { type: "required", message: "Required" };
                }
                if (!values.holderCity) {
                    errors["holderCity"] = { type: "required", message: "Required" };
                }
                if (!values.holderState) {
                    errors["holderState"] = { type: "required", message: "Required" };
                }
                if (values.holderState && values.holderState.length !== 2) {
                    errors["holderState"] = { type: "validate", message: "State must be 2 characters" };
                }
                if (!values.holderZip) {
                    errors["holderZip"] = { type: "required", message: "Required" };
                }
                if (values.holderZip && !isPostalCode(values.holderZip, "any")) {
                    errors["holderZip"] = { type: "validate", message: "Must be a valid postal code" };
                }
            }
            return {
                values,
                errors,
            };
        },
        defaultValues: {
            to: "",
            emailCC: "",
            holderName: "",
            holderStreet: "",
            holderStreet2: "",
            holderCity: "",
            holderState: "",
            holderZip: "",

            optionsObj: {
                [CertificateBuilderConfigItem.Id.HideWorkersCompensationExcludedPersons]:
                    certificate.hideWorkersCompensationExcludedPersons,
                [CertificateBuilderConfigItem.Id.AdditionalTractors]: [],
                [CertificateBuilderConfigItem.Id.AdditionalTrailers]: [],
                [CertificateBuilderConfigItem.Id.OwnerOperators]: [],
                [CertificateBuilderConfigItem.Id.HideAtOneLossAtAnyTerminal]: certificate.hideAtOneLossAtAnyTerminal,
                [CertificateBuilderConfigItem.Id.HideBlanketAI]: certificate.hideBlanketedAdditionalInterest,
                [CertificateBuilderConfigItem.Id.HideCoveragePD]: certificate.hideCoveragePhysicalDamage,
                [CertificateBuilderConfigItem.Id.HideCoverageWC]: certificate.hideCoverageWorkersCompensation,
                [CertificateBuilderConfigItem.Id.HideCoverageCargo]: certificate.hideCoverageCargo,
                [CertificateBuilderConfigItem.Id.HideCoverageXSC]: certificate.hideCoverageExcessCargo,
                [CertificateBuilderConfigItem.Id.HideCoverageXSAL]: certificate.hideCoverageExcessLiability,
                [CertificateBuilderConfigItem.Id.HideCoveragePOLL]: certificate.hideCoveragePollution,
                [CertificateBuilderConfigItem.Id.HideHiredPhysicalDamage]: certificate.hideHiredPhysicalDamage,
                [CertificateBuilderConfigItem.Id.HideNonTruckingCheckbox]: certificate.hideNonTruckingCheckbox,
                [CertificateBuilderConfigItem.Id.HideTrailerInterchange]: certificate.hideTrailerInterchange,
            },
        },
    });

    useEffect(() => {
        if (open && defaultValues) {
            form.setValue("holderName", defaultValues.holderName || "");
            form.setValue("holderStreet", defaultValues.holderStreet || "");
            form.setValue("holderStreet2", defaultValues.holderStreet2 || "");
            form.setValue("holderCity", defaultValues.holderCity || "");
            form.setValue("holderState", defaultValues.holderState || "");
            form.setValue("holderZip", defaultValues.holderZip || "");
            form.setValue("to", defaultValues.to || "");
        }
    }, [form, defaultValues, open]);

    useEffect(() => {
        if (open) {
            form.setValue("optionsObj", {
                ...(Object.fromEntries(
                    MasterCertificate.GetBuilderOptions(certificate).map((option) => [option.id, option.value])
                ) as Record<CertificateBuilderConfigItem["id"], unknown>),
                [CertificateBuilderConfigItem.Id.HideWorkersCompensationExcludedPersons]: true,
                [CertificateBuilderConfigItem.Id.AdditionalTractors]: [],
                [CertificateBuilderConfigItem.Id.AdditionalTrailers]: [],
                [CertificateBuilderConfigItem.Id.OwnerOperators]: certificate.availableUnits.ownerOperators
                    .filter((o) => globalHolderId && o.employerId === globalHolderId)
                    .map((o) => o.id),
            });
        }
    }, [form, open, certificate, globalHolderId]);

    const selectedOwnerOperators = useWatch({
        control: form.control,
        name: `optionsObj.${CertificateBuilderConfigItem.Id.OwnerOperators}`,
    }) as number[];

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            afterLeave={() => {
                // reset the state after the dialog is closed, otherwise the dialog UI will change while fading out and look weird
                sendCertificate.reset();
                setChoosingTractors(false);
                setChoosingTrailers(false);
                form.reset();
                afterClose();
            }}
            className="max-w-[min(100vw-64px,720px)] overflow-y-hidden p-2 lg:p-8"
        >
            {sendCertificate.isSuccess ? (
                <div className="flex flex-col items-center justify-center gap-4">
                    <CheckCircleIcon className="h-1/2 w-1/2 animate-wiggle text-emerald-600" />
                    <p className="text-center text-navigator-primary-dark">Certificate sent to</p>
                    <div className="font-medium text-navigator-primary-dark">
                        {sendCertificate.data?.email.to.map((email) => (
                            <p key={email}>{email}</p>
                        ))}
                    </div>
                    <div className="mt-4 flex w-full flex-col items-center justify-between gap-4">
                        <p className="text-sm text-stone-500">Emails may take a few minutes to be delivered</p>
                        <PrimaryButton onClick={closeDialog} className="w-32">
                            Continue
                        </PrimaryButton>
                    </div>
                </div>
            ) : sendCertificate.error ? (
                <div className="flex flex-col items-center justify-center gap-2">
                    <ExclamationCircleIcon className="h-1/2 w-1/2 text-red-600" />
                    <p>An error occurred and the certificate was not sent</p>
                    <p className="text-sm uppercase text-stone-500">{sendCertificate.error?.message || "[Unknown error]"}</p>
                </div>
            ) : (
                <FormProvider {...form}>
                    <form
                        className="flex flex-col overflow-y-auto"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                            }
                        }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            // submit is handled on the buttons themselves since we have multiple submit buttons
                        }}
                    >
                        {choosingTractors ? (
                            <>
                                <p className="text-xl font-medium leading-6 text-navigator-primary-dark">Add tractors to the certificate</p>
                                <div className="mt-2">
                                    <Controller
                                        name={`optionsObj.${CertificateBuilderConfigItem.Id.AdditionalTractors}` as const}
                                        control={form.control}
                                        render={({ field }) => (
                                            <EquipmentSelector
                                                available={certificate.availableUnits.tractors}
                                                selected={field.value as number[]}
                                                setSelected={(newState) => {
                                                    field.onChange(newState);
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="sticky bottom-0 flex justify-end border-t bg-white pt-4">
                                    <PrimaryButton
                                        type="button"
                                        onClick={() => {
                                            setChoosingTractors(false);
                                        }}
                                    >
                                        Continue
                                    </PrimaryButton>
                                </div>
                            </>
                        ) : choosingTrailers ? (
                            <>
                                <p className="text-xl font-medium leading-6 text-navigator-primary-dark">Add trailers to the certificate</p>
                                <div className="mt-2">
                                    <Controller
                                        name={`optionsObj.${CertificateBuilderConfigItem.Id.AdditionalTrailers}`}
                                        control={form.control}
                                        render={({ field }) => (
                                            <EquipmentSelector
                                                available={certificate.availableUnits.trailers}
                                                selected={field.value as number[]}
                                                setSelected={(newState) => {
                                                    field.onChange(newState);
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="sticky bottom-0 flex justify-end border-t bg-white pt-4">
                                    <PrimaryButton
                                        type="button"
                                        onClick={() => {
                                            setChoosingTrailers(false);
                                        }}
                                    >
                                        Continue
                                    </PrimaryButton>
                                </div>
                            </>
                        ) : choosingOwnerOperators ? (
                            <>
                                <p className="text-xl font-medium leading-6 text-navigator-primary-dark">
                                    Add owner operators to the certificate
                                </p>
                                <div className="mt-2">
                                    <Controller
                                        name={`optionsObj.${CertificateBuilderConfigItem.Id.OwnerOperators}`}
                                        control={form.control}
                                        render={({ field }) => (
                                            <OwnerOperatorSelector
                                                available={certificate.availableUnits.ownerOperators}
                                                selected={field.value as number[]}
                                                setSelected={(newState) => {
                                                    field.onChange(newState);
                                                    const linkedTractorIds = [];
                                                    for (const id of newState) {
                                                        const driver = certificate.availableUnits.ownerOperators.find(
                                                            (driver) => driver.id === id
                                                        );
                                                        if (!driver || !driver.tractors?.length) continue;
                                                        const linkedTractors = certificate.availableUnits.tractors.filter((t1) =>
                                                            driver.tractors?.find((t2) => t1.id === t2.id)
                                                        );
                                                        if (!linkedTractors.length) continue;
                                                        linkedTractorIds.push(...linkedTractors);
                                                    }
                                                    if (linkedTractorIds.length) {
                                                        const currentTractors = form.getValues(
                                                            `optionsObj.${CertificateBuilderConfigItem.Id.AdditionalTractors}`
                                                        ) as number[];
                                                        form.setValue(
                                                            `optionsObj.${CertificateBuilderConfigItem.Id.AdditionalTractors}`,
                                                            uniq([...currentTractors, ...linkedTractorIds])
                                                        );
                                                    }

                                                    const linkedTrailerIds = [];
                                                    for (const id of newState) {
                                                        const driver = certificate.availableUnits.ownerOperators.find(
                                                            (driver) => driver.id === id
                                                        );
                                                        if (!driver || !driver.trailers?.length) continue;
                                                        const linkedTrailers = certificate.availableUnits.trailers.filter((t1) =>
                                                            driver.trailers?.find((t2) => t1.id === t2.id)
                                                        );
                                                        if (!linkedTrailers.length) continue;
                                                        linkedTrailerIds.push(...linkedTrailers);
                                                    }
                                                    if (linkedTrailerIds.length) {
                                                        const currentTrailers = form.getValues(
                                                            `optionsObj.${CertificateBuilderConfigItem.Id.AdditionalTrailers}`
                                                        ) as number[];
                                                        form.setValue(
                                                            `optionsObj.${CertificateBuilderConfigItem.Id.AdditionalTrailers}`,
                                                            uniq([...currentTrailers, ...linkedTrailerIds])
                                                        );
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="sticky bottom-0 flex justify-end border-t bg-white pt-4">
                                    <PrimaryButton
                                        type="button"
                                        onClick={() => {
                                            setChoosingOwnerOperators(false);
                                        }}
                                    >
                                        Continue
                                    </PrimaryButton>
                                </div>
                            </>
                        ) : (
                            <div className="p-1">
                                <p className="text-xl font-medium leading-6 text-navigator-primary-dark">
                                    {primaryAction === "send" ? "Send" : "Download"} certificate
                                </p>
                                <div className="mt-2 grid grid-cols-6 gap-4">
                                    {primaryAction === "send" && (
                                        <>
                                            <InputField
                                                classes={{
                                                    root: "col-span-6",
                                                    inputContainerDisabled: "bg-stone-100 text-stone-500",
                                                }}
                                                placeholder="Holder name"
                                                {...form.register("holderName")}
                                                disabled={!!globalHolderId}
                                                autoFocus={!globalHolderId}
                                                error={!!form.formState.errors?.holderName}
                                            />
                                            <InputField
                                                classes={{
                                                    root: "col-span-full md:col-span-3",
                                                    inputContainerDisabled: "bg-stone-100 text-stone-500",
                                                }}
                                                placeholder="Street"
                                                {...form.register("holderStreet")}
                                                disabled={!!globalHolderId}
                                                error={!!form.formState.errors?.holderStreet}
                                            />
                                            <InputField
                                                classes={{
                                                    root: "col-span-full md:col-span-3",
                                                    inputContainerDisabled: "bg-stone-100 text-stone-500",
                                                }}
                                                placeholder={!globalHolderId ? "Street 2" : ""}
                                                {...form.register("holderStreet2")}
                                                disabled={!!globalHolderId}
                                            />
                                            <InputField
                                                classes={{
                                                    root: "col-span-3",
                                                    inputContainerDisabled: "bg-stone-100 text-stone-500",
                                                }}
                                                placeholder="City"
                                                {...form.register("holderCity")}
                                                disabled={!!globalHolderId}
                                                error={!!form.formState.errors?.holderCity}
                                            />
                                            <InputField
                                                classes={{
                                                    root: "col-span-3 md:col-span-1",
                                                    inputContainerDisabled: "bg-stone-100 text-stone-500",
                                                }}
                                                placeholder="State/Province"
                                                {...form.register("holderState")}
                                                disabled={!!globalHolderId}
                                                error={!!form.formState.errors?.holderState}
                                            />
                                            <InputField
                                                classes={{
                                                    root: "col-span-3 md:col-span-2",
                                                    inputContainerDisabled: "bg-stone-100 text-stone-500",
                                                }}
                                                placeholder="Postal code"
                                                {...form.register("holderZip")}
                                                disabled={!!globalHolderId}
                                                error={!!form.formState.errors?.holderZip}
                                            />

                                            <hr className="col-span-full" />
                                            <InputField
                                                label={
                                                    <span>
                                                        <span>To</span>
                                                        <span className="ml-2 text-sm text-stone-400">(email or fax)</span>
                                                    </span>
                                                }
                                                classes={{ root: "col-span-full md:col-span-4" }}
                                                {...form.register("to")}
                                                placeholder="e.g. 2125557100 or jsmith@example.com"
                                                autoFocus={!!globalHolderId}
                                            />
                                            {form.formState.errors?.to && (
                                                <p className="col-span-2 mb-4 self-end text-sm text-red-700">
                                                    {form.formState.errors?.to?.message}
                                                </p>
                                            )}
                                            <InputField
                                                label="CC"
                                                classes={{ root: "col-span-full md:col-span-4" }}
                                                {...form.register("emailCC")}
                                                placeholder="e.g. rogersmith@example.com"
                                            />
                                            {form.formState.errors?.emailCC && (
                                                <p className="col-span-2 mb-4 self-end text-sm text-red-700">
                                                    {form.formState.errors?.emailCC?.message}
                                                </p>
                                            )}
                                            <hr className="col-span-full" />
                                        </>
                                    )}
                                    <div className="col-span-full">
                                        <p className="text-stone-500">Customization options</p>
                                        {certificate.availableBuilderConfigIds.includes(CertificateBuilderConfigItem.Id.HideCoverageWC) && (
                                            <Checkbox
                                                label="Do not print workers' compensation"
                                                {...form.register(`optionsObj.${CertificateBuilderConfigItem.Id.HideCoverageWC}`)}
                                                classes={{ input: "w-4 h-4" }}
                                            />
                                        )}
                                        {certificate.availableBuilderConfigIds.includes(
                                            CertificateBuilderConfigItem.Id.HideCoverageXSAL
                                        ) && (
                                            <Checkbox
                                                label="Do not print excess liability"
                                                {...form.register(`optionsObj.${CertificateBuilderConfigItem.Id.HideCoverageXSAL}`)}
                                                classes={{ input: "w-4 h-4" }}
                                            />
                                        )}
                                        {certificate.availableBuilderConfigIds.includes(
                                            CertificateBuilderConfigItem.Id.HideCoverageXSC
                                        ) && (
                                            <Checkbox
                                                label="Do not print excess cargo"
                                                {...form.register(`optionsObj.${CertificateBuilderConfigItem.Id.HideCoverageXSC}`)}
                                                classes={{ input: "w-4 h-4" }}
                                            />
                                        )}
                                        {certificate.availableBuilderConfigIds.includes(CertificateBuilderConfigItem.Id.HideBlanketAI) && (
                                            <Checkbox
                                                label="Do not print blanket AI info"
                                                {...form.register(`optionsObj.${CertificateBuilderConfigItem.Id.HideBlanketAI}`)}
                                                classes={{ input: "w-4 h-4" }}
                                            />
                                        )}
                                        {certificate.availableBuilderConfigIds.includes(CertificateBuilderConfigItem.Id.HideCoveragePD) && (
                                            <Checkbox
                                                label={"Do not print physical damage in remarks"}
                                                {...form.register(`optionsObj.${CertificateBuilderConfigItem.Id.HideCoveragePD}`)}
                                                classes={{ input: "w-4 h-4" }}
                                            />
                                        )}
                                        {certificate.availableBuilderConfigIds.includes(
                                            CertificateBuilderConfigItem.Id.HideHiredPhysicalDamage
                                        ) && (
                                            <Checkbox
                                                label={"Do not print hired physical damage"}
                                                {...form.register(`optionsObj.${CertificateBuilderConfigItem.Id.HideHiredPhysicalDamage}`)}
                                                classes={{ input: "w-4 h-4" }}
                                            />
                                        )}
                                        {certificate.availableBuilderConfigIds.includes(
                                            CertificateBuilderConfigItem.Id.HideAtOneLossAtAnyTerminal
                                        ) && (
                                            <Checkbox
                                                label={'Do not print MTC "At one loss"/"At any terminal"'}
                                                {...form.register(
                                                    `optionsObj.${CertificateBuilderConfigItem.Id.HideAtOneLossAtAnyTerminal}`
                                                )}
                                                classes={{ input: "w-4 h-4" }}
                                            />
                                        )}
                                        {certificate.availableBuilderConfigIds.includes(
                                            CertificateBuilderConfigItem.Id.HideNonTruckingCheckbox
                                        ) && (
                                            <Checkbox
                                                label={'Do not print "Non-Trucking" checkbox'}
                                                {...form.register(`optionsObj.${CertificateBuilderConfigItem.Id.HideNonTruckingCheckbox}`)}
                                                classes={{ input: "w-4 h-4" }}
                                            />
                                        )}
                                        {certificate.availableBuilderConfigIds.includes(
                                            CertificateBuilderConfigItem.Id.HideWorkersCompensationExcludedPersons
                                        ) && (
                                            <Checkbox
                                                label={"Do not print WC excluded persons"}
                                                {...form.register(
                                                    `optionsObj.${CertificateBuilderConfigItem.Id.HideWorkersCompensationExcludedPersons}`
                                                )}
                                                classes={{ input: "w-4 h-4" }}
                                            />
                                        )}
                                        {certificate.availableBuilderConfigIds.includes(
                                            CertificateBuilderConfigItem.Id.HideTrailerInterchange
                                        ) && (
                                            <Checkbox
                                                label={"Do not print trailer interchange"}
                                                {...form.register(`optionsObj.${CertificateBuilderConfigItem.Id.HideTrailerInterchange}`)}
                                                classes={{ input: "w-4 h-4" }}
                                            />
                                        )}
                                        {certificate.availableBuilderConfigIds.includes(
                                            CertificateBuilderConfigItem.Id.HideFirstDollarDeductible
                                        ) && (
                                            <Checkbox
                                                label={"Do not show $0 first dollar deductible"}
                                                {...form.register(
                                                    `optionsObj.${CertificateBuilderConfigItem.Id.HideFirstDollarDeductible}`
                                                )}
                                                classes={{ input: "w-4 h-4" }}
                                            />
                                        )}
                                        {certificate.availableUnits.ownerOperators.length ? (
                                            <ActionButton onClick={() => setChoosingOwnerOperators(true)} className="ml-6 mt-2">
                                                Click to add owner operators{" "}
                                                <span
                                                    className={classNames(
                                                        selectedOwnerOperators.length ? "text-blue-700" : "text-stone-600"
                                                    )}
                                                >
                                                    (
                                                    {
                                                        (
                                                            form.watch(
                                                                `optionsObj.${CertificateBuilderConfigItem.Id.OwnerOperators}`
                                                            ) as number[]
                                                        ).length
                                                    }{" "}
                                                    added)
                                                </span>
                                            </ActionButton>
                                        ) : null}
                                        {certificate.availableUnits.tractors.length ? (
                                            <ActionButton onClick={() => setChoosingTractors(true)} className="ml-6 mt-2">
                                                Click to add tractors{" "}
                                                <span
                                                    className={classNames(
                                                        (
                                                            form.watch(
                                                                `optionsObj.${CertificateBuilderConfigItem.Id.AdditionalTractors}`
                                                            ) as number[]
                                                        ).length
                                                            ? "text-blue-700"
                                                            : "text-stone-600"
                                                    )}
                                                >
                                                    (
                                                    {
                                                        (
                                                            form.watch(
                                                                `optionsObj.${CertificateBuilderConfigItem.Id.AdditionalTractors}`
                                                            ) as number[]
                                                        ).length
                                                    }{" "}
                                                    added)
                                                </span>
                                            </ActionButton>
                                        ) : null}
                                        {certificate.availableUnits.trailers.length ? (
                                            <ActionButton onClick={() => setChoosingTrailers(true)} className="ml-6 mt-2">
                                                Click to add trailers{" "}
                                                <span
                                                    className={classNames(
                                                        (
                                                            form.watch(
                                                                `optionsObj.${CertificateBuilderConfigItem.Id.AdditionalTrailers}`
                                                            ) as number[]
                                                        ).length
                                                            ? "text-blue-700"
                                                            : "text-stone-600"
                                                    )}
                                                >
                                                    (
                                                    {
                                                        (
                                                            form.watch(
                                                                `optionsObj.${CertificateBuilderConfigItem.Id.AdditionalTrailers}`
                                                            ) as number[]
                                                        ).length
                                                    }{" "}
                                                    added)
                                                </span>
                                            </ActionButton>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="mt-4 grid grid-cols-2 items-center gap-2 gap-y-3 border-t pt-4 md:flex md:justify-end md:gap-8">
                                    <button
                                        type="button"
                                        className="px-2 py-0 text-stone-500"
                                        onClick={closeDialog}
                                        disabled={sendCertificate.isPending || downloadCertificate.isPending}
                                    >
                                        Cancel
                                    </button>

                                    <SecondaryButton
                                        type="button"
                                        className="!py-1 text-waypoint-blue"
                                        disabled={sendCertificate.isPending || downloadCertificate.isPending}
                                        onClick={() => {
                                            method.current = "download";
                                            form.handleSubmit(async function onValid(data) {
                                                downloadCertificate.mutate({
                                                    holder:
                                                        primaryAction === "download"
                                                            ? null
                                                            : globalHolderId || {
                                                                  name: data.holderName,
                                                                  street: data.holderStreet,
                                                                  street2: data.holderStreet2,
                                                                  city: data.holderCity,
                                                                  state: data.holderState,
                                                                  zip: data.holderZip,
                                                              },
                                                    options: Object.entries(data.optionsObj)
                                                        .map(([id, value]) => {
                                                            return {
                                                                id: parseInt(id),
                                                                value,
                                                            };
                                                        })
                                                        .filter((option) => option.value !== false),
                                                });
                                            }, console.error)();
                                        }}
                                    >
                                        {downloadCertificate.isPending ? (
                                            <CircularProgress size="1rem" classes={{ root: "!text-waypoint-blue" }} />
                                        ) : (
                                            <>
                                                <ArrowDownTrayIcon className="h-4 w-4" />
                                                <span>Download</span>
                                            </>
                                        )}
                                    </SecondaryButton>

                                    {primaryAction === "send" && (
                                        <PrimaryButton
                                            type="button"
                                            className="col-span-2 min-w-[96px]"
                                            disabled={sendCertificate.isPending || downloadCertificate.isPending}
                                            onClick={() => {
                                                method.current = "send";
                                                form.handleSubmit(async function onValid(data) {
                                                    sendCertificate.mutate({
                                                        holder: globalHolderId || {
                                                            name: data.holderName,
                                                            street: data.holderStreet,
                                                            street2: data.holderStreet2,
                                                            city: data.holderCity,
                                                            state: data.holderState,
                                                            zip: data.holderZip,
                                                        },
                                                        email: {
                                                            to: data.to,
                                                            ccAddress: data.emailCC,
                                                        },
                                                        options: Object.entries(data.optionsObj)
                                                            .map(([id, value]) => {
                                                                return {
                                                                    id: parseInt(id),
                                                                    value,
                                                                };
                                                            })
                                                            .filter((option) => option.value !== false),
                                                    });
                                                })();
                                            }}
                                        >
                                            {sendCertificate.isPending ? (
                                                <CircularProgress size="1rem" classes={{ root: "!text-white" }} />
                                            ) : (
                                                <>
                                                    <PaperAirplaneIcon className="h-4 w-4" />
                                                    <span>Send</span>
                                                </>
                                            )}
                                        </PrimaryButton>
                                    )}
                                </div>
                            </div>
                        )}
                    </form>
                </FormProvider>
            )}
        </Dialog>
    );
}
