import { Transform } from "class-transformer";
import { isBefore, isEqual } from "date-fns";
import moment from "moment";

export class GeneralLedgerTransaction {
    static isPossiblePayment(glt: GeneralLedgerTransaction, policyEffectiveDate: Date) {
        if (
            ![3, 4].includes(glt.sourceId) ||
            isBefore(glt.effectiveDate || glt.enteredDate, policyEffectiveDate) ||
            isEqual(glt.effectiveDate || glt.enteredDate, policyEffectiveDate) ||
            glt.description === "Company Statement"
        ) {
            return false;
        }
        return true;
    }

    id: string;
    invoiceId: string;
    description?: string;
    debitAmount: number;
    creditAmount: number;
    enteredDate: Date;
    @Transform(({ value }) => {
        if (!value) return null;
        const mmt = moment(value);
        return mmt.toDate();
    })
    effectiveDate?: Date;
    glNumber: string;
    sourceId?: number;
    sourceTypeId?: number;
    sourceReferenceNumber?: number;
}

export interface GeneralLedgerTransactionDto extends Omit<GeneralLedgerTransaction, "effectiveDate"> {
    effectiveDate?: string;
}
