import { GlobalCertificateHolder, MasterCertificate } from "@deathstar/types/northstar";
import { InputField, useSnackbar } from "@deathstar/ui";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { debounce } from "lodash";
import { matchSorter } from "match-sorter";
import { useMemo, useRef, useState } from "react";
import api from "../../api/api";
import { UnauthorizedError } from "../../api/util/exceptions";
import { Dialog } from "../../components/dialog/Dialog";
import { queryClient } from "../../util/queryClient";
import { stringifyAddress } from "./certificatesUtil";

export default function HolderSelectDialog({
    certificate,
    open,
    onClose,
    onAdd,
    openCustomSendDialog,
    openSendDialog,
}: {
    certificate: MasterCertificate;
    open: boolean;
    onClose(): void;
    onAdd(): void;
    openCustomSendDialog(name: string): void;
    openSendDialog(globalHolder: GlobalCertificateHolder): void;
}) {
    const [{ keyword }, setQuery] = useState({ keyword: "" });

    const { data } = api.certificates.useSearchGlobalHolders({ keyword });

    const { mutate: addHolder, isPending: addingHolder } = api.certificates.useAddGlobalHolderToCertificate(
        certificate.accountId,
        certificate.id,
        {
            onSuccess: (holder, globalHolderId) => {
                queryClient.setQueryData<MasterCertificate | undefined>(["certificate", certificate.id], (old) => {
                    if (!old) return undefined;
                    return {
                        ...old,
                        holders: [...old.holders, holder],
                    };
                });
                onAdd();
                const globalHolder = data?.data.find((h) => h.id === globalHolderId);
                if (globalHolder) {
                    openSendDialog(globalHolder);
                }
            },

            onError: (e) => {
                if (e instanceof UnauthorizedError) {
                    useSnackbar.add("You do not have permission to manage certificates", {
                        variant: "error",
                    });
                }
            },
        }
    );

    const setKeyword = useMemo(
        () =>
            debounce((keyword) => {
                setQuery({ keyword });
            }, 300),
        []
    );
    const searchRef = useRef<HTMLInputElement | null>(null);
    const listRef = useRef<HTMLUListElement | null>(null);

    const results = useMemo(() => {
        const relatedPendingHolders = matchSorter(
            certificate.holders.map((holder) => holder.globalHolder).filter((globalHolder) => globalHolder.pending),
            keyword,
            { keys: ["name", (globalHolder) => stringifyAddress(globalHolder)] }
        );
        return keyword ? [...relatedPendingHolders, ...(data?.data || [])] : [];
    }, [certificate.holders, data?.data, keyword]);

    return (
        <Dialog open={open} onClose={onClose} afterLeave={() => setQuery({ keyword: "" })} className="p-0">
            <div className="flex w-screen flex-col overflow-auto md:max-w-md">
                <div className="w-full space-y-4 px-6 pt-6">
                    <p className="text-xl font-medium leading-6 text-navigator-primary-dark">Find a holder</p>
                    <InputField
                        ref={searchRef}
                        placeholder="Search by name, address, or email"
                        defaultValue=""
                        onChange={(e) => {
                            setKeyword(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "ArrowDown") {
                                e.preventDefault();
                                listRef.current?.querySelector("li")?.querySelector("button")?.focus();
                            }
                        }}
                        classes={{ root: "text-sm w-full" }}
                    />
                </div>
                {results.length ? (
                    <ul ref={listRef} className="overflow-y-auto overflow-x-visible p-6">
                        {results.map((holder) => {
                            const isAdded = certificate.holders.some((h) => h.globalHolderId === holder.id);
                            return (
                                <li key={holder.id}>
                                    <button
                                        className="group relative flex w-full rounded py-4 pl-4 pr-6 text-left outline-none ring-navigator-primary focus:ring-2 enabled:hover:bg-navigator-primary-light/5 enabled:active:bg-navigator-primary-light/10"
                                        onClick={async () => {
                                            if (!addingHolder) {
                                                if (!isAdded) {
                                                    addHolder(holder.id);
                                                }
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "ArrowDown") {
                                                e.preventDefault();
                                                e.currentTarget.parentElement?.nextElementSibling?.querySelector("button")?.focus();
                                            } else if (e.key === "ArrowUp") {
                                                e.preventDefault();
                                                const previous =
                                                    e.currentTarget.parentElement?.previousElementSibling?.querySelector("button");
                                                if (previous) {
                                                    previous.focus();
                                                } else {
                                                    searchRef.current?.focus();
                                                }
                                            } else {
                                                e.currentTarget.focus();
                                            }
                                        }}
                                    >
                                        <div className="grow">
                                            <p className="font-medium">{holder.name}</p>
                                            <p className="text-sm text-stone-600">{stringifyAddress(holder)}</p>
                                            <p className="text-sm text-navigator-primary-dark">{holder.email || holder.fax}</p>
                                        </div>

                                        <PaperAirplaneIcon className="h-6 w-6 place-self-end self-center text-navigator-primary opacity-0 transition-opacity group-hover:opacity-100 group-focus:opacity-100" />
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <div className="flex flex-grow flex-col items-center justify-center gap-4 py-4">
                        {keyword.length >= 3 && <p className="text-sm text-navigator-primary-dark">No results found</p>}
                    </div>
                )}
                {keyword.length >= 3 && (
                    <button
                        onClick={() => openCustomSendDialog(keyword)}
                        className="border-t py-4 text-navigator-primary-light hover:text-navigator-primary-dark"
                    >
                        Send to a new holder
                    </button>
                )}
            </div>
        </Dialog>
    );
}
